@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Basic / Typography
  */

$heading-thumb-size: $unit-size-small * 9 + $unit-size-small + 2px; //Img + padding + border
$divider-labeled-height: 2px;

.#{$ns}heading {
    margin: 0;
    font-family: $font-family-base;
    font-size: $font-size-big;
    font-weight: $font-weight-extrabold;
    color: $color-text-dark;

    @include wordbreak;

    &--accent {
        color: $color-text-accent;
        text-transform: uppercase;
    }

    &--inline {
        display: inline-block;
    }

    >a {
        color: inherit;
    }

    &__thumb {
        background-color: $color-white;
        padding: $unit-size-small;
        border-radius: $unit-size-small;
        height: 100%;

        &-wrapper {
            overflow: hidden;
            height: $heading-thumb-size;
            min-width: $heading-thumb-size;
            width: $heading-thumb-size;
            border: 1px solid $color-divider-light;
            border-radius: $unit-size-small;
            float: left;
            margin-right: $unit-size-small * 3;
        }
    }

    &__icon {
        $c: &;

        display: inline-block;

        &--accent {
            color: $color-text-accent;
        }

        &--circle {
            line-height: 1.1;
            display: inline-block;
            height: $unit-size-large + $unit-size-small*0.5;
            width: $unit-size-large + $unit-size-small*0.5;
            border-radius: 50%;
            font-size: $font-size-plus;
            padding: $unit-size-small math.div($unit-size-small, 3) *2;
            color: $color-white;
            background: $color-text-base;

            &#{$c} {
                &--accent {
                    color: $color-white;
                    background: $color-text-accent;
                }
            }
        }

        &--small {
            font-size: $font-size-base;
        }

        &--primary {
            color: $color-text-primary;
        }
    }

    &__wrapper {
        position: relative;
    }

    &__actions {
        display: block;
        font-size: $font-size-base;
        min-width: $unit-size-large * 4;

        @include breakpoint(l) {
            float: right;
        }
    }

    &--inverse {
        color: $color-white;
    }

    &--text {
        font-size: $font-size-plus;
        color: $color-text-base;
        line-height: $line-height-big;
        text-transform: uppercase;
    }

    &--main {
        font-size: $font-size-super;
        text-align: center;

        .#{$ns}heading {
            &__actions {
                text-align: center;
            }
        }

        @include breakpoint('l') {
            text-align: left;
        }
    }

    &--decorative {
        font-family: $font-family-decorative;
        font-size: $font-size-huge;
        font-weight: $font-weight-normal;
        text-transform: capitalize;
    }

    &--soft {
        font-family: $font-family-soft;
        font-weight: $font-weight-normal;
        color: $color-text-light;
    }

    &--light {
        font-size: $font-size-tiny;
        text-transform: uppercase;
        color: $color-text-lighter;
        font-weight: $font-weight-normal;
    }

    &--top-divider {
        padding-top: $unit-size-large - math.div($unit-size-small*2, 3);
        position: relative;

        &::before {
            position: absolute;
            display: block;
            content: '';
            left: 1px;
            top: 0;
            width: $unit-size-small * 3;
            height: $unit-size-small * 0.55;
            background-color: $color-accent-base;
        }
    }

    &--top-divider-center {
        padding-top: $unit-size-large - math.div($unit-size-small*2, 3);
        position: relative;

        &::before {
            position: absolute;
            display: block;
            content: '';
            left: 50%;
            top: 0;
            width: $unit-size-small * 4;
            height: $unit-size-small * 0.5;
            background-color: $color-accent-base;
            transform: translateX(-50%);
        }
    }
}

.#{$ns}subheading {
    margin: 0;
    margin-bottom: $unit-size-small * 3;
    font-family: $font-family-base;
    font-size: $font-size-plus;
    font-weight: $font-weight-normal;
    color: $color-text-dark;

    @include wordbreak;

    &--bold {
        font-weight: $font-weight-bold;
    }

    &--light {
        font-size: $font-size-tiny;
        color: $color-text-light;
    }

    &--subtle {
        font-size: $font-size-big;
        color: $color-text-gray;
    }

    &--inline {
        padding-left: $unit-size-base;
        display: inline-block;
        margin: 0;
        align-self: center;
    }

    &--main {
        font-size: $font-size-base;
        font-family: $font-family-soft;
        text-align: center;

        @include breakpoint('l') {
            text-align: left;
        }
    }

    &--wizard {
        font-size: $font-size-base;
        font-family: $font-family-soft;
        color: $color-text-gray;
    }
}

p {
    margin-top: $unit-size-base;
    margin-bottom: 0;
}

em {
    font-style: normal;
    color: $color-text-accent;
}

.#{$ns}link {
    position: relative;
    text-decoration: none;
    cursor: pointer;

    @include wordbreak;

    &:hover {
        opacity: 0.75;
    }

    &--block {
        display: block;
    }

    &--emphasized {
        border-bottom-width: 1px;
        border-bottom-style: dashed;

        &:hover {
            text-decoration: none;
        }
    }

    &--disabled {
        cursor: default;

        &:hover {
            cursor: default;
        }
    }

    &--inline {
        display: inline-block;
    }
}

.#{$ns}link-underline {
    &:hover {
        text-decoration: none;
    }

    &:hover &__content {
        text-decoration: underline;
    }
}

.#{$ns}divider {
    display: inline-block;
    width: $unit-size-large;
    margin-left: - $unit-size-base;
    margin-right: - $unit-size-base;
    float: left;
    height: $unit-size-small * 7;
    line-height: $unit-size-small * 7;
    text-align: center;

    &--small {
        height: $unit-size-small*5;
        line-height: $unit-size-small*5;
    }
}

.#{$ns}hr {
    border-top: $unit-size-small*0.5 solid $color-accent-base;
    font-size: $font-size-super;
    height: 1em;

    &::before {
        content: '';
        width: 1.2em;
        display: block;
    }

    &--light {
        border-color: $color-background-light;
        border-width: $unit-size-small*0.5 0 0 0;
        height: 0;

        &::before {
            display: none;
        }
    }
}

.#{$ns}hd {
    margin: 0;
    font-family: $font-family-base;
    font-weight: $font-weight-extrabold;
    color: $color-text-dark;

    &--light {
        font-weight: $font-weight-normal;
        color: $color-text-light;
    }

    &--decorative {
        font-family: $font-family-decorative;
        font-weight: 400;
    }

    &--d2 {
        font-size: $font-size-enormous;
    }

    &--d1 {
        font-size: $font-size-huge;
    }

    &--h1 {
        font-size: $font-size-super;
    }

    &--h2 {
        font-size: $font-size-big;
    }

    &--h3 {
        font-size: $font-size-plus;
    }

    &--h4 {
        font-size: $font-size-base;
    }

    &--h5 {
        font-size: $font-size-small;
    }

    &--h6 {
        font-size: $font-size-tiny;
    }
}

.#{$ns}bt {
    &--b1 {
        font-size: $font-size-plus;
    }

    &--b2 {
        font-size: $font-size-base;
    }

    &--caption {
        font-size: $font-size-tiny;
    }

    &--overline {
        font-size: $font-size-micro;
    }
}

.#{$ns}bullet-list {
    margin: 0;

    &__item {
        position: relative;
        margin: $unit-size-small 0 $unit-size-small $unit-size-small*3;
        list-style-position: inside;
        list-style-type: none;

        &::before {
            position: absolute;
            top: 0;
            left: -$unit-size-small*3;

            @include fa-light;

            content: '\f105';
            color: $color-accent-base;
        }

        &--check {
            &::before {
                content: '\f00c';
                color: $color-text-base;
            }
        }

        // Extension of the list due to the login / sign up page
        &--secondary {
            margin: $unit-size-small 0 $unit-size-small $unit-size-small*4;

            &::before {
                color: $color-accent-base;
                left: -$unit-size-small*4;
            }
        }
    }
}

@mixin link-color($name, $color, $color-hover) {
    .#{$ns}link--#{$name} {
        color: $color;
        line-height: $line-height-base;

        &:hover {
            opacity: 1;
            color: $color-hover;
            text-decoration: inherit;
        }

        &[disabled="disabled"] {
            opacity: 0.5;
            cursor: default;
        }

        @content;
    }
}

@include link-color('primary', $color-primary-base, $color-primary-dark);
@include link-color('secondary', $color-text-base, $color-text-dark);
@include link-color('accent-plain', $color-text-accent, $color-text-accent);
@include link-color('accent', $color-text-accent, $color-text-accent);
@include link-color('light', $color-text-light, $color-text-light);
@include link-color('inverse', $color-text-inverse, $color-text-inverse);
@include link-color('invalid', $color-message-error, $color-message-error);
@include link-color('inherit', 'inherit', 'inherit');

.#{$ns}link {
    &--nofx {
        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }
}