@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Collapsible
  */

$collapsible-content-background: $color-white;

.#{$ns}collapsible {
    display: block;
    position: relative;
    overflow: visible;
    border: math.div($unit-size-small, 3) solid $color-background-light;
    border-radius: $common-border-radius;
    color: $color-text-gray;
    transition: min-height $transition-quick;
    min-height: 0;

    &--expanded {
        min-height: $unit-size-base * 10;
    }

    &--no-border {
        border: none;
    }

    &__header {
        padding: $unit-size-base;
        padding-left: $unit-size-large * 2;
        background-color: $color-background-light;
        position: relative;
        cursor: pointer;
        font-weight: $font-weight-extrabold;
        font-size: $font-size-plus;

        @include collapsible-icon;
    }

    &__content {
        display: none;
        background-color: $collapsible-content-background;
        padding: $unit-size-large;
        border-radius: $common-border-radius;

        &--no-rounded {
            border-radius: 0;
        }

        &--expanded {
            display: block;
        }
    }
}

.#{$ns}collapsible-icon {
    @include collapsible-icon() {
        // TODO: This should be default but it might break something. Chnage in #8263
        left: 0px;
    }

    &__wrapper {
        position: relative;
        user-select: none;
    }
}

@mixin collapsible-variant($name, $color, $background-color) {
    .#{$ns}collapsible--#{$name} {
        border-color: $background-color;

        .#{$ns}collapsible {
            &__header {
                background-color: $background-color;
            }
        }

        @content;
    }
}

@include collapsible-variant('primary', $color-primary-base, $color-primary-dark);
@include collapsible-variant('secondary', $color-text-base, $color-text-dark);
@include collapsible-variant('accent', $color-text-accent, $color-text-accent);
@include collapsible-variant('light', $color-text-light, $color-text-light);
@include collapsible-variant('inverse', $color-text-inverse, $color-text-inverse);
