@import 'variables';
@import 'mixins';

/***
  * Component / Chatbox
  */

.#{$ns}bar {
    width: 100%;
    background-color: $color-background-lighter;
    position: sticky;
    top: $unit-size-small * 13;
    z-index: inherit;
    box-shadow: $box-shadow-tight;
    transition: transform $transition-slow;
}