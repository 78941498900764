/***
  * Module / Selection Wizard
  */

.#{$ns}selection-wizard-checkbox {
    margin-top: 0;

    .#{$ns}field-toggle__icon {
        margin-top: 2px; // margin fix for correct label align
    }
}
