@import 'variables';
@import 'mixins';

._divide-y > * ~ * {
    border-top: 1px solid transparent;
}

._divide-y-2 > * ~ * {
    border-top: 2px solid transparent;
}

._divide--disabled > * ~ * {
    border-color: $color-text-disabled;
}

