@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Module / Issues
  */

.#{$ns}issues {
    &-empty-result {
        min-height: $unit-size-large * 15;
        padding: $unit-size-base * 5 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

.#{$ns}issue {
    &__no-results {
        background-color: $color-background-light;
        border-radius: math.div($unit-size-base, 3);
        margin: $unit-size-base 0;
        padding: $unit-size-small * 4;
        cursor: pointer;
        text-align: center;
    }

    &__option {
        position: relative;
        display: flex;
        align-items: center;
        min-height: $unit-size-small * 12;
        background-color: $color-background-light;
        border-radius: math.div($unit-size-base, 3);
        padding: $unit-size-small * 3 $unit-size-small * 6 $unit-size-small * 3 $unit-size-small * 3;
        cursor: pointer;
        flex-wrap: wrap;
        user-select: none;
        color: $color-text-gray;

        @include breakpoint(s) {
            flex-wrap: nowrap;
            padding: $unit-size-small * 3 $unit-size-small * 12 $unit-size-small * 3 $unit-size-small * 3;
        }

        &-wrapper {
            background-color: $color-background-light;
            margin-top: $unit-size-base;
            overflow: hidden;

            &--expanded {
                border-color: $color-primary-base;
                background-color: $color-primary-base;
            }
        }

        &-collapsible {
            background-color: $color-background-light;
            margin: 0;
            border-style: none;
            border-radius: 0;
            padding: $unit-size-small * 3 $unit-size-small * 3 $unit-size-small * 8;

            @include breakpoint(s) {
                padding: $unit-size-small * 3 $unit-size-small * 3 $unit-size-small * 3 $unit-size-small * 12;
            }

            &--expanded {
                color: $color-white;
                background-color: $color-primary-base;
            }

            &-icon {
                color: $color-text-gray;

                @include collapsible-icon() {
                    font-size: $font-size-big;
                    left: $unit-size-small * 4;
                    top: auto;
                    bottom: 0;

                    @include breakpoint(s) {
                        top: 50%;
                        bottom: auto;
                        left: $unit-size-small * 5;
                    }
                }

                &--expanded {
                    color: $color-white;
                    background-color: $color-primary-base;

                    &::after {
                        color: $color-white;
                    }
                }
            }

            &-content {
                background: $color-white;
                padding: $unit-size-small * 3;
                border-color: $color-primary-base !important;

                @include breakpoint(s) {
                    padding: $unit-size-small * 5 $unit-size-small * 5 $unit-size-small * 5 $unit-size-base * 6;
                }
            }

            &-sub-content {
                display: inline;
                margin-top: $unit-size-base;

                @include breakpoint(s) {
                    margin-top: 0;
                }

                &-text {
                    display: inline;

                    @include breakpoint(s) {
                        margin-right: $unit-size-small * 3;
                    }
                }

                &-button {
                    width: $unit-size-small * 17;
                    padding: $unit-size-small $unit-size-small * 2;
                    text-align: left;
                    max-height: $unit-size-small * 5;

                    &-icon {
                        padding-right: 25%;
                        line-height: normal;
                    }
                }

                &-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: center;
                    margin-top: 0;

                    @include breakpoint(s) {
                        text-align: right;
                        flex-direction: row;
                    }
                }
            }
        }

        &-header {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            flex-basis: 100%;

            @include breakpoint(s) {
                flex-basis: auto;
            }

            &--with-image {
                width: 100%;
            }
        }

        &-title {
            font-size: $font-size-base;
            white-space: normal;
            font-weight: $font-weight-normal;
            color: inherit;

            @include breakpoint(m) {
                font-size: $font-size-plus;
            }
        }

        &-subtitle {
            display: block;
            font-size: $font-size-tiny;
            color: $color-text-light;
        }

        &-icon-box {
            display: none;

            @include breakpoint(s) {
                display: flex;
                padding: 0 $unit-size-small * 3 0 0;
                justify-content: center;
                align-items: center;
                min-width: $unit-size-small * 9;
            }

            &-icon-box-inner {
                background-color: $color-white;
                border-radius: math.div($unit-size-base, 3);
                padding: math.div($unit-size-small, 3);
                box-shadow: $box-shadow-tight;
            }

            img {
                background-color: $color-white;
                border-radius: math.div($unit-size-small, 3);
                min-height: $unit-size-base * 3;
                min-width: $unit-size-base * 3;
                max-height: $unit-size-base * 3;
                max-width: $unit-size-base * 3;
                margin: $unit-size-small 0;

                @include breakpoint(m) {
                    margin: 0;
                }
            }
        }

        &-navigation-box {
            position: absolute;
            right: 0;
            top: 0;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $color-accent-base;
            margin-left: auto;
            min-width: 1px;
            padding: 0 $unit-size-small * 3;

            &--left {
                position: relative;
                display: block;
                width: 100%;
                margin: $unit-size-small 0 0 0;
                color: $color-text-base;
            }

            &--mobile-only {
                @include breakpoint('s') {
                    display: none;
                }
            }

            @include breakpoint(s) {
                padding: 0 $unit-size-small * 4;
            }

            &-icon {
                font-size: $font-size-big;
                color: $color-accent-base;
            }
        }
    }

    &__dot-icon {
        display: inline-block;
        height: math.div($unit-size-base, 3);
        width: math.div($unit-size-base, 3);
        background-color: $color-input-border;
        border-radius: 50%;
    }
}
