@import 'variables';
@import 'mixins';

/***
  * Module / Stacked bar chart
  */

.#{$ns}stacked-bar-chart-series {
    position: relative;
    background: $color-background-base;
    border-radius: $unit-size-small * 0.5;
    overflow: hidden;
    height: $unit-size-small;
    display: flex;

    &__item {
        height: 100%;
    }
}
