@import './mixins';
@import './variables';

/***
  * Browser hacks
  */

::-webkit-inner-spin-button {
    display: none;
}

/***
  * Hide captcha bug according to:
  * https://developers.google.com/recaptcha/docs/faq
  */
.grecaptcha-badge {
    display: none;
}

[data-display-mode="pwa"] img {
    pointer-events: none;
}