@import 'mixins';
@import 'variables';

/***
  * Components / Zoomable gallery
  */

  // Modal
  .#{$ns}zoomable-gallery-modal {
    padding: 0;
    width: 100vw;
    max-width: 100vw;
    margin: 0;
    height: 100vh;
    height: 100dvh;
    border-radius: 0;

    @include breakpoint("l") {
        padding: 0;
        width: 900px;
        max-width: 900px;
        border-radius: 9px;
        height: initial;
        transform: translate(-50%, -50%);
        left: 50%;
        top: 50%;
    }

    &__nav {
      height: 5vh;
      height: 5dvh;

      @include breakpoint("s") {
        height: 10vh;
        height: 10dvh;
      }

      @include breakpoint("l") {
        height: initial
      }
    }

  }

  // Gallery
  .#{$ns}zoomable-gallery {
    height: 95vh;
    height: 95dvh;
    position: relative;
    @include breakpoint("s") {
      height: 90vh;
      height: 90dvh;
    }

    &__slider {
        height: 100%;
        position: relative;
    }
    
    &__slide {
        @include embed-fill();
        opacity: 0;
        transition: opacity $transition-default;
        cursor: default;
        pointer-events: none;
        height: 90%;

        @include breakpoint("s") {
          height: 85%;
        }

        @include breakpoint("l") {
          height: 90%;
        }
        
        &.is-active {
            opacity: 1;
            pointer-events: all;
        }
    }

    &__controls {
      position: absolute;
      width: 100%;
      height: 10%;
      bottom: 0;

      @include breakpoint("s") {
        height: 15%;
      }

      @include breakpoint("l") {
        height: 10%;
      }
    }

      &__image {
        position: relative;
        height: 100%;
        overflow: hidden;

        img {
          object-fit: contain;
          max-height: 100%;
        }
    }
  }

  //Nav
  .#{$ns}zoomable-gallery-nav-item {
    position: relative;
    transform: translate(0,0);
    top: 0;
    left: 0;
    text-indent: initial;
    font-size: initial;
}
