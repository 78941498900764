@import 'mixins';
@import 'variables';

.#{$ns}media-description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @include breakpoint('m') {
        flex-direction: row;
    }

    &--reverse {
        @include breakpoint('m') {
            flex-direction: row-reverse;
        }
    }

    &__body {
        margin-bottom: $unit-size-small * 3;

        @include breakpoint('m') {
            width: 320px;
            margin-bottom: 0;
            max-width: 40%;
        }
    }

    &__aside {
        width: 100%;

        @include breakpoint('m') {
            width: 380px;
            max-width: 50%;
        }
    }
}
