@import 'variables';
@import 'mixins';

/***
  * Basic / Elements
  */

* {
    &,
    &::after,
    &::before {
        box-sizing: border-box;
    }
}

html {
    overflow-y: scroll;
    min-height: 100%;
    font-size: $font-size-base;
    font-family: $font-family-base;
    line-height: $line-height-base;
    background-color: $color-background-light;
    color: $color-text-base;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

a {
    color: inherit;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

button {
    background-color: transparent;
    border: none;
    color: inherit;
    cursor: pointer;
}

b,
strong {
    font-weight: $font-weight-extrabold;
}

/* stylelint-disable */
p,
label {
    a {
        text-decoration: underline;
    }
}

img {
    max-width: 100%;
    font-style: italic;
    vertical-align: middle;
}

img[width],
img[height] {
    max-width: none;
}

ol,
ul {
    padding-left: 0;
    margin-left: $unit-size-large;
}

dl {
    display: block;

    dt {
        font-weight: bold;
        display: inline-block;
    }

    dd {
        margin-left: $unit-size-large;
        display: inline-block;
    }
}

select,
textarea,
input {
    border: 0;
}

select,
textarea,
input,
div[contenteditable="true"]:not(.ql-editor) {

    &:not([readonly]):not([disabled]):active,
    &:not([readonly]):not([disabled]):focus {
        @include field-focus;
    }
}

fieldset {
    display: block;
    border: 0;
    margin: 0;
    padding: 0;
}

iframe {
    max-width: 100%;
}