@import 'variables';
@import 'mixins';

/***
  * Component / Navbar
  */

$navbar-border-size: $unit-size-small * 0.5;

.#{$ns}navbar {
    @include navbar-height();

    position: relative;
    background-color: $color-background-light;

    &--lighter {
        background-color: $color-background-lighter;
    }

    &--dark {
        background-color: $color-background-dark;
        color: $color-text-inverse;
    }

    &--transparent {
        background-color: transparent;
    }

    &--elevate {
        box-shadow: $box-shadow-base;
    }

    &__wrap {
        position: relative;
    }

    &--layout {
        @include layout('full');
    }
}

/***
  * Component / Navbar Nav
  */

.#{$ns}navbar-nav {
    position: relative;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    max-width: 100%;
    height: 100%;

    &--center {
        justify-content: center;
    }

    &--scrollable {
        &::after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            width: $unit-size-small * 2;
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 50%);
        }
    }

    &--scrollable &__wrapper {
        display: inherit;
        flex: inherit;
        flex-direction: inherit;
        justify-content: inherit;
        overflow-x: auto;
        scrollbar-width: none;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: auto;

        &::-webkit-scrollbar {
            display: none;
        }
    }
}

/***
  * Component / Navbar Item
  */

.#{$ns}navbar-item {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    align-content: center;
    position: relative;

    &.is-active {
        font-weight: bold;
        box-shadow: inset 0 -2px 0 $color-accent-base;

        @include breakpoint('m') {
            box-shadow: inset 0 -3px 0 $color-accent-base;
        }
    }

    &__button-wrapper {
        border-radius: 24px;
        white-space: nowrap;
        overflow: hidden;
    }
}

/***
  * Component / Navbar Link
  */

.#{$ns}navbar-link {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0 $unit-size-small * 2;
    min-width: $unit-size-base * 4;
    height: 100%;
    white-space: nowrap;
    align-content: center;

    @include breakpoint('l') {
        padding: 0 $unit-size-small * 3;
    }

    &.is-active {
        font-weight: bold;
    }

    &--no-fx:hover {
        text-decoration: none;
    }

    &--disabled {
        opacity: 0.5;
    }
}

/***
  * Component / Navbar Tooltip
  */

.#{$ns}navbar-tooltip {
    display: flex;
    position: relative;
    height: 100%;
}

/***
  * Component / Navbar Dropdown
  */

.#{$ns}navbar-dropdown {
    position: relative;

    &__content {
        @include navbar-height(top);

        right: 0;
        position: absolute;
        margin-top: -$unit-size-small;
        outline: none;
        justify-content: flex-end;
        padding: 0 $unit-size-small * 2;

        @include breakpoint('m') {
            position: absolute;
            top: 100%;
            right: 0;
            left: auto;
            padding: 0;
        }

        opacity: 0;
        pointer-events: none;
        transition: $transition-opacity;
        z-index: -1;

        &.is-visible {
            opacity: 1;
            z-index: 3;
            pointer-events: all;
        }
    }
}

.#{$ns}navbar-dropdown-pointer {
    &::after {
        @include arrow($color-background-dark, $unit-size-small, top);

        opacity: 0;
        content: '';
        position: absolute;
        bottom: $unit-size-small;
        right: 50%;
        margin-right: -$unit-size-small;
        transition: $transition-opacity;
    }
    
    &--invert {
        &::after {
            @include arrow($color-background-lighter, $unit-size-small, top);

            filter: drop-shadow($drop-shadow-base);
            z-index: $z-nav + 3; // one more than the balloon, to not be covered by its shadow
        }
    }

    &.is-visible::after {
        opacity: 1;
    }

    &:hover {
        text-decoration: none;
    }
}

/***
  * Component / Navbar Icon
  */
.#{$ns}navbar-icon {
    display: block;
    margin-left: $unit-size-base;
    text-decoration: none;

    &:first-child {
        margin-left: 0;
        margin-right: $unit-size-base;
    }

    &::after {
        float: left;
        display: block;
        font-family: $font-family-icons;
        color: $color-accent-base;
        text-decoration: none;
        font-weight: normal;
        text-align: center;
        width: 1em;

        @include breakpoint('m') {
            font-size: $font-size-big;
        }
    }

    &--micro {
        &::after {
            font-size: $font-size-micro;
        }
    }
}

/***
  * Component / Navbar Icon
  */

.#{$ns}navbar-btn {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 0 $unit-size-base;
    min-width: $unit-size-base * 4;
    touch-action: manipulation;

    &--header {
        @include breakpoint('m') {
            min-width: $unit-size-base * 6;
        }
    }

    &--narrow {
        min-width: $unit-size-base * 3;
    }
}

/***
  * Component / Navbar step
  */

.#{$ns}navbar-step {
    $badge-width: $unit-size-small * 2;

    &::after {
        content: '';
        position: absolute;
        border-radius: 50%;
        height: $badge-width;
        left: 50%;
        top: 0;
        transform: translate(-50%, -50%);
        width: $badge-width;
        z-index: 1;
        background-color: $color-primary-disabled;
    }

    &::before {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        right: 50%;
        width: 100%;
        height: 3px;
        background-color: $color-primary-disabled;
        transform: translateY(-50%);
    }

    &:first-child::before {
        display: none;
    }

    &.is-completed {

        &::after,
        &::before {
            background-color: $color-accent-base;
        }
    }
}

/***
  * Component / Navbar badge
  */

.#{$ns}navbar-badge {
    display: inline-flex;
    background-color: $color-background-light;
    padding: 0 $unit-size-small * 0.5;
    color: $color-text-light;
    border-radius: $unit-size-small * 0.5;
    font-weight: normal;
    font-size: $font-size-micro;
}