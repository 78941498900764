@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Timeline
  */

$timeline-border: dotted 2px;
$timeline-dot-border: 2px;

.#{$ns}timeline {
    &__item-wrapper {
        position: relative;
        overflow: visible;
        display: flex;
        align-self: stretch;
        align-items: center;

        &::before {
            content: '';
            display: inline-block;
            align-self: center;
            margin-right: $unit-size-base;
            border-radius: 50%;
            border: solid $timeline-dot-border;
            border-color: inherit;
            color: inherit;
            padding: $unit-size-small * 0.5;
        }
    }

    &__item {
        border-color: inherit;
        color: inherit;

        &::before, &::after {
            content: '';
            display: block;
            position: absolute;
            left: $unit-size-small + 4px;
            border-left: $timeline-border;
            height: calc(50% + #{$unit-size-small});
            border-color: inherit;
            color: inherit;
        }

        &::before {
            bottom: -10px;
        }

        &::after {
            top: -11px;
        }

        &--first {
            &::after {
                display: none;
            }
        }

        &--last {
            &::before {
                display: none;
            }
        }

        &--short {
            &::before {
                height: calc(50% + #{math.div($unit-size-small, 3)});
                bottom: -5px;
            }

            &::after {
                height: calc(50% + #{math.div($unit-size-small, 3)});
                top: -5px;
            }
        }
    }
}
