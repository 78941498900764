@import 'variables';
@import 'mixins';

/***
  * Module / Profile banner
  */

.#{$ns}profile-banner {
    padding: $unit-size-small * 4 0;
    background-color: $color-background-dark;
    color: $color-text-inverse;
    background-repeat: repeat;
    background-image: url('#{$image-path}profile/profile-banner.svg');
    background-size: 25%;

    @include breakpoint('l') {
        padding: $unit-size-small * 6 0;
    }

    &__name {
        line-height: 1;
    }

    &__followers {
        margin-top: $unit-size-small * 0.5;
        line-height: $font-size-base;
    }

    &__bio {
        text-align: center;

        @include breakpoint('l') {
            text-align: left;
        }
    }

    &__social {
        justify-content: center;

        @include breakpoint('l') {
            justify-content: flex-start;
        }
    }
}

/***
  * Module / Profile navigation
  */

.#{$ns}profile-navigation {
    position: sticky;
    top: -1px;
    z-index: 49;
    box-shadow: 0 0px 10px 0 rgba(0, 0, 0, 0.12);
    color: var(--color-text-gray);
    width: 100%;
    border-top: 1px solid #fff;
}

/***
  * Module / Profile navigation
  */

.#{$ns}profile-view {
    position: relative;
    min-height: $unit-size-small * 60;

    @include breakpoint('l') {
        min-height: $unit-size-small * 100;
    }
}

/***
  * Module / Profile navigation
  */

.#{$ns}profile-navbar-nav {
    @include breakpoint('l') {
        justify-content: center;
    }
}

/***
  * Module / Profile card
  */

.#{$ns}profile-card {
    display: flex;

    @include breakpoint('l') {
        justify-content: center;
        text-align: center;
    }

    &__title, &__verified {
        line-height: 1;
    }

    &__media {
        width: 65%;

        @include breakpoint('l') {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }

    &__badges {
        min-height: $line-height-base * 1em;
    }

    &__avatar {
        width: $unit-size-small * 11;
        height: $unit-size-small * 11;

        @include breakpoint('l') {
            width: $unit-size-small * 16;
            height: $unit-size-small * 16;
        }
    }

    &__body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    &__follow {
        display: flex;
        margin-top: $unit-size-small * 3;
        gap: $unit-size-small;

        @include breakpoint('l') {
            flex-direction: column;
            min-height: $line-height-base * 2em;
            justify-content: center;
            align-items: center;
            gap: 0;
        }
    }

    &__wrapper {
        padding: $unit-size-small * 2;

        @include breakpoint('l') {
            padding: $unit-size-small * 5;
        }
    }
}

.#{$ns}avatar-image {
    width: 154px;
    height: 154px;
    object-fit: cover;
}
