@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Component / Badge
  */

.#{$ns}avatar {
    border-radius: 100% !important;
    box-shadow: $box-shadow-base;

    &--clean {
        border: none;
    }

    &--followed {
        padding: $unit-size-small * 0.5;
        width: $unit-size-base + math.div($unit-size-small, 3);
        height: $unit-size-base + math.div($unit-size-small, 3);
        position: absolute;
        bottom: -($unit-size-small * 0.5);
        right: -(math.div($unit-size-small, 3));
        border-radius: 50%;
        font-size: $font-size-plus * 0.5;
        animation: beat 0.5s ease-in-out 1;
        line-height: 1;
        contain: content;
    }

    &--bordered {
        border: math.div($unit-size-small, 3) solid $color-white;
    }
}