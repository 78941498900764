@import 'variables';
@import 'mixins';

/***
  * Component / Floating
  */

.#{$ns}floating-content {
    position: fixed;
    z-index: $z-modal;
    padding: $unit-size-small * 2;
}

.#{$ns}floating-content-trigger {
    position: relative;

    &:hover::before {
        content: '';
        opacity: 0;
        height: $unit-size-base + 2;
        width: 100%;
        display: block;
        position: absolute;
        top: -($unit-size-base + 2);
    }
}

.#{$ns}floating-balloon {
    position: relative;
    padding: $unit-size-small * 2;
    min-width: auto;
    transform: none;
    left: auto;
    right: auto;
    word-break: break-word;
    width: 70vw;

    @include breakpoint('s') {
        width: $unit-size-base * 24;
    }
}
