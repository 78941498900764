@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Module / Product
  */

.#{$ns}product {
    color: $color-text-gray;
    background-color: $color-white;
    box-shadow: $box-shadow-base;

    &--modal {
        box-shadow: none;
        padding-bottom: $unit-size-small * 2;
        min-height: $unit-size-small * 70;
    }

    &__layout {
        position: relative;
        padding-bottom: $unit-size-base * 3;

        @include layout;

        @include breakpoint('xl') {
            @include layout('narrow');
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__gallery {
        margin: 0 0 $unit-size-small * 4;

        @include breakpoint('m') {
            margin-bottom: 0;
            padding-right: $unit-size-small * 3;
        }
    }

    &__info {
        @include breakpoint('m') {
            padding-left: $unit-size-small * 3;
        }
    }

    &__description {
        @include wordbreak;
    }

    &__action {
        overflow: hidden;
        font-size: $font-size-big;

        @include breakpoint('m') {
            font-size: $font-size-base;
        }
    }

    &__short-description {
        @include wordbreak;
    }

    &__option {
        display: inline-block;
        padding-right: $unit-size-large;
    }

    &__submit {
        display: block;
    }

    &__amount {
        position: relative;
        display: inline-block;
        clear: both;

        &--standalone {
            order: 1;

            @include breakpoint('s') {
                margin-left: 25%;
            }

            @include breakpoint('l') {
                margin-left: 0;
                clear: none;
            }
        }

        &--standalone > &-input {
            margin: 0 $unit-size-large * 3;

            @include breakpoint('s') {
                margin: 0 $unit-size-small * 9;
            }

            @include breakpoint('l') {
                margin: 0;
            }
        }
    }

    &__amount-input {
        margin: 0 $unit-size-large * 2;

        @include breakpoint('s') {
            margin: 0 $unit-size-large * 2;
        }

        @include breakpoint('l') {
            margin: 0;
        }
    }

    &__amount-btn {
        z-index: $z-base + 1;
        position: absolute;
        height: $unit-size-small * 7;
        width: $unit-size-small * 7;
        top: 0;

        &--plus {
            right: 0;
        }

        @include breakpoint('l', 'max-width') {
            border-radius: 50px;
            font-size: $font-size-big;
        }

        @include breakpoint('l') {
            right: math.div($unit-size-small, 3);
            height: $unit-size-small * 3;
            width: $unit-size-small * 3;
            display: block;

            &--plus {
                top: math.div($unit-size-small, 3);
            }

            &--minus {
                left: auto;
                top: $unit-size-large - math.div($unit-size-small, 3);
            }
        }
    }

    &__amount-wrapper {
        display: flex;
        flex-direction: column;
        gap: $unit-size-small * 3;

        @include breakpoint('s') {
            flex-direction: row;
        }

        @include breakpoint('m') {
            flex-direction: column;
        }

        @include breakpoint('l') {
            flex-direction: row;
        }

        &--sticky {
            @include breakpoint('l') {
                justify-content: flex-end;
            }
        }
    }
}

.#{$ns}product-info {
    position: relative;
    padding-left: $unit-size-base;
    padding-right: $unit-size-base;

    @include breakpoint('m') {
        padding-left: 0;
        padding-right: 0;
    }

    &__name {
        font-weight: $font-weight-normal;
        color: $color-text-base;
    }

    &__abstract {
        @include wordbreak;

        font-family: $font-family-soft;
        color: $color-text-gray;
        margin-top: $unit-size-small * 3;
        margin-bottom: $unit-size-base * 3;
    }

    &__add-to-cart {
        margin-bottom: $unit-size-base * 3;

        &--sticky {
            @include breakpoint('m') {
                min-width: 370px;
            }
        }

    }

    &__badge {
        text-transform: uppercase;
        margin-right: $unit-size-small * 3 * 0.5;
        margin-bottom: 0;
    }

    &__footer {
        position: sticky;
        bottom: 0;
        margin: 0;
        padding: $unit-size-base;
        background-color: $color-white;
        justify-content: flex-end;
        visibility: hidden;
        opacity: 0;
        display: none;

        @include breakpoint('m') {
            margin: 0 (-$unit-size-base * 3);
            padding: $unit-size-base ($unit-size-base * 3);
        }

        &--visible {
            visibility: visible;
            opacity: 1;
            display: flex;
            flex-direction: column;

            @include breakpoint('l') {
                flex-direction: row;
            }
        }
    }
}

.#{$ns}product-set-items {
    position: relative;
    display: flex;
    flex-flow: column wrap;
    margin: -$unit-size-small;

    @include breakpoint('m') {
        flex-direction: row;
    }

    &__item {
        padding: $unit-size-small;
    }
}

.#{$ns}product-set-item {
    padding: $unit-size-small * 2;
    background-color: $color-background-light;
    color: $color-primary-dark;
    height: 100%;
    align-items: center;
    border-radius: $common-border-radius;

    &__name {
        overflow: hidden;
        max-height: $unit-size-small * 6;
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        line-height: $unit-size-small * 3;
        margin-bottom: math.div($unit-size-small, 3);
    }

    img {
        max-width: $unit-size-base * 4;
        max-height: $unit-size-base * 4;
        overflow: hidden;
    }
}

.#{$ns}product-image:hover {
    cursor: zoom-in;
}

.#{$ns}product-addon {
    &__price {
        font-size: $font-size-plus;
    }

    &__remaining-stock {
        font-size: $font-size-nano;
        vertical-align: baseline;
    }
}

$product-modal-row-height: 56px;
$product-image-big: 84px;

.#{$ns}product-modal {
    &__wrapper {
        @include breakpoint('l') {
            display: flex;
        }
    }

    &__column-left {
        display: flex;
        width: $product-modal-row-height;

        @include breakpoint('l') {
            width: $product-image-big;
        }
    }

    &__column-right {
        display: flex;
        flex-flow: wrap;
        width: calc(100% - #{$product-modal-row-height});
        margin-left: $unit-size-base;

        @include breakpoint('s') {
            margin-left: $unit-size-base * 2;
        }

        @include breakpoint('l') {
            width: calc(100% - #{$product-image-big});
        }
    }

    &__header {
        display: flex;
        background-color: $color-background-light;
        padding: $unit-size-base;
        border-radius: $common-border-radius;

        @include breakpoint('s') {
            padding: $unit-size-small * 3;
        }
    }

    &__header-info-wrapper {
        display: flex;
        width: 100%;
        flex-flow: wrap;
    }

    &__header-title-wrapper {
        width: 100%;

        @include breakpoint('l') {
            width: 66%;
            order: 2;
        }
    }

    &__header-title {
        @include wordbreak;

        display: flex;
        align-items: center;

        @include breakpoint('l', 'max-width') {
            margin-top: $unit-size-small;
            margin-bottom: $unit-size-small;
        }

        @include breakpoint('l') {
            height: $product-modal-row-height;
            margin-top: $unit-size-small;
        }
    }

    &__header-options-wrapper {
        width: 100%;
    }

    &__header-input-wrapper {
        margin-left: 0;
        padding-right: $grid-space-size-m*0.5;

        @include breakpoint('l') {
            padding-right: $grid-space-size-l*0.5;
        }

        @include breakpoint('xl') {
            padding-right: $grid-space-size-xl*0.5;
        }
    }

    &__header-image {
        justify-self: flex-start;
        height: $product-modal-row-height;
        min-width: $product-modal-row-height;
        width: $product-modal-row-height;
        padding: 0;

        @include breakpoint('l') {
            height: $product-image-big;
            min-width: $product-image-big;
            width: $product-image-big;
        }
    }

    &__image-wrapper,
    &__title-wrapper {
        @include breakpoint('l') {
            display: flex;
            margin-top: $unit-size-small;
            margin-bottom: $unit-size-base;
        }
    }

    &__image {
        justify-self: flex-start;
        height: $product-modal-row-height;
        min-width: $product-modal-row-height;
        width: $product-modal-row-height;
        padding: 0;
    }

    &__title-wrapper {
        @include breakpoint('l') {
            order: 2;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        height: $product-modal-row-height;
    }
}

.#{$ns}product-details-slider {
    @include aspect-ratio-1-1;

    &__slide {
        @include embed-fill;

        opacity: 0;
        transition: opacity $transition-default;
        cursor: default;

        &.is-active {
            opacity: 1;
        }
    }

    &__image {
        pointer-events: none;
    }

    &__indicators {
        @include breakpoint('l') {
            max-width: $unit-size-small * 70;
        }
    }
}

.#{$ns}product-details-fullscreen {
    position: fixed;
    overflow: hidden;
    background: $color-background-darker;
    height: 100%;
    width: 100%;
    padding: 0;

    @include breakpoint('m') {
        padding: $unit-size-base * 4;
    }

    &__wrap {
        position: relative;
        height: 100%;
        z-index: 1;
    }

    &__indicators {
        bottom: $unit-size-base * 2;

        @include breakpoint('m') {
            bottom: -$unit-size-base * 2;
        }
    }

    &__progress {
        background-color: transparent;
        z-index: 0;
    }

    &__slide {
        @include embed-fill;

        opacity: 0;
        transition: opacity $transition-default;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;

        &.is-active {
            opacity: 1;
        }
    }
}

.#{$ns}product-status {
    min-height: $unit-size-small * 6;
}

.#{$ns}product-content {
    padding-left: $unit-size-base;
    padding-right: $unit-size-base;

    @include breakpoint('m') {
        padding-left: 0;
        padding-right: 0;
    }
}

.#{$ns}product-modal {
    min-height: $unit-size-small * 70;
}
