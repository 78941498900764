@import 'variables';
@import 'mixins';

/***
  * Module / Bottom bar
  */

.#{$ns}project-bottom-bar {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &--compact {
        $compact-height: $unit-size-small * 10;

        height: calc($compact-height + env(safe-area-inset-bottom));
        max-height: calc($compact-height + env(safe-area-inset-bottom));
    }

    &__content {
        z-index: 1;
        background-color: $color-background-lighter;
        display: flex;
        box-shadow: $drop-shadow-base;
        width: 100%;
        justify-content: center;
        padding: $unit-size-small *0.5 $unit-size-base calc($unit-size-small + env(safe-area-inset-bottom));

        &--absolute {
            padding: $unit-size-small * 3*0.5 $unit-size-base calc($unit-size-small * 3*0.5 + env(safe-area-inset-bottom));
            position: absolute;
            top: 0;
        }
    }
}
