@import 'variables';
@import 'mixins';

/***
* General Message
*/
.#{$ns}general-message-info {
    padding: $unit-size-small $unit-size-small * 2;
    color: $color-text-inverse;
    background-color: $color-primary-base;

    @include breakpoint('l') {
        text-align: center;
    }

    &__icon {
        display: none;

        @include breakpoint('l') {
            display: inline-block;
        }
    }
}
