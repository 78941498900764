@import 'variables';
@import 'mixins';

/***
  * Component / Cloud
  */

.#{$ns}cloud {
    position: relative;
    padding: $unit-size-small $unit-size-base;
    border-radius: $unit-size-small *3;
    font-weight: bold;
    display: inline-block;

    &::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: -10px;

        @include arrow(transparent, $unit-size-small * 2, 'bottom');
    }

    &--accent {
        color: $color-text-inverse;
        background-color: $color-accent-base;

        &::after {
            border-right-color: $color-accent-base;
        }
    }
}
