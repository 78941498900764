@import 'variables';
@import 'mixins';

/***
  * Module / Project Navigation
  */

.#{$ns}project-navigation {
    position: sticky;
    top: -1px; // Fix for 1px space when scaling screen.
    z-index: $z-project-nav;
    box-shadow: $box-shadow-base;
    color: $color-text-gray;
    width: 100%;
    border-top: 1px solid $color-white;

    &.is-open {
        z-index: $z-header + 1;
    }
}

.#{$ns}project-navigation-title {
    @include ellipsis;

    font-weight: $font-weight-extrabold;
}

/***
  * Module / Project Navigations
  */

.#{$ns}project-navigation-main {
    display: flex;
    height: 100%;
    max-height: $navbar-height;
    justify-content: stretch;

    &__logo {
        flex-shrink: 0;
    }

    &__project {
        @include ellipsis;

        font-size: $font-size-big;
        margin-left: $unit-size-base;
        font-family: $font-family-decorative;
        font-weight: normal;
        color: $color-text-base;
    }

    &__nav {
        position: relative;
        flex-grow: 1;
        overflow: hidden;
        scrollbar-width: none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(xl) {
            flex-shrink: 0;
            justify-content: center;
        }
    }

    &__header {
        padding-right: $unit-size-base;
        flex-grow: 1;
        max-width: calc(100% - #{$unit-size-large * 4});
    }

    &__title {
        width: 100%;
    }

    &__side {
        display: flex;
        align-items: center;
        transition: $transition-opacity;
        opacity: 0;
        visibility: hidden;
        justify-content: flex-end;
        flex-shrink: 0;
        width: 0;

        &:first-child {
            justify-content: flex-start;
        }

        @include breakpoint(l) {
            @include layout('full');

            min-width: $grid-column-size-xl * 4;
            width: 25%;
            overflow: visible;
        }
    }

    &.is-stuck &__side {
        opacity: 1;
        width: unset;
        min-width: $unit-size-large * 4;
        visibility: visible;

        @include breakpoint(l) {
            width: 25%;
        }
    }
}

/***
  * Module / Project Quickaction
  */

.#{$ns}project-quickaction {
    position: relative;
    display: inherit;
    justify-content: flex-end;
    align-items: center;

    @include breakpoint(l) {
        margin-right: -$unit-size-base;
    }
}

.#{$ns}project-quickaction-cart {
    @include navbar-height('min-height');
}

/***
  * Module / Project Scrollable
  */

.gfu-project-navigation-scrollable {
    display: flex;
    width: 100%;
    overflow-x: auto;
}


/***
  * Module / Project action
  */

.#{$ns}project-navigation-tooltip {
    margin-right: $unit-size-base;

    &:hover {
        text-decoration: none;
    }

    &__btn {
        position: relative;
        margin-left: -$unit-size-small;
        width: $unit-size-small * 4;
        height: $unit-size-small * 4;
        font-size: $font-size-micro;
        box-shadow: $box-shadow-tight;
        line-height: ($unit-size-small * 4) - 2;
        text-shadow: none;
    }
}

/***
* Slide Animation
*/
.#{$ns}shrink-leave-active {
    transition: all $transition-timing-quick*2 ease-in;
}

.#{$ns}shrink-leave-to {
    width: 0;
}

/***
* Return to navigation
*/
.#{$ns}project-return-to-navigation {
    position: fixed;
    right: $unit-size-base * 2;
    bottom: $unit-size-base * 10;
    width: $unit-size-small * 7;
    height: $unit-size-small * 7;
    background-color: $color-text-lighter;
    color: $color-text-inverse;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $unit-size-small * 7;
    z-index: 1;

    &--right {
        align-self: flex-end;
    }

    &--expanded {
        bottom: $unit-size-base * 28;

        @include breakpoint('l') {
            bottom: $unit-size-base * 10;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

/***
* Project preview
*/
.#{$ns}project-navigation-archive {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    min-height: $navbar-height;
    justify-content: center;
    align-items: center;
    z-index: 1;

    @include breakpoint('l') {
        position: absolute;
        top: -1px; // Fix for 1px space when scaling screen.
    }
}
