@import 'variables';
@import 'mixins';

.#{$ns}skeleton {
    overflow: hidden;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        inset: 0;
        z-index: $z-modal + 1;
    }
}

.#{$ns}skeleton-line {
    background: $color-skeleton-base;

    &--table {
        border: none;
        height: $unit-size-small * 3;
        background-color: $color-skeleton-base;
    }
}

.#{$ns}skeleton-button {
    background: $color-skeleton-base;
    border-radius: $unit-size-base * 2;
}
