@import 'variables';
@import 'mixins';

/***
  * Mixins / Product Cards
  */

@mixin card-wrapper() {
    &__wrapper {
        position: relative;
    }
}

@mixin card-title() {
    &__title {
        font-family: $font-family-decorative;
        font-size: $font-size-extra;
        font-weight: $font-weight-normal;
        line-height: $line-height-small;
        color: $color-text-base;

        @include ellipsis;

        @supports (-webkit-line-clamp: 2) { // IE fix
            flex-shrink: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;

            @include breakpoint('m') {
                -webkit-line-clamp: 2;
            }
        }
    }
}

@mixin card-tags() {
    &__tag-container {
        position: absolute;
        z-index: 1;
        top: -$unit-size-base;
    }

    &__tag {
        font-size: $font-size-nano;
        margin-right: $unit-size-small;
    }
}

@mixin card-body() {
    &__body {
        padding: $card-space;
    }
}

@mixin card-edit {
    &__edit {
        top: $unit-size-small * 1;
        right: $unit-size-small * 1;
        z-index: 2;
        position: absolute;
    }
}

@mixin card-desc {
    &__desc {
        &, span {
            -webkit-line-clamp: initial;
        }
    }

    &__desc {
        max-height: none;
        flex-grow: 1;
        margin-top: $unit-size-base;
        margin-bottom: $unit-size-small;

        &:focus,
        &:hover,
        &:active {
            text-decoration: none;
            outline: none;
        }
    }
}
