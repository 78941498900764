



@import 'variables';
@import 'mixins';

/***
  * Module / Homepage
  */
  
.#{$ns}homepage-wrapper {
    @include layout('homepage');
    padding: $unit-size-small * 4 $unit-size-small * 2;

    @include breakpoint('l') {
        padding: $unit-size-small * 6;
    }
}

.#{$ns}homepage-featured-projects {
    padding: 0;
    @include breakpoint('l') {
        padding: 0 $unit-size-small * 6;
    }

    &__title {
        margin-bottom: $unit-size-small;

        @include breakpoint('l') {
            margin-bottom: $unit-size-small * 3;
        }
    }
}

  /***
    * Module / Featured Projects
    */
  
  .#{$ns}featured-projects {
      min-height: 550px;
  
      @include breakpoint('s') {
          min-height: 500px;
      }

      @include breakpoint('l') {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 2fr));
        grid-template-rows: repeat(2, minmax(0, 530px));
        gap: $unit-size-small * 6;
        min-height: auto;
      }

      @include breakpoint('xl') {
        grid-template-rows: repeat(2, minmax(0, 550px));
      }
  }
  