@import 'variables';
@import 'mixins';

.#{$ns}search-title {
    margin-top: $unit-size-small * 4;

    @include breakpoint(m) {
        margin-top: $unit-size-small * 7;
    }
}

.#{$ns}search-header {
    position: sticky;
    width: 100%;
    top: -1px;

    @include breakpoint(m) {
        position: relative;
        top: auto;
        padding: $unit-size-small * 2 0;
        z-index: 0;
    }

    &.is-stuck {
        background-color: $color-background-lighter;
        box-shadow: $box-shadow-base;
        z-index: $z-header;

        @include breakpoint(m) {
            background-color: transparent;
            box-shadow: none;
            z-index: 0;
        }
    }
}

.#{$ns}project-search-header {
    white-space: nowrap;

    @include breakpoint(m) {
        white-space: normal;
    }

    &__content {
        display: flex;
        position: relative;
        overflow-x: auto;
        scroll-padding-left: 2px;
        padding: $unit-size-small 0 $unit-size-small $unit-size-small * 2;
        margin-left: -$unit-size-small * 2;
        gap: $unit-size-small * 2;

        &::-webkit-scrollbar-track {
            margin-left: $unit-size-small * 2;
        }

        @include breakpoint(m) {
            flex-wrap: wrap;
            margin: 0 (-$unit-size-small);
            padding: $unit-size-small;
        }
    }

    &__button {
        @include breakpoint(m) {
            margin-bottom: $unit-size-small * 2;
        }
    }

    &::after {
        content: '';
        background: linear-gradient(to right, transparent, $color-background-light 90%);
        width: $unit-size-large * 2;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;

        @include breakpoint(m) {
            display: none;
        }
    }

    &.is-stuck::after {
        background: linear-gradient(to right, transparent, $color-background-lighter 90%);
    }
}
