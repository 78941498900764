@import 'variables';
@import 'mixins';

/***
  * Component / List Info
  */

.#{$ns}search-filters {
    &__fields {
        display: block;
        padding-top: $unit-size-large;

        @include breakpoint(m) {
            flex-direction: row;
            display: flex;
            padding-top: 0;
        }

        &--center {
            justify-content: center;
        }
    }

    &__field {
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include breakpoint(m) {
            flex-direction: row;
            padding-right: $unit-size-large;
        }
    }

    &-field {
        &__input {
            flex: 0 0 auto;
            margin-top: 0;
            font-weight: $font-weight-extrabold;
            line-height: 54px;
        }

        &__label {
            flex: 0 0 auto;
            padding: 0;
            line-height: $line-height-base;

            @include breakpoint(m) {
                margin-right: $unit-size-large;
                line-height: $unit-size-small * 9;
            }
        }
    }
}
