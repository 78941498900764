@import "variables";
@import "mixins";

/***
  * Module / Project engagement card
  */

.#{$ns}project-engagement-card {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $unit-size-small * 3;
    justify-content: stretch;

    @include breakpoint('m') {
        flex-direction: row;
    }

    &__media {
        max-width: 100%;

        @include breakpoint('m') {
            width: 180px;
            height: 180px;
        }
    }

    &__body {
        flex-grow: 1;
    }

    &__labels {
        position: absolute;
        top: 0;
        transform: translateY(-50%);

        @include box-padding(left);
    }

    &__row {
        display: flex;
        justify-content: space-between;
        padding: $unit-size-small 0;
        border-top: 2px solid $color-border-light;
        line-height: $unit-size-small * 4;
    }
}

.#{$ns}project-engagement-card-action {
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: stretch;
    gap: $unit-size-small * 2;

    @include breakpoint(m) {
        justify-content: space-between;
        flex-direction: row;
        align-items: center;
    }
}

// TODO: Balloon should not be specific 
.#{$ns}project-engagement-card-actions {
    position: absolute;
    top: -$unit-size-small * 5 * 0.5;
    right: $unit-size-small * 5;
    display: flex;
    flex-direction: column;

    &__dropdown {
        position: absolute;
        right: 0;
        top: $unit-size-base*3;
        z-index: $z-nav + 2;
    }
}
