@use "sass:math";

@import 'variables';
@import 'mixins';

$icon-size: $unit-size-large - math.div($unit-size-base, 3);

.#{$ns}toast-wrapper {
    @include navbar-height(top, $unit-size-small);

    position: fixed;
    left: $unit-size-small * 3;
    right: $unit-size-small * 3;
    z-index: $z-floating-notification + 1;
    margin-bottom: 0;

    @include breakpoint('l') {
        right: auto;
    }

    @include breakpoint('xl') {
        top: auto;
        right: auto;
        bottom: $unit-size-small * 3;
    }
}

.#{$ns}toast {
    position: relative;
    display: flex;
    gap: $unit-size-small;
    font-size: $font-size-base;
    width: 100%;
    background-color: $color-background-lighter;
    padding: $unit-size-small;
    border-radius: $container-border-radius-tight;
    box-shadow: $box-shadow-base;

    @include breakpoint('m') {
        width: $unit-size-base * 30;
        top: auto;
    }

    @include breakpoint('xl') {
        bottom: $unit-size-small * 3;
    }

    &__icon, &__close {
        width: 20px;
        height: 20px;
    }

    &__action {
        position: relative;
        color: $color-accent-base;
        font-weight: $font-weight-bold;
        margin-right: $unit-size-base;
        cursor: pointer;

        &--button {
            padding: $unit-size-small $unit-size-base;
            background-color: $color-primary-base;
            color: $color-white;
            border-radius: $unit-size-base * 2;
            transition: all $transition-slow;

            &:hover {
                @include breakpoint('l') {
                    background-color: $color-primary-variant;
                    color: $color-white;
                    text-decoration: none;
                }
            }
        }
    }
}
