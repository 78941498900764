.#{$ns}floating-message {
    background: $color-white;
    margin: $unit-size-base;
    border-radius: $common-border-radius;
    padding: $unit-size-small;

    @include breakpoint('m') {
        max-width: 360px;
    }
}
