@import 'variables';
@import 'mixins';

/***
  * Skeletons / List
  */

.#{$ns}skeleton-list-item-indicator {
    width: ($unit-size-small * 3) * 0.5;
    height: ($unit-size-small * 3) * 0.5;
    border-radius: 50%;
    background-color: $color-skeleton-base;
}
