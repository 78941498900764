@import 'mixins';
@import 'variables';

/***
  * Skeletons / Boxes
  */

.#{$ns}skeleton-table-header {
    padding: $unit-size-base * 2;
    background-color: $color-skeleton-base;
}

.#{$ns}skeleton-table-cell {
    display: flex;
    justify-content: center;
}

.#{$ns}skeleton-table-row {
    display: flex;
    align-items: center;
    min-height: $table-row-height;
}


/*** 
  * Skeletons / Sticky Table
  */

  .#{$ns}skeleton-sticky-table {
    gap: $unit-size-small * 0.5;
  }

  .#{$ns}skeleton-sticky-table-element {
    position: relative;
    background-color: $color-skeleton-base;
    display: flex;

    &--row-header {
        border-radius: $common-border-radius;
        height: $unit-size-small * 5;
        grid-column: 1 / -1;
        width: 95vw;
        position: sticky;
        left: 0;

        @include breakpoint('xl') {
            position: relative;
            height: $unit-size-small * 9;
            grid-column: 1 / 1;
            width: auto;
        }
    }

    &--column-header {
        border-radius: $common-border-radius $common-border-radius 0 0;
        height: $unit-size-small * 34;

    }

    &--value {
        height: $unit-size-small * 6;

        @include breakpoint('xl') {
            height: $unit-size-small * 9;
        }
    }
  }

  .#{$ns}skeleton-sticky-table-element-separator {
    display: none;

    @include breakpoint('xl') {
        display: block;
    }
  }