@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Tree
  */

$leaf-border-width: 3px;
$leaf-offset-left: - $unit-size-base * 4;
$leaf-width: $unit-size-base * 2;
$leaf-border: dotted $leaf-border-width $color-divider-light;

.#{$ns}tree {
    &__leaf {
        position: relative;

        &::before,
        &::after {
            content: '';
            position: absolute;
            left: $leaf-offset-left;
            width: $leaf-width;
            border-left: $leaf-border;
            display: none;

            @include breakpoint('s') {
                display: block;
            }
        }

        &::before {
            border-bottom: $leaf-border;
            top: - $unit-size-small * 3;
            height: calc(50% + #{$unit-size-small * 3});
        }

        &::after {
            bottom: - $unit-size-small - math.div($unit-size-small * 2, 3);
            height: calc(50% + #{$unit-size-small});
        }

        + .#{$ns}tree__leaf {
            &::before {
                top: $leaf-border-width * 0.5;
                height: 50%;
            }
        }

        &:last-child {
            &::after {
                display: none;
            }
        }
    }
}
