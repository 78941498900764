@import 'mixins';
@import 'variables';

/***
  * Component / Alert
  */

.#{$ns}countdown-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    &__progress {
        transition: 1s stroke-dashoffset linear;
    }

    &__time {
        position: absolute;
        font-size: $font-size-huge;
        font-weight: $font-weight-extrabold;
        color: $color-white;
    }
}