@import 'mixins';
@import 'variables';

/***
  * Module / Info
  */

.#{$ns}about-us {
    position: relative;
}

.#{$ns}info-hero {
    position: relative;
    overflow: hidden;
    width: 100%;
    background-color: $color-primary-dark;
    color: $color-text-inverse;
    height: 80vh;

    @include breakpoint('m') {
        height: 440px;
    }

    &__cover {
        @include embed-fill();

        background-color: $color-background-dark;
        opacity: 0.1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        position: absolute;
        text-align: center;
        top: 0;
        bottom: 0;
        font-size: $font-size-plus;
        width: 100%;
        padding: $unit-size-small * 3;

        @include breakpoint('m') {
            max-width: 560px;
            left: 50%;
            transform: translateX(-50%);
            padding: 0;
        }
    }

    &--fees {
        background-image: url('#{$image-path}fees/fees-hero-m.svg');
        background-size: contain;
        background-position: 50% 50%;

        @include breakpoint('l') {
            background-image: url('#{$image-path}fees/fees-hero-l.svg');
        }
    }
}

.#{$ns}info-hero-video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    opacity: 0.25;

    &--s {
        width: 100%;

        @include breakpoint('m') {
            display: none;
        }
    }

    &--m {
        height: 100%;
        display: none;

        @include breakpoint('m') {
            display: block;
        }

        @include breakpoint('xl') {
            height: auto;
            width: 100%;
        }
    }
}

.#{$ns}info-stats {
    background-image: url($image-path + '/static/about-us/stats.svg');
    background-size: cover;
    color: $color-white;
    margin-left: -$unit-size-small * 4;
    margin-right: -$unit-size-small * 4;

    @include breakpoint('m') {
        margin-left: -$unit-size-small * 6;
        margin-right: -$unit-size-small * 6;
    }

    @include breakpoint('l') {
        margin-left: -$unit-size-small * 12;
        margin-right: -$unit-size-small * 12;
    }
}

.#{$ns}info-media {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: $font-size-plus;
    align-items: center;

    @include breakpoint('m') {
        flex-direction: row;
    }

    &--reverse {
        @include breakpoint('m') {
            flex-direction: row-reverse;
        }
    }

    &__body {
        margin-bottom: $unit-size-small * 3;

        @include breakpoint('m') {
            margin-bottom: 0;
            max-width: 45%;
        }
    }

    &__aside {
        width: 100%;

        @include breakpoint('m') {
            max-width: 45%;

            img {
                max-height: 340px;
            }
        }
    }
}

.#{$ns}info-contact {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    flex-direction: column;
    font-size: $font-size-plus;
    text-align: center;
    box-shadow: $box-shadow-base;
    border-radius: $common-border-radius;
    padding: $unit-size-small * 5;
    justify-content: space-between;

    &__icon {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -65%);
        color: $color-accent-base;
        font-size: $unit-size-small * 6;
    }
}
