@import 'variables';
@import 'mixins';

/***
  * Component / Price
  */

.#{$ns}countdown {
    overflow: hidden;
    height: 100%;
    width: 100%;
    border-top: $unit-size-small*0.5 solid $color-accent-base;
    border-radius: $common-border-radius;
    padding: $unit-size-base;
    min-height: $unit-size-small * 21; // Fix for iOS 10, min height for all devices

    @include breakpoint('m') {
        padding: $unit-size-small * 5;
    }

    &--mobile {
        margin-left: 0;
    }

    &__notify {
        text-align: center;

        @include breakpoint('m') {
            margin-bottom: 0;
        }

        @include breakpoint('xl') {
            padding-top: 0;
        }

        .#{$ns}field__error {
            display: block !important;
        }

        &--top {
            margin-top: $unit-size-base * 3;

            @include breakpoint('m') {
                margin-top: 0;
            }

            @include breakpoint('l') {
                margin-top: $unit-size-base * 3;
            }
        }
    }

    &__hidden-link {
        position: absolute;
        left: -9999px;
    }

    &__title {
        text-align: center;

        @include breakpoint('m') {
            text-align: left;
        }

        @include breakpoint('xl') {
            text-align: center;
        }
    }

    &__tooltip {
        color: $color-text-lighter;
        font-size: $font-size-base;
    }

    &__share-links {
        text-align: center;
        font-size: $font-size-plus;

        @include breakpoint('m') {
            text-align: left;
        }

        @include breakpoint('xl') {
            text-align: center;
        }

        &--top {
            @include breakpoint('m') {
                margin-top: $unit-size-base;
            }
        }

        &--bottom-left {
            margin-bottom: $unit-size-base;

            @include breakpoint('l') {
                margin-bottom: 0;
            }
        }

        &--bottom-right {
            margin-top: $unit-size-base*2;

            @include breakpoint('l') {
                margin-bottom: 0;
            }
        }

        &--left {
            position: absolute;

            @include breakpoint('m') {
                left: $unit-size-small * 5;
                text-align: left;
                width: 40%;
            }

            @include breakpoint('l') {
                text-align: center;
                width: 100%;
                left: 0;
                bottom: $unit-size-small * 5;
            }
        }

        .#{$ns}tooltip__content {
            text-align: center;
        }
    }
}
