@import 'variables';
@import 'mixins';

/***
  * Module / Project description
  */
@mixin project-description-same-consecutive-spacing {
    & + & {
        padding-top: 0;
    }
}

.#{$ns}project-description {
    position: relative;
}

.#{$ns}project-description-section {
    padding-top: $unit-size-small * 6;
    padding-bottom: $unit-size-small * 6;

    &__description {
        padding: $unit-size-small * 3 $unit-size-small * 3;
        border-radius: $common-border-radius;
        background-color: $color-background-light;
        margin-bottom: $unit-size-small * 3;
    }

    &--emphasized {
        background: $color-background-lighter;

        @include project-description-same-consecutive-spacing;
    }

    &--regular {
        @include project-description-same-consecutive-spacing;
    }

    &__scrollable {
        @include breakpoint('m') {
            padding-left: $unit-size-base;
            padding-right: $unit-size-base;
        }

        @include breakpoint('l') {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.#{$ns}project-description-stable-pledge {
    display: flex;
    padding: $unit-size-small * 4 $unit-size-small * 3;
    background-color: $color-background-light;
    border-radius: $block-border-radius;
    justify-content: space-between;
}

.#{$ns}project-description-section-pledge {
    padding-top: $unit-size-base * 3;
    padding-bottom: $unit-size-base * 3;
    display: block;

    > * + * {
        margin-top: $unit-size-base * 3;
    }
}