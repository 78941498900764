@import 'mixins';
@import 'variables';

/***
  * Module / what is crowdfunding
  */

.#{$ns}crowdfunding-guide {
    position: relative;

    &__box {
        overflow: hidden;
    }

    &__idea {
        position: relative;
        background-color: $color-primary-base;
        color: $color-text-inverse;
        padding: $unit-size-base * 2;

        @include breakpoint('m') {
            padding: $unit-size-base * 3;
        }

        @include breakpoint('l') {
            padding: $unit-size-base * 6;
        }

        &::after {
            content: '';
            position: absolute;
            pointer-events: none;
            width: $unit-size-small * 34;
            height: $unit-size-small * 34;
            top: $unit-size-small * 2;
            right: -$unit-size-small * 2;
            background-image: url($image-path + 'static/crowdfunding/g-box-outline.svg');
            background-repeat: no-repeat;
            background-size: contain;
            opacity: 0.12;
        }
    }

    &__content {
        padding: $unit-size-base * 2;

        @include breakpoint('m') {
            padding: $unit-size-base * 3;
        }

        @include breakpoint('l') {
            padding: $unit-size-base * 6;
        }
    }

    &__table-of-contents {
        display: none;
        top: -$unit-size-small * 7;

        @include breakpoint('xxl') {
            display: block;
        }
    }
}
