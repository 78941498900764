@import 'mixins';
@import 'variables';

/***
  * Skeletons / Table with chart
  */

.#{$ns}skeleton-table-with-chart {
    display: flex;

    &::before {
        display: none;
    }

    @keyframes skeletonBackgroundSwept {
        0% {
            background-position: -1200px 0;
        }

        100% {
            background-position: 1200px 0;
        }
    }

    &__table {
        flex-basis: 70%;
    }

    &__chart {
        flex-basis: 30%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
