@import './variables';

/***
  * Labeled divider
  */

.#{$ns}divider-labeled {
    text-align: center;
    display: flex;
    align-items: center;
    padding: 0;

    &::before,
    &::after {
        content: "";
        flex: 1;
        height: $divider-labeled-height;
        border-radius: $divider-labeled-height;
        background-color: currentColor;
    }

    &::before {
        margin-right: $unit-size-base;
    }

    &::after {
        margin-left: $unit-size-base;
    }
}

.#{$ns}base-divider {
    border: none;
    border-bottom: 2px solid $color-background-light;
    margin: $unit-size-base 0;
}
