@import 'mixins';
@import 'variables';

/***
  * Module / Table of contents
  */

.#{$ns}table-of-contents {
    @include layout-space(padding-left);

    display: none;
    position: absolute;
    max-width: $grid-column-size-xl * 5;
    top: 0;
    left: -$unit-size-small * 3;
    bottom: 0;
    width: 20%;
    padding: $unit-size-small * 6 0;

    &__wrapper {

        &--web {
            @include navbar-height(top, $unit-size-base * 3);
        }

        &--pwa {
            @include navbar-height(top, $unit-size-base * 7);
        }

        position: sticky;
        overflow: auto;
        direction: rtl;
        max-height: calc(100vh - #{$unit-size-large * 5 + $unit-size-base});
        height: 100%;

        &::-webkit-scrollbar-track {
            background: $color-background-base;
        }

        &::-webkit-scrollbar-thumb {
            background-color: transparent;
            transition: all $transition-quick;
        }

        &:hover::-webkit-scrollbar-thumb {
            background-color: $color-accent-base;
        }
    }

    &__list {
        position: sticky;
        direction: ltr;
    }

    &__item {
        &.is-active {
            box-shadow: none;

            @include breakpoint(m) {
                box-shadow: none;
            }
        }
    }

    @include breakpoint(xl) {
        display: block;
    }
}
