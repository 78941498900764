@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Component / Field
  */

.#{$ns}fields {
    @include breakpoint('m') {
        max-width: 320px;
        margin: 0 auto;
    }

    &--left {
        @include breakpoint('m') {
            max-width: 320px;
            margin: 0;
        }
    }

    &__annotation {
        font-size: $font-size-tiny;
        color: $color-text-light;
    }
}

.#{$ns}field {
    $c: &;

    position: relative;
    margin-top: $unit-size-base;
    font-weight: $font-weight-normal;

    @include clearfix;

    &__brands-container {
        z-index: $z-front;
        position: absolute;
        top: 0;
        right: 0;
        padding: 0 12px;
        display: flex;
        height: 100%;
    }

    &__brand {
        opacity: 0.5;

        &.is-active {
            opacity: 1;
        }
    }

    &--disabled > * {
        opacity: 0.33;
        pointer-events: none;
    }

    &--columns-layout {
        margin-top: $unit-size-small * 3;
    }

    &--compact {
        .#{$ns}field__input {
            padding: 0 $unit-size-small;
        }

        .#{$ns}field-select select {
            padding-left: $unit-size-small;
        }
    }

    &--inline {
        margin-top: 0;
        display: inline-block;

        &.has-error {
            margin-bottom: 0;
        }
    }

    &--unit {
        width: $unit-size-small * 17;
    }

    &--good {
        color: $color-message-success;
    }

    &--bad {
        color: $color-message-error;
    }

    &.is-highlighted, &.is-dehighlighted {
        margin-left: - $unit-size-small * 2;
        margin-right: - $unit-size-base;
        padding: $unit-size-base $unit-size-small;

        @include breakpoint('l') {
            margin-left: - $unit-size-small * 5;
            margin-right: - $unit-size-small * 5;
            padding: $unit-size-base $unit-size-large;
        }
    }

    &.is-highlighted {
        @include hightlighted;
    }

    &.is-dehighlighted {
        @include dehighlighted;
    }

    &__description {
        display: block;
        font-size: $font-size-micro;
        color: $color-text-light;
    }

    &--validator-layout-tooltip {
        #{$c}__input {
            padding-right: $unit-size-small * 4;
        }
    }

    &__textarea,
    &__input {
        width: 100%;
        border: solid 1px $color-input-border;
        border-radius: $input-field-border-radius;
        line-height: $line-height-base;
        height: 7*$unit-size-small;
        padding: 0 $unit-size-base;
        background: $color-input-base;
        outline: none;
        color: $color-text-base;
        caret-color: $color-accent-base;

        @include breakpoint('l') {
            height: 7*$unit-size-small;
        }

        &.#{$ns}field__input--faux-expanded {
            min-height: $unit-size-small*7;
        }

        &[type="number"] {
            font-size: $font-size-base;
            appearance: textfield;
        }

        &:-webkit-autofill {
            box-shadow: inset 0 0 0 1000px $color-input-base;
        }

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
            appearance: none;
            margin: 0;
        }

        &textarea {
            height: auto;
        }

        &--conceal {
            font-size: $font-size-big;
            font-family: $font-family-text-security-disc;
        }

        &--small {
            height: $unit-size-small*5;
            padding: 0 $unit-size-small;

            @include breakpoint('l') {
                padding: 0 $unit-size-base;
            }

            &.#{$ns}field__input--faux, &.#{$ns}field__input--faux-expanded {
                line-height: $unit-size-small*5;
                min-height: $unit-size-small*5;
            }
        }

        &--soft {
            border-radius: $unit-size-large;
        }

        &--start {
            border-radius: 0;
            border-top-left-radius: $input-field-border-radius;
            border-bottom-left-radius: $input-field-border-radius;
        }

        &--end {
            border-radius: 0;
            border-top-right-radius: $input-field-border-radius;
            border-bottom-right-radius: $input-field-border-radius;
        }

        &--social {
            background-color: $color-background-dark;
            border-color: $color-background-dark;
            color: $color-white;
        }

        &--readonly {
            border-color: $color-input-border;
            background: $color-background-light;
        }

        &--text-only {
            border-color: transparent;
            background: transparent;
        }

        &--faux {
            display: inline-block;
            line-height: 7*$unit-size-small;
        }

        &--faux-expanded {
            display: inline-block;
            line-height: 7*$unit-size-small;
            height: auto;
        }

        &--no-fill {
            width: auto;
        }

        &--text-left {
            text-align: left;
        }

        &--text-center {
            text-align: center;
        }

        &--text-right {
            text-align: right;
        }

        .#{$ns}field.has-error & {
            border-color: $color-error;
        }
    }

    &__holder {
        position: relative;
        display: inherit;
        width: 100%;
    }

    &__readonly {
        overflow-wrap: break-word;

        @include wordbreak;

        display: block;
    }

    &__textarea {
        height: auto;
        line-height: inherit;
        padding: $unit-size-base;
        max-height: $unit-size-small * 67;
        resize: vertical;

        &--no-resize {
            resize: none;
        }

        &--rows-1 {
            min-height: $unit-size-small * 5;
        }

        &--rows-2 {
            min-height: $unit-size-small * 10;
        }

        &--rows-3 {
            min-height: $unit-size-small * 15;
        }

        &--rows-4 {
            min-height: $unit-size-small * 20;
        }

        &--rows-5 {
            min-height: $unit-size-small * 25;
        }

        &--rows-6 {
            min-height: $unit-size-small * 30;
        }
    }

    &__label {
        display: block;
        font-size: $font-size-base;
        font-weight: $font-weight-extrabold;
        font-family: $font-family-base;
        color: $color-text-base;

        &--big {
            font-size: $font-size-base;
            line-height: $line-height-big;
            margin-bottom: 0;
        }

        &--emphasized label {
            font-size: $font-size-plus;
            margin-bottom: 0;
        }

        &--columns-layout {
            font-size: $font-size-base;
        }

        &--gray {
            color: $color-text-gray;
        }

        &--light {
            font-weight: $font-weight-normal;
        }
    }

    &__label-wrapper {
        &--inline {
            display: inline-block;
        }
    }

    &__value {
        display: block;
        font-size: $font-size-tiny;
        font-family: $font-family-base;
        text-align: center;
        color: $color-text-gray;
        line-height: $line-height-big;

        &--big {
            font-size: $font-size-base;
        }
    }

    &__error {
        display: none;
        font-size: $font-size-tiny;
        font-weight: $font-weight-extrabold;
        line-height: $line-height-base;
        margin: $unit-size-small 0;

        @include wordbreak;

        .#{$ns}field.has-error & {
            display: inline-block;
            color: $color-message-error;
        }

        &--tooltip {
            position: absolute;
            top: 50%;
            right: $unit-size-small;
            margin: 0;
            transform: translateY(-50%);
        }
    }

    &__prepend, &__append {
        border: solid 1px $color-input-border;
        background: $color-input-base;
        line-height: $unit-size-small * 7;
        height: $unit-size-small * 7;
        padding: 0 $unit-size-base;
        color: $color-text-base;

        #{$c}.has-error & {
            border-color: $color-error;
        }

        &--small {
            height: $unit-size-small * 5;
            line-height: $unit-size-small * 5;
        }

        &--currency {
            position: absolute;
            right: 0;

            #{$c}.has-error & {
                border-left-color: $color-input-border;
            }
        }

        &-wrapper {
            display: flex;
            flex-flow: row wrap;

            .#{$ns}field__label-wrapper, .#{$ns}field__error {
                width: 100%;
            }

            #{$c}__error--tooltip {
                width: auto;
            }

            .#{$ns}field__input, .#{$ns}field-select {
                width: 1%; //It won't grow without some value defined
                flex-grow: 1;
            }
        }
    }

    &__prepend {
        border-top-left-radius: $input-field-border-radius;
        border-bottom-left-radius: $input-field-border-radius;

        &-wrapper {
            .#{$ns}field__input {
                border-left: unset;
                border-top-left-radius: unset;
                border-bottom-left-radius: unset;
            }
        }
    }

    &__append {
        margin-left: - $unit-size-small * 0.5;
        border-top-right-radius: $input-field-border-radius;
        border-bottom-right-radius: $input-field-border-radius;
        width: $unit-size-small * 7;
        display: flex;
        justify-content: center;
        align-items: center;

        &-wrapper {
            .#{$ns}field__input {
                border-right: unset;
                border-top-right-radius: unset;
                border-bottom-right-radius: unset;
            }

            &--currency {
                .#{$ns}field__input {
                    border-top-right-radius: $input-field-border-radius;
                    border-bottom-right-radius: $input-field-border-radius;
                    padding-right: $unit-size-large * 2;
                }
            }
        }
    }
}

/***
  * Component / Image Field
  */

.#{$ns}field-image {
    display: flex;
    border-radius: $input-field-border-radius;
    border: solid 1px transparent;

    .#{$ns}field.has-error & {
        border-color: $color-error;
    }

    &__img-wrapper {
        position: relative;

        &:hover .#{$ns}field-image__actions {
            display: block;
        }
    }

    &__input {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        pointer-events: none;

        &-title {
            display: block;
            padding-top: $unit-size-base;
            font-weight: $font-weight-bold;
            color: $color-text-dark;

            &--disabled {
                color: $color-text-gray;
            }
        }

        &-description {
            display: block;
            font-size: $font-size-tiny;
            color: $color-text-gray;
        }
    }

    &__actions {
        position: absolute;
        top: $unit-size-base;
        right: $unit-size-base;

        @include breakpoint(xl) {
            display: none;
        }
    }

    &__image {
        min-width: $unit-size-large * 4;
        min-height: $unit-size-base * 13;
        height: $unit-size-base * 13;
        border-radius: $common-border-radius;
        object-fit: contain;

        &--expanded {
            height: auto;
        }
    }
}

/***
  * Component / Document field
  */

.#{$ns}field-document {
    position: relative;
    border-radius: $input-field-border-radius;
    border: solid 1px $color-input-border;
    padding: $unit-size-small * 2;
    overflow: hidden;
    line-height: $line-height-small;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-white;
        width: $unit-size-small * 6;
        height: $unit-size-small * 6;
        border-radius: 50%;
    }

    &__primary {
        background-color: $color-primary-base;
    }

    &__input {
        position: absolute;
        top: 0;
        right: 0;
        opacity: 0;
        width: 0;
        height: 0;
    }

    &.is-dragover {
        background-color: $color-background-light;
    }

    &.is-readonly {
        border-color: $color-border-light;
        background: $color-background-light;
    }
}

/***
  * Component / Toggle Field
  */

.#{$ns}field-toggle {
    position: relative;
    display: inline-block;

    &__input {
        display: none;
    }

    &__label {
        display: flex;
        padding: ($unit-size-small*0.5) 0;
        cursor: pointer;
    }

    &__icon::after {
        font-family: $font-family-icons;
        display: block;
        content: '\f00c';
        color: transparent;
        width: $unit-size-small * 3;
        height: $unit-size-small * 3;
        line-height: $unit-size-small * 3;
        border-radius: $input-field-border-radius;
        background-color: $color-input-base;
        text-align: center;
        border: solid 1px $color-input-border;
        flex-shrink: 0;

        .#{$ns}field.has-error & {
            border-color: $color-error;
        }
    }

    &__icon--indeterminate::after {
        font-family: $font-family-icons;
        content: '\f86c'; // horizontal rule
        color: $color-accent-base;
    }

    &--readonly {
        cursor: auto;
    }

    &--readonly &__icon::after {
        border-color: $color-border-light;
        background: $color-background-light;
    }

    &__label-text {
        margin-left: $unit-size-small * 2;
        flex-grow: 1;
    }

    &__input:checked + &__label &__icon::after {
        color: $color-accent-base;
    }

    &__input:checked + &--readonly &__icon::after {
        color: $color-text-base;
    }

    &--radio &__icon {
        position: relative;
        display: block;
        color: currentcolor;
        width: $unit-size-small * 3;
        height: $unit-size-small * 3;
        line-height: $unit-size-small * 3;
        border-radius: 50%;
        background-color: $color-input-base;
        text-align: center;
        border: solid 1px $color-input-border;
        flex-shrink: 0;
    }

    &--radio &__icon::after {
        position: absolute;
        display: none;
        left: $unit-size-small * 0.5;
        top: $unit-size-small * 0.5 * 3;
        content: '';
        width: $unit-size-small + math.div($unit-size-small, 3) * 2;
        height: $unit-size-small + math.div($unit-size-small, 3) * 2;
        line-height: $unit-size-small * 3;
        margin-top: -$unit-size-small;
        border-radius: 50%;
        background-color: $color-accent-base;
        text-align: center;
    }

    &--radio &__input:checked + &__label &__icon::after {
        display: block;
    }

    &--switch {
        .#{$ns}field-toggle {
            &__label {
                padding-left: $unit-size-base*3;

                &::before {
                    display: none;
                }
            }
        }
    }

    &--align-vertical-center &__label {
        align-items: center;
    }

    &--block {
        display: block;
    }
}

/***
  * Component / Select Field
  */

.#{$ns}field-select {
    position: relative;
    line-height: $line-height-base;

    > select {
        &:-moz-focusring {
            color: transparent;
            text-shadow: 0 0 0 $color-text-base;
        }
    }

    > select, &__trigger {
        width: 100%;
        border: solid 1px $color-input-border;
        border-radius: $input-field-border-radius;
        padding: 0 ($unit-size-base * 3) 0 $unit-size-base;
        cursor: pointer;
        appearance: none;
        outline: none;
        background-color: $color-white;
        font-family: $font-family-base;
        font-size: $font-size-base;
        color: $color-text-base;
        height: 7*$unit-size-small;
        line-height: $line-height-base;

        &:active,
        &:focus {
            outline: none;
        }

        &:disabled, &[disabled] {
            &:hover {
                cursor: default;
            }
        }

        &::-ms-expand {
            display: none;
        }

        .#{$ns}field.has-error & {
            border-color: $color-error;
        }

        > option, > optgroup > option {
            background-color: $color-white;
            color: $color-text-base;

            &:active,
            &:focus {
                outline: none;
            }

            &:disabled, &[disabled] {
                background-color: $color-primary-disabled;
            }
        }
    }

    &--transparent > select {
        background-color: transparent;
        color: $color-white;
    }

    &__trigger {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        line-height: 7*$unit-size-small;

        &--active, &--active input {
            border-bottom: none;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
        }

        &:not([disabled]):active,
        &:not([disabled]):focus {
            @include field-focus;
        }
    }

    &__content {
        background-color: $color-white;
        position: absolute;
        width: 100%;
        border: solid 1px $color-input-border;
        border-bottom-left-radius: $input-field-border-radius;
        border-bottom-right-radius: $input-field-border-radius;
        border-top: none;
        z-index: -1;
        opacity: 0;
        pointer-events: none;
        transition: $transition-opacity;
        box-shadow: $box-shadow-tight;
        height: 0;
        overflow: hidden;

        &--expanded {
            z-index: 1;
            opacity: 1;
            pointer-events: all;
            height: fit-content;
        }

        &:active,
        &:focus {
            outline: none;
        }

        .#{$ns}field.has-error & {
            border-color: $color-error;
        }
    }

    &__content-wrapper {
        padding: 0 $unit-size-base;
        max-height: $unit-size-base*16;
        overflow: auto;
    }

    &__group-header {
        padding: ($unit-size-base * 0.5) 0;
        color: $color-text-lighter;
        text-transform: uppercase;
        letter-spacing: math.div($unit-size-small, 3);
        font-size: $font-size-micro;
    }

    &::after {
        position: absolute;
        top: 0;
        right: $unit-size-base;
        font-family: $font-family-icons;
        font-weight: 300 !important;
        font-size: $font-size-base;
        content: '\f078';
        color: $color-accent-base;
        cursor: pointer;
        pointer-events: none;
        line-height: $unit-size-small * 7;
    }

    &.has-error {
        margin-bottom: $unit-size-base;

        > select, .#{$ns}field-select__trigger, .#{$ns}field-select__content {
            border-color: $color-error;
        }
    }

    &--soft {
        > select, .#{$ns}field-select__trigger {
            border-radius: $unit-size-large;
        }
    }

    &--strong {
        > select, .#{$ns}field-select__trigger {
            font-weight: $font-weight-bold;
        }
    }

    &--dark {
        > select, .#{$ns}field-select__trigger {
            background-color: $color-primary-dark;
            color: $color-white;

            > option {
                background-color: $color-primary-dark;
                color: $color-white;
            }
        }
    }

    &--solid {
        > select, .#{$ns}field-select__trigger {
            border-width: 2px;
        }
    }

    &--tiny-text {
        > select, .#{$ns}field-select__trigger {
            font-size: $font-size-tiny;
        }
    }

    &--inline {
        line-height: inherit;

        &::after {
            line-height: 7*$unit-size-small;
        }
    }

    &--small {
        > select {
            height: 5*$unit-size-small;
            padding-right: $unit-size-small * 5;
        }

        .#{$ns}field-select__trigger {
            height: 5*$unit-size-small;
            line-height: 5*$unit-size-small;
        }

        &::after {
            line-height: $unit-size-small * 5;
        }
    }

    &--no-fx {
        > select, > select > optgroup, .#{$ns}field-select__trigger {
            &, > option {
                text-transform: none;
            }
        }
    }

    &--fixed {
        line-height: 7*$unit-size-small;

        &::after {
            line-height: 7*$unit-size-small;
        }
    }
}

/***
  * Component / Switch Field
  */

.#{$ns}field-switch {
    $c: &;

    display: inline-block;
    position: relative;

    &:focus, &:active {
        outline: none;
    }

    &__input {
        display: none;
    }

    &--small {
        height: $unit-size-small * 5;

        & #{$c}__label {
            padding-top: $unit-size-small - math.div($unit-size-small, 3);
        }
    }

    &__label {
        cursor: pointer;
        position: relative;
        font-weight: normal;
        display: inline-block;
        padding: 0 0 0 $unit-size-base*3;

        &--readonly {
            cursor: default;

            /* padding-left: 0; */
        }

        &--left {
            padding: 0 $unit-size-base*3 0 0;
        }
    }

    &__slider {
        position: absolute;
        cursor: pointer;
        top: 50%;
        left: 0;
        transition: 0.2s;
        height: $unit-size-small * 3;
        width: $unit-size-small * 5;
        border: solid 1px $color-primary-base;
        border-radius: $unit-size-base;
        margin-top: -$unit-size-base + 1px;
        background: $color-white;
        background-color: $color-white;

        &--readonly {
            cursor: default !important;
            border-color: $color-primary-disabled !important;

            &::before {
                background-color: $color-primary-disabled !important;
            }
        }

        &--left {
            left: auto;
            right: 0;
        }

        &::before {
            position: absolute;
            content: "";
            height: $unit-size-base;
            width: $unit-size-base;
            left: math.div($unit-size-small, 3);
            top: math.div($unit-size-small, 3);
            transition: 0.2s;
            border-radius: 50%;
            background-color: $color-primary-base;
        }
    }

    &__input:checked + &__slider {
        border-color: $color-accent-base;
    }

    &__input:checked + &__slider::before {
        transform: translateX($unit-size-base);
        background-color: $color-accent-base;
    }
}

/***
  * Component / Drop area
  */

.#{$ns}field__drop-area {
    $c: &;

    height: 100%;
    width: 100%;
    border: 1px solid $color-input-border;
    text-align: center;
    padding: ($unit-size-base * 3) $unit-size-base;
    border-radius: $common-border-radius;

    &:hover {
        &:not(#{$c}--disabled) {
            cursor: pointer;
            background: $color-background-light;
        }
    }

    &--disabled {
        &:focus {
            outline: none;
        }
    }

    &--active {
        background: $color-background-light;

        > * {
            pointer-events: none;
        }
    }

    &--preview {
        padding: 0;
        border: none;

        > * {
            pointer-events: all;
        }

        &:hover {
            cursor: default;
            background: unset;
        }
    }

    &--active#{&}--preview {
        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 90px;
            height: 90px;
            line-height: 90px;
            text-align: center;
            margin: -45px 0 0 -45px;
            animation: fa-spin 2s infinite;
            font-family: $font-family-icons;
            text-rendering: auto;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\f021';
            background-color: $color-primary-base;
            font-size: $font-size-gigantic;
            color: $color-white;
            border-radius: $unit-size-large * 2;
            z-index: $z-base + 1;
            pointer-events: none;
        }

        &::before {
            content: "";
            position: absolute;
            inset: 0;
            background: rgb(255 255 255 / 0.8);
            pointer-events: none;
            z-index: $z-base;
        }
    }
}

.#{$ns}richtext, .ql-container {
    @include wordbreak;

    &, b, i, span {
        font-family: $font-family-base !important;
        color: $color-text-base !important;
    }

    br {
        display: inline-block;
        line-height: 1.5em;
    }

    iframe, img {
        border: none;
        display: block;
        margin: 1.5em auto;
    }

    .#{$ns}embed {
        margin: 1.5em auto;

        img {
            margin: auto;
        }
    }

    ul, ol {
        margin: $unit-size-small 0;
        list-style: none !important;

        li {
            position: relative;
            padding-left: $unit-size-small*4;
            margin-bottom: $unit-size-small;

            &::before {
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }

    ul {
        li::before {
            content: '\2022';
            margin-left: $unit-size-small;
            font-size: $font-size-small;
            line-height: $unit-size-small * 4;
        }
    }

    @for $i from 1 through 25 {
        ol[start="#{$i}"] {
            counter-reset: list-counter ($i - 1);
        }
    }

    ol {
        counter-reset: list-counter;

        li {
            counter-increment: list-counter;

            &::before {
                content: counter(list-counter) ". ";
                font-size: $font-size-base;
            }
        }
    }

    &--article {
        font-size: $font-size-plus;
        line-height: 1.5;

        span[style*="font"] {
            display: inline-block;
            line-height: 1.5em;
        }

        > * {
            overflow: hidden;
        }
    }

    a[href], a[href] *:not(img) {
        font-family: inherit;
        color: $color-accent-base !important;
        text-decoration: none;

        &:hover {
            opacity: 1;
            color: $color-accent-base;
            text-decoration: underline;
        }
    }

    [data-header*="header"] {
        &::before {
            display: inline-block;
            font-family: $font-family-icons;
            content: '\f6e2';
            padding-right: $unit-size-small;
        }

        // This is copy pasta. Don't @ me becuase important.
        color: $color-text-light !important;
        cursor: pointer;
        font-family: inherit !important;
        border-bottom: 1px dashed $color-text-light !important;
        text-decoration: none;
        background-color: transparent !important;
    }

    [data-video] {
        position: relative;
        display: block;
        padding-top: 56.25%;

        iframe {
            @include embed-fill;

            margin: 0;
        }
    }

    [data-video-clip] {
        position: relative;
        display: block;

        video {
            border: none;
            display: inline-block;
            margin: 1.5em auto;
            max-width: 100%;
        }
    }
}

.#{$ns}richtext-soft-images {
    img {
        border-radius: $common-border-radius;
        margin: $unit-size-small*5 auto;
        padding: 0;
    }
}

.#{$ns}field-richtext {
    position: relative;

    &__toolbar {
        &--disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &__editor {
        position: relative;
        min-height: $unit-size-small * 67;
        max-height: $unit-size-small * 67;
        border: $color-input-border 1px solid;
        border-radius: $input-field-border-radius;
        background: $color-white;

        &.expanded {
            min-height: 90vh;
        }

        &--compact {
            overflow: hidden !important;
            min-height: $unit-size-small * 10;
            resize: vertical;
            font-size: $font-size-base !important;
            line-height: 1.4 !important;
            padding: ($unit-size-small * 0.5) $unit-size-base !important;

            &.#{$ns}field-richtext__editor--read-only {
                border: none;
                height: auto !important;
                font-size: inherit !important;
                line-height: inherit !important;
                padding: 0 !important;
            }

            input.input {
                padding: 0 !important;
            }

            .ql-tooltip {
                padding: 0 $unit-size-small;
                margin: 0 $unit-size-base;
                font-size: $font-size-small;

                a[href] {
                    line-height: $line-height-base;
                }
            }
        }

        &--small {
            min-height: $unit-size-small * 30;
            resize: vertical;
        }

        &--medium {
            min-height: $unit-size-small * 53;
            max-height: $unit-size-small * 53;
        }

        &--tiny {
            //4 lines of text + paddings + borders
            $height: ($font-size-small * $line-height-base * 4) + ($unit-size-base * 2) + 2;

            min-height: $height;
            max-height: $height;
        }

        &--adaptive-height {
            resize: vertical;
            max-height: $unit-size-small * 100;
        }

        .ql-upload-status-error {
            background-color: $color-error;
            padding: $unit-size-small $unit-size-small*2;
            display: none;
            margin: -(($unit-size-small*4)+2) $unit-size-small*-2 math.div($unit-size-small, -2) $unit-size-small*-2;
            position: relative;
            z-index: 1;

            span {
                color: $color-white !important;
            }
        }

        .ql-upload-status-container-close {
            cursor: pointer;
        }

        .ql-attachment {
            a::before {
                font-family: $font-family-icons;
                content: '\f15b';
                margin: 0 $unit-size-small;
            }
        }

        &.ql-container {
            height: $unit-size-small; //Any value
            padding: $unit-size-base;
            caret-color: $color-accent-base;
        }

        .#{$ns}field.has-error & {
            box-shadow: inset 0 0 0 1000px $color-input-base, 0 0 2px 1px $color-message-error;
        }

        &:not([disabled]):focus-within {
            @include field-focus;
        }
    }

    &__charlimit {
        position: absolute;
        bottom: 0;
        right: $unit-size-small;
        color: $color-text-light;
    }

    .ql-editor {
        position: relative;
    }

    .ql-editor::before {
        user-select: none;
        pointer-events: none;
        content: attr(data-placeholder);
        display: inline;
        position: absolute;
        top: 0;
        left: 0;
        color: $color-primary-disabled-dark;
    }
}

/***
  * Component / Auto-complete field
  */
.#{$ns}field-auto-complete {
    &__container {
        width: 100%;
        position: relative;
    }
    
    &__chevron::after {
        position: absolute;
        top: 0;
        right: $unit-size-base;
        font-family: $font-family-icons;
        font-weight: 300 !important;
        font-size: $font-size-base;
        content: '\f078';
        color: $color-accent-base;
        cursor: pointer;
        pointer-events: none;
        line-height: $unit-size-small * 5;
    }

    &__clear {
        position: absolute;
        top: 0;
        right: 0;
        color: $color-accent-base;
        margin: math.div($unit-size-small, 3);
        cursor: pointer;

        &--small {
            padding: $unit-size-small;
            margin-right: math.div($unit-size-small, 3) * 4;
        }
    }

    &__suggestions {
        $c: &;

        background-color: $color-background-lighter;
        padding: 0;
        z-index: $z-admin+1;
        left: 0;
        right: 0;

        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;

            li {
                padding: $unit-size-small $unit-size-base;
                cursor: pointer;
                width: 100%;

                &:hover, &:focus {
                    background-color: $color-background-light;

                    .#{$ns}tags__item {
                        background-color: $color-white;
                    }
                }

                &:focus {
                    outline: none;
                }
            }
        }

        li#{$c}-header {
            cursor: default;

            &:hover, &:focus {
                background-color: unset;
            }
        }
    }
}

/***
  * Component / Fieldset
  */

.#{$ns}fieldset {
    border: 1px solid $color-input-border;
    border-radius: $common-border-radius;
    padding: $unit-size-small * 3;
}

/***
  * Component / Progress field
  */

.#{$ns}progress-field {
    margin-top: $unit-size-small * 5;
}

.#{$ns}progress-field-content {
    display: flex;
    justify-content: space-between;

    &__title {
        font-size: $font-size-tiny; 
    }

    &__current-value, &__max-value {
        margin: $unit-size-small 0 $unit-size-small 0;
        font-size: $font-size-tiny;
    }

    &__percentage {
        display: flex;
        align-items: center;
        justify-content: center;
        color: $color-text-dark;
        font-size: $font-size-base;

        @include breakpoint(xl) {
            font-size: $font-size-plus;
        }
    }
}

/***
  * Component / Clickeditor
  */
.#{$ns}clickeditor {
    display: inline-block;
    width: 100%;

    @include wordbreak;

    &.is-enabled {
        .#{$ns}clickeditor__value {
            &:hover {
                outline: 2px dashed $color-accent-base;
                outline-offset: math.div($unit-size-small, 3);
                cursor: pointer;
            }
        }
    }

    &__wrapper {
        display: inline-block;
    }

    &__field {
        font-weight: inherit;
        font-size: inherit;
        width: 100%;

        input {
            color: inherit;
            height: unset;
            padding: 0;
        }
    }
}

/***
  * Component / Preview Field
  */

.#{$ns}preview-field {
    padding: $unit-size-small;
    background-color: $color-background-light;
    border-radius: $input-field-border-radius;
    color: $color-text-base;
}

.#{$ns}text-field-suggestions {
    margin: 0;
    list-style-type: none;
    border: solid 1px $color-input-border;
    border-bottom-left-radius: $input-field-border-radius;
    border-bottom-right-radius: $input-field-border-radius;
    max-height: 128px;
    overflow: auto;
    cursor: pointer;
    outline: none;

    &__item {
        &:hover, &.active {
            background-color: $color-background-light;
        }
    }
}