@import 'variables';
@import 'mixins';

/***
  * Component / Islands
  */

$isle-radius: 2px;

.#{$ns}isle {
    display: block;
    position: relative;
    border-radius: $isle-radius;
    background-color: $color-white;
    width: 100%;
    pointer-events: all;
    padding: 2*$unit-size-large $layout-padding;

    @include breakpoint('m') {
        padding: 2*$unit-size-large;
    }

    @include breakpoint('l') {
        padding: 2*$unit-size-large;
    }

    @include breakpoint('xl') {
        padding: 3*$unit-size-large;
    }

    &__title {
        font-family: $font-family-decorative;
        font-size: $font-size-huge;
        color: $color-text-dark;
    }

    &__description {
        font-family: $font-family-soft;
        font-size: $font-size-base;
        line-height: 1.6;
        color: $color-text-gray;
        margin: 0 auto;
    }

    &--small {
        padding: $unit-size-small;
    }

    &--primary-light {
        background-color: $color-background-light;
    }

    &--primary {
        background-color: $color-primary-dark;
        color: $color-text-accent;
    }

    &--primary-dark {
        background-color: $color-primary-dark;
        color: $color-text-accent;
    }

    &--bordered {
        border-radius: $common-border-radius;
        border: 1px solid $color-input-border;
    }
}
