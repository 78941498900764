@import 'variables';
@import 'mixins';
@import "mixins.product-cards";

/***
  * Component / Product Cards
  */

$card-space: $unit-size-small*3;

.#{$ns}product-card {
    @include card-title;
    @include card-body;
    @include card-tags;
    @include card-edit;
    @include card-desc;

    padding: 0;

    &__desc-fade {
        position: absolute;
        height: $unit-size-small * 5;
        width: 100%;
        left: 0;
        bottom: 0;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #FFFFFF 100%);
    }

    &__body {
        display: flex;
        flex-direction: column;
        height: $unit-size-base * 12;

        @include breakpoint('m') {
            height: $unit-size-base * 14;
        }

        @include breakpoint('l') {
            height: $unit-size-base * 16;
        }

        &:hover {
            text-decoration: none;
        }
    }

    /**
    * This is quick solution should be improved.
    */

    &__distinction {
        display: flex;
        flex-direction: column;
        padding: $card-space;

        &:hover {
            text-decoration: none;
        }
    }

    &__distinction-title {
        height: $unit-size-small * 8;
        margin-bottom: $unit-size-small * 3;
    }
}
