@import 'variables';
@import 'mixins';

/***
  * Component / Tabs
  */

.#{$ns}tabs {
    position: relative;
    display: flex;
}

.#{$ns}tab {
    display: flex;
    position: relative;
    align-content: center;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    
    &--button {
        background-color: $color-background-base;
        border-radius: $container-border-radius;
        padding: $unit-size-small * 2 $unit-size-small * 4;
        margin-right: $unit-size-small * 2;
        border: none;
        font-weight: bold;
        color: $color-text-gray;
        cursor: pointer;
    }
    
    &--button.is-active {
        background-color: $color-white;
        color: $color-text-base;
        box-shadow: $box-shadow-base;
    }
    
    &--link {
        font-weight: bold;
        padding: $unit-size-base $unit-size-half;
        border-bottom: $unit-size-half solid transparent;
        color: $color-primary-disabled;
    }

    &--link-secondary {
        font-weight: bold;
        padding: $unit-size-base $unit-size-half;
        border-top: $unit-size-half solid transparent;
        color: $color-text-gray;
    }

    &--link[title], &--link-secondary[title] {
        color: transparent;
        
        &::after {
            display: flex;
            content: attr(title);
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            justify-content: center;
            align-items: center;
            color: $color-primary-disabled;
            font-weight: normal;
            user-select: none;
        }
    }
    
    &--link:hover, &--link-secondary:hover {
        text-decoration: none;    
    }

    &--link.is-active, &--link-secondary.is-active {
        color: $color-primary-base;
        border-color: currentColor;
        
        &::after {
            display: none;
        }
    }
}
