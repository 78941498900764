@import 'variables';
@import 'mixins';

.#{$ns}flip-card {
    position: relative;
    display: flex;
    height: 100%;
    perspective: 1000px;
    flex-direction: column;
    will-change: auto;
    transform: translate3d(0, 0, 0);

    &.is-out-of-screen {
        visibility: hidden;
    }

    &.is-out-of-screen &__inner {
        opacity: 0;
        perspective: 0;
    }
    
    &__inner {
        opacity: 1;
        display: grid;
        height: 100%;
        will-change: auto;
        transform-style: preserve-3d;
        transition: transform 0.35s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        transform: translate3d(0, 0, 0);

        &.is-flipped {
            transform: rotateY(180deg);
        }
    }

    &__side {
        display: grid;
        grid-area: 1/1;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        will-change: auto;

        &--reverse {
            transform: rotateY(180deg);
        }

        &.scrollable {
            height: 0;
            min-height: 100%;
            overflow: auto;
        }
    }
}
