@import 'variables';
@import 'mixins';

/***
  * Module / Project Slider
  */

.#{$ns}project-slider {
    user-select: none;
    box-shadow: $box-shadow-base;

    &__slide {
        overflow: hidden;
        opacity: 0;
        transition: opacity $transition-timing-default ease-in;
        background-size: cover;
        background-repeat: no-repeat;
        background-color: $color-white;
        border-radius: $common-border-radius;
        z-index: 0;

        &.is-active {
            opacity: 1;
            z-index: 1;
        }

        @include breakpoint(m) {
            border-radius: $common-border-radius;
        }
    }
}

.#{$ns}project-slider-nav {
    overflow: hidden;
    position: absolute;
    top: 50%;
    z-index: 2;

    &:hover {
        color: $color-text-accent-2;
        text-decoration: none;
    }

    &--next {
        right: 0;
        transform: translate(-25%, -50%);

        @include breakpoint('m') {
            transform: translate(50%, -50%);
        }
    }

    &--prev {
        left: 0;
        transform: translate(25%, -50%);

        @include breakpoint('m') {
            transform: translate(-50%, -50%);
        }
    }
}

.#{$ns}project-slider-indicators {
    display: flex;
    position: absolute;
    bottom: -$unit-size-small * 4;
    left: 50%;
    transform: translate(-50%, -100%);
    z-index: 2;

    &__indicator {
        width: $unit-size-large;
        height: $unit-size-small * 0.5;
        background-color: $color-background-lighter;
        border-radius: $unit-size-small * 0.5;
        margin: 0 $unit-size-small * 0.5;
        transition: all 0.5s ease;

        &.is-active {
            background-color: $color-accent-base;
        }
    }
}

.#{$ns}project-slider-indicators-enter {
    opacity: 0;
    transform: scale(0);
}

.#{$ns}project-slider-indicators-leave-to {
    opacity: 0;
    transform: scale(0);
}

.#{$ns}project-slider-indicators-leave-active {
    position: absolute;
}
