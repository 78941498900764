@import '_variables';
@import 'mixins';

.#{$ns}summary-list-wrapper {
    position: relative;
    font-size: $font-size-base;
    line-height: $line-height-base;
    background-color: $table-main-color;
    border-radius: $common-border-radius;
    padding: $layout-padding-s;
    display: flex;
    flex-direction: column;
    gap: $unit-size-base;

    &__divider {
        border-top: 2px solid $color-input-border;
        margin: $unit-size-small 0;
    }

    &--small {
        padding: $layout-padding;
        font-size: $font-size-tiny;
        gap: $unit-size-base * 0.5;
    }

    &--large {
        gap: $unit-size-small * 3;
        padding: $unit-size-small * 2 $unit-size-small * 3;
    }
}

.#{$ns}summary-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__value {
        color: $color-text-base;

        &--highlighted {
            font-size: $font-size-big;
            font-weight: $font-weight-extrabold;
        }
    }

    &__subtitle {
        font-size: $font-size-tiny;
    }
}
