@import 'variables';
@import 'mixins';

.#{$ns}testimonial-slider {
    position: relative;
    height: $unit-size-base * 46;
    margin-top: $unit-size-large * 2;
    margin-bottom: $unit-size-base * 3;
    user-select: none;

    &::after {
        position: absolute;
        top: -($unit-size-small * 15 * 0.5);
        left: calc(50% - 23px);
        content: "”";
        font-size: $unit-size-large * 5;
        color: $color-accent-base;
        z-index: 2;

        @include breakpoint('m') {
            left: calc(40% - 8px);
        }
    }

    @include breakpoint('m') {
        width: 80%;
        margin-left: 10%;
        margin-top: $unit-size-large;
        height: $unit-size-small * 49;
        margin-bottom: $unit-size-base * 3;
    }

    @include breakpoint('l') {
        width: 90%;
        margin-left: 5%;
        height: $unit-size-small * 53;
        margin-bottom: $unit-size-base * 5;
    }

    &--wide {
        height: $unit-size-base * 35;

        @include breakpoint('m') {
            width: 100%;
            height: $unit-size-small * 40;
            margin-left: 0;
        }

        @include breakpoint('l') {
            width: 100%;
            height: $unit-size-small * 42;
            margin-left: 0;
        }
    }
}

.#{$ns}testimonial-slider-nav {
    position: absolute;
    display: none;
    top: 50%;
    transform: translateY(-50%);
    width: $unit-size-base * 3;
    height: $unit-size-base * 3;
    line-height: $unit-size-base * 3;
    color: $color-accent-base;
    font-size: $font-size-immense;
    font-family: $font-family-icons;
    text-align: center;
    cursor: pointer;

    @include breakpoint('m') {
        display: block;
    }

    &--next {
        right: -$unit-size-base * 6;

        &::before {
            content: '\f105';
        }
    }

    &--prev {
        left: -$unit-size-base * 6;

        &::before {
            content: '\f104';
        }
    }

    &--desktop {
        display: none;

        @include breakpoint('m') {
            display: block;
        }
    }
}
