@import 'variables';
@import 'mixins';

.#{$ns}project-test-mode-bar {
    @include layout('full');
    background-color: $color-primary-base;
    padding-top: $unit-size-base;
    padding-bottom: $unit-size-base;
    display: flex;
    justify-content: space-between;
    align-items: center;
}