@import 'variables';
@import 'mixins';

/***
  * Helpers / Fonts
  */

._fa {
    &--base { // This should be base but can cause problem in few places so has mod
        -webkit-font-smoothing: antialiased;
        display: inline-block;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
    }

    &--fw {
        text-align: center;
        width: 1.25em;
    }

    &--lighter::after {
        color: $color-text-lighter;
    }
    
    &--inverse::after {
        color: $color-text-inverse;
    }

    &--fw-eb::after {
        font-weight: $font-weight-extrabold;
    }

    &--tac::after {
        text-align: center;
    }

    &--xs {
        font-size: 0.75em;
    }

    &--sm {
        font-size: 0.875em;
    }

    &--lg {
        font-size: 1.33em;
    }

    &--2x {
        font-size: 2em;
    }

    &--3x {
        font-size: 3em;
    }

    &--4x {
        font-size: 4em;
    }

    &--5x {
        font-size: 5em;
    }

    &::after {
        font-family: $font-family-icons;
        font-weight: 300 !important;
    }

    &--ad::after {
        content: '\f641';
    }

    &--add::after {
        content: '\2b';
    }

    &--alarm-exclamation::after {
        content: '\f843';
    }

    &--align-left::after {
        content: '\f036';
    }

    &--analytics::after {
        content: '\f643';
    }

    &--angle-right::after {
        content: '\f105';
    }

    &--angle-left::after {
        content: '\f104';
    }

    &--angle-up::after {
        content: '\f106';
    }

    &--angle-down::after {
        content: '\f107';
    }

    &--angle-double-down::after {
        content: '\f103';
    }

    &--angle-double-up::after {
        content: '\f102';
    }

    &--arrow-right-from-line::after {
        content: '\f343';
    }

    &--arrows-rotate::after {
        content: '\f021';
    }

    &--arrows-rotate-animated::after {
        display: inline-block;
        animation: full-rotate 1s infinite;
        content: '\f021';
    }

    &--award::after {
        content: '\f559';
    }

    &--bars::after {
        content: '\f0c9';
    }

    &--bars-sort::after {
        content: '\e0ae';
    }

    &--barcode::after {
        content: '\f02a';
    }

    &--bell::after {
        content: '\f0f3';
    }

    &--box-check::after {
        content: '\f467';
    }

    &--box-full::after {
        content: '\f49c';
    }

    &--boxes-alt::after {
        content: '\f4a1';
    }

    &--lightbulb::after {
        content: '\f0eb';
    }

    &--book-section::after {
        content: '\e0c1';
    }

    &--bullhorn::after {
        content: '\f0a1';
    }

    &--chart-network::after {
        content: '\f78a';
    }

    &--chart-line::after {
        content: '\f201';
    }

    &--pie-chart::after {
        content: '\f64e';
    }

    &--chevron-circle-down::after {
        content: '\f13a';
    }

    &--chevron-circle-up::after {
        content: '\f139';
    }

    &--chevron-circle-right::after {
        content: '\f138';
    }

    &--chevron-down::after {
        content: '\f078';
    }

    &--chevron-right::after {
        content: '\f054';
    }

    &--chevron-up::after {
        content: '\f077';
    }

    &--chevron-left::after {
        content: '\f053';
    }

    &--circle::after {
        content: '\f111';
    }

    &--circle-radiation::after {
        content: '\f7ba';
    }

    &--circle-up::after {
        content: '\f35b';
    }

    &--clipboard-check::after {
        content: '\f46c';
    }

    &--comment::after {
        content: '\f075';
    }

    &--cog::after {
        content: '\f013';
    }

    &--cogs::after {
        content: '\f085';
    }

    &--compress::after {
        content: '\f066';
    }

    &--cube::after {
        content: '\f1b2';
    }

    &--divide::after {
        content: '\f529';
    }

    &--edit {
        &::after {
            content: '\f044';
        }

        &.fa-fw {
            // mathematical center != visual center
            left: 0.1em;
        }
    }

    &--ellipsis-h::after {
        content: '\f141';
    }

    &--ellipsis-v::after {
        content: '\f142';
    }

    &--envelope-open-dollar::after {
        content: '\f657';
    }

    &--envelope-open-text::after {
        content: '\f658';
    }

    &--exclamation::after {
        content: '\21';
    }

    &--exclamation-triangle::after {
        content: '\f071';
    }

    &--exchange::after {
        content: '\f0ec';
    }

    &--expand::after {
        content: '\f065';
    }

    &--external-link::after {
        content: '\f08e';
    }

    &--face-smile::after {
        content: '\f118';
    }
    
    &--facebook::after {
        content: '\f09a';
    }

    &--facebook-f::after {
        content: '\f39e';
    }

    &--fingerprint::after {
        content: '\f577';
    }

    &--file::after {
        content: '\f15b';
    }

    &--file-check::after {
        content: '\f316';
    }

    &--file-certificate::after {
        content: '\f5f3';
    }

    &--file-image::after {
        content: '\f1c5';
    }

    &--file-invoice-dollar::after {
        content: '\f571';
    }

    &--file-pdf::after {
        content: '\f1c1';
    }

    &--filter::after {
        content: '\f0b0';
    }

    &--triangle-exclamation::after {
        content: '\f071';
    }

    &--globe::after {
        content: '\f0ac';
    }

    &--grip-lines::after {
        content: '\f7a4';
    }

    &--hand-holding-usd::after {
        content: '\f4c0';
    }

    &--heart::after {
        content: '\f004';
    }

    &--home::after {
        content: '\f015';
    }

    &--info::after {
        content: '\f05a';
    }

    &--info-basic::after {
        content: '\f129';
    }

    &--instagram::after {
        content: '\f16d';
    }

    &--language::after {
        content: '\f1ab';
    }

    &--life-ring::after {
        content: '\f1cd';
    }

    &--lightbulb::after {
        content: '\f0eb';
    }

    &--link::after {
        content: '\f0c1';
    }

    &--list::after {
        content: '\f03a';
    }

    &--list-check::after {
        content: '\f0ae';
    }

    &--location-dot::after {
        content: '\f3c5';
    }

    &--map-marker::after {
        content: '\f041';
    }

    &--megaphone::after {
        content: '\f675';
    }

    &--minus::after {
        content: '\f068';
    }

    &--money-bill-wave::after {
        content: '\f53a';
    }

    &--money-bill-trend-up::after {
        content: '\e529';
    }

    &--paper-plane::after {
        content: '\f1d8';
    }

    &--paperclip::after {
        content: '\f0c6';
    }

    &--pen::after {
        content: '\f304';
    }

    &--pen-ruler::after {
        content: '\f5ae';
    }

    &--pencil::after {
        content: '\f040';
    }

    &--percent::after {
        content: '\f295';
    }

    &--plus::after {
        content: '\2b';
    }

    &--photo-film::after {
        content: '\f87c';
    }

    &--rectangle-vertical-history::after {
        content: '\e237';
    }

    &--right-to-bracket::after {
        content: '\f2f6';
    }

    &--rocket::after {
        content: '\f135';
    }

    &--sack-dollar::after {
        content: '\f81d';
    }

    &--search::after {
        content: '\f002';
    }

    &--section::after {
        content: '\e447'
    }

    &--shield-halved::after {
        content: '\f3ed';
    }

    &--ship::after {
        content: '\f21a';
    }

    &--shipping-fast::after {
        content: '\f48b';
    }

    &--shopping-cart::after {
        content: '\f07a';
    }

    &--sort-amount-up::after {
        content: '\f161';
    }

    &--spinner::after {
        content: '\f110';
    }

    &--star::after {
        content: '\f006';
    }

    &--star-o::after {
        content: '\f005';
    }

    &--swords::after {
        content: '\f71d';
    }

    &--sync::after {
        content: '\f021';
    }

    &--sync-alt::after {
        content: '\f2f1';
    }

    &--tags::after {
        content: '\f02c';
    }

    &--trophy::after {
        content: '\f091';
    }

    &--thumbs-down::after {
        content: '\f165';
    }

    &--thumbs-up::after {
        content: '\f164';
    }

    &--tick::after {
        content: '\f00c';
    }

    &--timeline-arrow::after {
        content: '\e29d';
    }

    &--times::after {
        content: '\f00d';
    }

    &--times-circle::after {
        content: '\f057';
    }

    &--trash::after {
        content: '\f1f8';
    }

    &--trash-alt::after {
        content: '\f2ed';
    }

    &--twitter::after {
        content: '\f099';
    }

    &--x-twitter::after {
        content: '\e61b';
    }

    &--undo::after {
        content: '\f0e2';
    }

    &--university::after {
        content: '\f19c';
    }

    &--smile::after {
        content: '\f118';
    }

    &--shield-check::after {
        content: '\f2f7';
    }

    &--hands-helping::after {
        content: '\f4c4';
    }

    &--hand-pointer::after {
        content: '\f25a';
    }

    &--window-close::after {
        content: '\f00d';
    }

    &--youtube::after {
        content: '\f167';
    }

    &--upload::after {
        content: '\f093';
    }

    &--download::after {
        content: '\f019';
    }

    &--excel::after {
        content: '\f1c3';
    }

    &--envelope::after {
        content: '\f0e0';
    }

    &--print::after {
        content: '\f02f';
    }

    &--credit-card::after {
        content: '\f09d';
    }

    &--eye::after {
        content: '\f06e';
    }

    &--eye-slash::after {
        content: '\f070';
    }

    &--ban::after {
        content: '\f05e';
    }

    &--cart-plus::after {
        content: '\f217';
    }

    &--location-arrow::after {
        content: '\f124';
    }

    &--badge-check::after {
        content: '\f336';
    }

    &--trophy::after {
        content: '\f2eb';
    }

    &--medal::after {
        content: '\f5a2';
    }

    &--tools::after {
        content: '\f7d9';
    }

    &--sign-out-alt::after {
        content: '\f2f5';
    }

    &--id-card::after {
        content: '\f2c2';
    }

    &--copy::after {
        content: '\f0c5';
    }

    &--check::after {
        content: '\f00c';
    }

    &--check-circle::after {
        content: '\f058';
    }

    &--check-square::after {
        content: '\f14a';
    }

    &--sliders-h::after {
        content: '\f1de';
    }

    &--sliders-simple::after {
        content: '\e253';
    }

    &--sitemap::after {
        content: '\f0e8';
    }

    &--cubes::after {
        content: '\f1b3';
    }

    &--arrow-up::after {
        content: '\f062';
    }

    &--arrow-down::after {
        content: '\f063';
    }

    &--arrow-up-from-bracket::after {
        content: '\e09a';
    }

    &--save::after {
        content: '\f0c7';
    }

    &--cube::after {
        content: '\f1b2';
    }

    &--pencil::after {
        content: '\f040';
    }

    &--rocket::after {
        content: '\f135';
    }

    &--analytics::after {
        content: '\f643';
    }

    &--arrows::after {
        content: '\f047';
    }

    &--plus-circle::after {
        content: '\f055';
    }

    &--minus-circle::after {
        content: '\f056';
    }

    &--image::after {
        content: '\f03e';
    }

    &--images::after {
        content: '\f302';
    }

    &--piggy-bank::after {
        content: '\f4d3';
    }

    &--clock::after {
        content: '\f017';
    }

    &--clock-rotate-left::after {
        content: '\f1da';
    }

    &--thumbtack::after {
        content: '\f08d';
    }

    &--hashtag::after {
        content: '\f292';
    }

    &--memo::after {
        content: '\e1d8';
    }

    &--arrow-right::after {
        content: '\f061';
    }

    &--arrow-left::after {
        content: '\f060';
    }

    &--user::after {
        content: '\f007';
    }

    &--user-circle::after {
        content: '\f2bd';
    }

    &--user-cog::after {
        content: '\f4fe';
    }

    &--user-crown::after {
        content: '\f6a4';
    }

    &--user-edit::after {
        content: '\f4ff';
    }

    &--user-hard-hat::after {
        content: '\f82c';
    }

    &--user-headset::after {
        content: '\f82d';
    }

    &--user-plus::after {
        content: '\f234';
    }

    &--user-secret::after {
        content: '\f21b';
    }

    &--user-shield::after {
        content: '\f505';
    }

    &--user-tie::after {
        content: '\f508';
    }

    &--users::after {
        content: '\f0c0';
    }

    &--users-cog::after {
        content: '\f509';
    }

    &--users-crown::after {
        content: '\f6a5';
    }

    &--question-circle::after {
        content: '\f059';
    }

    &--trophy::after {
        content: '\f091';
    }

    &--route::after {
        content: '\f4d7';
    }

    &--puzzle-piece::after {
        content: '\f12e';
    }

    &--lock::after {
        content: '\f023';
    }

    &--unlock::after {
        content: '\f09c';
    }

    &--scale-balanced::after {
        content: '\f24e';
    }

    &--scale-unbalanced::after {
        content: '\f515';
    }

    &--scroll::after {
        content: '\f70e';
    }

    &--search-plus::after {
        content: '\f00e';
    }

    &--seeding::after {
        content: '\f4d8';
    }

    &--comments-question-check::after {
        content: '\e14f';
    }

    &--cart-arrow-down {
        &::after {
            content: '\f218';
        }

        &.fa-fw {
            // mathematical center != visual center
            left: -0.1em;
        }
    }

    &--user-minus {
        &::after {
            content: '\f503';
        }

        &.fa-fw {
            // mathematical center != visual center
            left: 0.1em;
        }
    }

    &--file-export {
        &::after {
            content: '\f56e';
        }

        &.fa-fw {
            // mathematical center != visual center
            left: 0.1em;
        }
    }

    &--comments::after {
        content: '\f086';
    }

    &--flag::after {
        content: '\f024';
    }

    &--hourglass::after {
        content: '\f254';
    }

    &--calendar::after {
        content: '\f133';
    }

    &--ghost::after {
        content: '\f6e2';
    }

    &--heart-broken::after {
        content: '\f7a9';
    }

    &--sad-cry::after {
        content: '\f5b3';
    }

    &--thermometer-quarter::after {
        content: '\f2ca';
    }

    &--hand-spock::after {
        content: '\f259';
    }

    &--rocket::after {
        content: '\f135';
    }

    &--parachute-box::after {
        content: '\f4cd';
    }

    &--fire::after {
        content: '\f06d';
    }

    &--pause::after {
        content: '\f04c';
    }
    
    &--pig::after {
        content: '\f706';
    }

    &--play-circle::after {
        content: '\f144';
    }

    &--play::after {
        content: '\f04b';
    }

    &--space-station-moon-alt::after {
        content: '\e034';
    }

    &--exclamation-circle::after {
        content: '\f06a';
    }

    &--ruler::after {
        content: '\f545';
    }

    &--dice::after {
        content: '\f522';
    }

    &--briefcase::after {
        content: '\f0b1';
    }

    &--bullseye-arrow::after {
        content: '\f648';
    }

    &--video::after {
        content: '\f03d';
    }

    &--poll::after {
        content: '\f681';
    }

    &--door-open::after {
        content: '\f52b';
    }

    &--door-closed::after {
        content: '\f52a';
    }

    &--unlink::after {
        content: '\f127';
    }

    &--birthday-cake::after {
        content: '\f1fd';
    }

    &--history::after {
        content: '\f1da';
    }

    &--gem::after {
        content: '\f3a5';
    }

    &--badge-dollar::after {
        content: '\f645';
    }

    &--address-card::after {
        content: '\f2bb';
    }

    &--university::after {
        content: '\f19c';
    }

    &--unicorn::after {
        content: '\f727';
    }

    &--cart-circle-plus::after {
        content: '\e3f3';
    }

    &--cart-circle-check::after {
        content: '\e3f1';
    }

    &--books::after {
        content: '\f5db';
    }

    &--hat-wizard::after {
        content: '\f6e8';
    }

    &--money-withdrawal::after {
        content: '\e313';
    }

    &--atom-simple::after {
        content: '\f5d3';
    }

    &--store::after {
        content: '\f54e';
    }

    &--broom-wide::after {
        content: '\e5d1';
    }

    &--section::after {
        content: '\e447';
    }

    &--warehouse::after {
        content: '\f494';
    }

    &--warehouse-full::after {
        content: '\f495';
    }

    &--shelves::after {
        content: '\f480';
    }

    &--grip-horizontal::after {
        content: '\f58d';
    }

    &--shape::after {
        content: '\f61f';
    }

    &--share-nodes::after {
        content: '\f1e0'
    }

    &--shopping-bag::after {
        content: '\f290';
    }

    &--file-circle-question::after {
        content: '\e4ef';
    }

    &--sparkles::after {
        content: '\f890';
    }

    &--watch::after {
        content: '\f2e1';
    }
    
    &--wallet::after {
        content: '\f555';
    }
    
    &--microchip-ai::after {
        content: '\e1ec';
    }

    &--gauge-max::after {
        content: '\f626';
    }

    &--gauge::after {
        content: '\f624';
    }    
}

._fa-brands::after {
    font-family: $font-family-brands;
    font-weight: 300 !important;
}

@keyframes full-rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
