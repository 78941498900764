@import 'mixins';
@import 'variables';

/***
  * Module / Currency selector
  */

.#{$ns}currency-selector {
    position: relative;

    &__label {
        display: flex;
        line-height: $font-size-plus;
        padding: $unit-size-base;
        min-width: $unit-size-small * 13;
        cursor: pointer;
        justify-content: space-between;
    }

    &__select {
        position: relative;
        border: 1px solid $color-input-border;
        border-radius: $input-field-border-radius;

        select {
            opacity: 0;

            @include embed-fill;

            @include breakpoint('m') {
                display: none;
            }
        }

        &.is-expanded {
            @include breakpoint('m') {
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
                border-bottom: 1px solid $color-white;
                background-color: $color-white;
                z-index: 1;
            }
        }
    }

    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        min-width: $unit-size-small * 34;
        max-height: 280px; // 10 lines of items
        background-color: $color-white;
        border: 1px solid $color-input-border;
        border-radius: $input-field-border-radius;
        border-top-left-radius: 0;
        margin-top: -1px;
        padding: $unit-size-small 0;
        overflow-y: auto;
    }
}

/***
  * Module / Currency selector option
  */

.#{$ns}currency-selector-option {
    padding: $unit-size-small $unit-size-base;
    line-height: $font-size-plus;
    white-space: nowrap;
    cursor: pointer;

    &:hover {
        background-color: darken($color-white, 10%);
    }

    &.is-selected {
        font-weight: bold;
    }
}

/***
  * Module / Currency selector label
  */

.#{$ns}currency-selector-label {
    padding: $unit-size-small $unit-size-base;
    line-height: $font-size-plus;
    white-space: nowrap;
    color: $color-text-light;
    font-size: $font-size-micro;
    text-transform: uppercase;
}
