@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Thumb
  */

$thumb-outer-radius: $input-field-border-radius;
$thumb-inner-radius: ceil(math.div($thumb-outer-radius * 2, 3));
$thumb-padding: ceil($unit-size-small * 0.5);

.#{$ns}thumb {
    display: flex;
    position: relative;
    box-shadow: $box-shadow-tight;
    border-radius: $thumb-outer-radius;
    background: $color-white;
    padding: $thumb-padding;

    &__item {
        border-radius: $thumb-inner-radius;
        overflow: hidden;
    }

    &--small {
        width: $unit-size-small * 9;
        min-width: $unit-size-small * 9;
        height: $unit-size-small * 9;
    }

    &--medium {
        width: $unit-size-small * 12;
        min-width: $unit-size-small * 12;
        height: $unit-size-small * 12;
    }

    &--large {
        width: $unit-size-small * 16;
        min-width: $unit-size-small * 16;
        height: $unit-size-small * 16;
    }

    &--big {
        width: $unit-size-small * 20;
        min-width: $unit-size-small * 20;
        height: $unit-size-small * 20;
    }
}
