@import 'mixins';
@import 'variables';

/***
  * Module / Announcement Box
  */

.#{$ns}announcement-box {
    &__slider {
        width: 100%;
        display: block;
        user-select: none;
        position: relative;
        max-height: 370px;

        &::before {
            content: '';
            display: block;

            @include aspect-ratio-1-2;
        }

        @include breakpoint('m') {
            &::before {
                @include aspect-ratio-3-1;
            }
        }

        @include breakpoint('xl') {
            &::before {
                @include aspect-ratio-4-1;
            }
        }
    }
}

.#{$ns}announcement-content {
    height: 100%;
    width: 100%;
    display: flex;
    gap: $unit-size-base;
    overflow: hidden;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-repeat: no-repeat;
    box-shadow: $box-shadow-base;
    justify-content: space-between;
    border-radius: $common-border-radius;
    background-size: auto;
    background-color: $color-background-lighter;
    background-position: center;
    padding: $unit-size-small * 5;

    @include breakpoint('m') {
        gap: 0;
        align-items: center;
        flex-direction: row;
        justify-content: initial;
        background-size: cover;
        background-position: initial;
    }

    &__packshot-wrapper {
        width: 100%;
        aspect-ratio: 2 / 1;
        min-height: $unit-size-base *  9;

        @include breakpoint('m') {
            width: 50%;
            height: 100%;
            text-align: center;
        }
    }

    &__packshot {
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        width: 100%;
        height: 100%;
    }

    &__description {
        @include breakpoint('m') {
            width: 50%;
            padding: 0 $unit-size-small * 5;
        }

        &--no-image {
            padding: 0 $unit-size-small * 2;

            @include breakpoint('m') {
                padding: 0;
            }
        }
    }

    &__header {
        margin: 0;
        font-size: $font-size-big;
        line-height: $unit-size-small *  5;
        font-weight: $font-weight-extrabold;
    }

    &--no-image {
        justify-content: center;
    }
}

.#{$ns}announcement-box-slider-nav {
    font-size: $font-size-super;

    &--prev {
        transform: translate(-20%, -50%);

        @include breakpoint('xl') {
            transform: translate(-50%, -50%);
        }
    }

    &--next {
        transform: translate(20%, -50%);

        @include breakpoint('xl') {
            transform: translate(50%, -50%);
        }
    }
}
