@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Module / Gallery
  */

$gallery-height-fixed: $unit-size-base * 37;

.#{$ns}gallery {
    position: relative;
    text-align: center;
    background: $color-white;
    box-shadow: $box-shadow-tight;
    border-radius: $common-border-radius;
    height: $gallery-height-fixed;

    &--proportional {
        height: 0;
        padding-bottom: 56.25%;
        /* 16:9 */
        position: relative;

        .#{$ns}gallery__slider {
            position: absolute !important;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    &--mobile {
        z-index: $z-base + 1;
        border-radius: 0;

        .#{$ns}gallery {
            &__slide {
                border-radius: 0;
            }

            &__video {
                border-radius: 0;
            }
        }
    }

    &__no-items {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        color: $color-background-base;

        span::after {
            font-size: $font-size-immense;

            @include breakpoint('m') {
                font-size: $font-size-immense * 2;
            }
        }
    }

    &__content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        top: 50%;
        position: absolute;
        left: 0;
        right: 0;
        transform: translateY(-50%);
    }

    &__slide {
        border-radius: $common-border-radius;
    }

    &__dots {
        padding-top: $unit-size-small;
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: -$unit-size-small * 5;

        button {
            font-size: 0;
            background: $color-background-base;
            width: $unit-size-large;
            height: $unit-size-small * 0.5;
            border: 0;
            border-radius: $unit-size-small * 0.5;
        }
    }

    &__prev,
    &__next {
        top: 50%;
        transform: translateY(-50%);
        width: $unit-size-base * 3;
        height: $unit-size-base * 3;
        line-height: $unit-size-base * 3;
        padding: 0;
        border: none;
        border-radius: $unit-size-large;
        color: $color-accent-base;
        background: $color-white;
        font-size: $font-size-enormous;
        box-shadow: $box-shadow-base;
        margin: 0 $unit-size-small * 3;

        @include breakpoint('m') {
            margin: 0;
        }

        &:hover {
            color: $color-text-primary;
        }
    }

    &__next {
        right: -$unit-size-base;

        &::before {
            position: absolute;
            right: 9px;
            font-family: $font-family-icons;
            content: '\f105 ';
            padding-left: math.div($unit-size-base, 3);
        }
    }

    &__prev {
        left: -$unit-size-base;

        &::before {
            position: absolute;
            left: 9px;
            font-family: $font-family-icons;
            content: '\f104';
            padding-right: math.div($unit-size-base, 3);
        }
    }

    &__play {
        align-self: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: $unit-size-small $unit-size-base;
        border-radius: $unit-size-large;
        width: $unit-size-large * 4;
        font-weight: $font-weight-bold;
        color: $color-white;
        background: $color-primary-base;
        box-shadow: $box-shadow-base;
        text-transform: uppercase;

        &:hover {
            background: $color-accent-base;
            cursor: pointer;
        }

        &::after {
            margin-left: 6px;
            font-family: $font-family-icons;
            font-weight: $font-weight-normal;
            font-size: $font-size-big;
            content: '\f144';
        }
    }

    &__play-button {
        border: solid 2px $color-white;
        box-shadow: $box-shadow-tight;
        padding-top: $unit-size-small + 4;
        padding-bottom: $unit-size-small + 3;
        line-height: $unit-size-small * 3 + 1;

        &:hover {
            border: solid 2px $color-white;
        }
    }

    &__video {
        border-radius: $common-border-radius;

        &-wrapper {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}