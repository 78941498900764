@import 'variables';
@import 'mixins';

/***
  * Component / Empty state
  */

.#{$ns}empty-state {
    display: flex;
    flex: 1;
    min-height: $unit-size-small * 40;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    &--small {
        min-height: $unit-size-small * 12;
    }
}

.#{$ns}empty-state-action {
    margin: $unit-size-small * 0.5;
}
