@import 'variables';
@import 'mixins';

/***
  * Component / Drawer
  */

.#{$ns}drawer {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    flex-direction: column;
    z-index: 100;
    height: 100%;
    max-height: 100vh;
    overflow: hidden;
    opacity: 0;
    transition: $transition-opacity;

    &__header {
        position: relative;
        flex-shrink: 0;
        background-color: $color-white;
        box-shadow: $box-shadow-base;
        z-index: 1;
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: $unit-size-large * 10;
        width: 75%;
        max-width: $unit-size-large * 15;
        background-color: $color-white;
        overflow-y: auto;
        justify-content: stretch;
        flex-grow: 1;

        &--fill {
            width: 100%;
            max-width: 100%;
        }
    }

    &__body {
        overflow: auto;
        -webkit-overflow-scrolling: touch;
        flex-grow: 1;
    }

    &__footer {
        flex-grow: 0;
        flex-shrink: 0;
        box-shadow: $box-shadow-base;
    }

    &__backdrop {
        @include embed-fill();

        content: '';
        background-color: $color-background-darker;
        opacity: 0.75;
        pointer-events: all;
    }

    &--right &__body {
        align-self: flex-end;
    }

    &.is-open {
        opacity: 1;
    }
}

/***
  * Component / Drawer Animation
  */

.gfu-drawer-slide-right {
    &-enter-active, &-leave-active {
        .gfu-drawer__content {
            transition: transform $transition-slow;
        }

        .gfu-drawe__backdrop {
            transition: $transition-opacity;
        }
    }

    &-enter, &-leave-to {
        .gfu-drawer__content {
            transform: translateX(-100%);
        }

        .gfu-drawer__backdrop {
            opacity: 0;
        }
    }
}

.gfu-drawer-slide-left {
    &-enter-active, &-leave-active {
        .gfu-drawer__content {
            transition: transform $transition-slow;
        }

        .gfu-drawer__backdrop {
            transition: $transition-opacity;
        }
    }

    &-enter, &-leave-to {
        .gfu-drawer__content {
            transform: translateX(+100%);
        }

        .gfu-drawer__backdrop {
            opacity: 0;
        }
    }
}
