@import 'variables';
@import 'mixins';

/***
  * Skeletons / Media
  */

.#{$ns}skeleton-avatar {
    width: $unit-size-small * 11;
    height: $unit-size-small * 11;
    border-radius: 100%;
    background-color: $color-skeleton-base;
    box-shadow: $box-shadow-tight;
}
