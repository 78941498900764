@import 'variables';
@import 'mixins';

/***
  * Module / Project explorer
  */

.#{$ns}project-explorer-card-wrapper {
    min-height: 585px;

    @include breakpoint('s') {
      min-height: 475px;
    }

    @include breakpoint('l') {
      min-height: 580px;
    }
}
