﻿@import 'variables';
@import 'mixins';

/***
  * Component / List Info
  */

.#{$ns}list-info {
    position: relative;
    display: block;

    &--thick {
        margin: $unit-size-base*3 0;
    }

    &__left {
        width: 100%;

        @include breakpoint('m') {
            display: inline-block;
            width: 70%;
        }

        @include breakpoint('l') {
            width: 80%;
        }
    }

    &__right {
        @include breakpoint('m') {
            display: inline-block;
            vertical-align: top;
            float: right;
        }

        &--bottom {
            @include breakpoint('m') {
                margin-top: $unit-size-small*5;
            }
        }

        &--has-subheading {
            @include breakpoint('m') {
                margin-top: $unit-size-small*7;
            }
        }
    }

    &__sort {
        margin-top: $unit-size-base;
        text-align: center;

        @include breakpoint('m') {
            margin-top: $unit-size-base*3;
            text-align: right;
        }

        &--label {
            display: none;
            padding-right: $unit-size-base;

            @include breakpoint('m') {
                display: inline-block;
            }
        }

        &--select {
            float: none;
            width: 100%;
            display: inline-block;

            @include breakpoint('m') {
                float: right;
                width: auto;
            }
        }
    }
}
