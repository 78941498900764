@import 'variables';

/***
  * Module / Credit card overview
  */

.#{$ns}credit-card-overview {
    display: inline-flex;
    align-items: center;
    background-color: $color-background-light;
    padding: $unit-size-base;
    border-radius: $common-border-radius;

    &__label {
        word-spacing: $unit-size-small;
    }

    &--pledge {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        @include breakpoint('m') {
            display: inline-flex;
        }
    }
}
