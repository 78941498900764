﻿@import 'variables';
@import 'mixins';

/***
  * Module / Blog
  */

.#{$ns}blog {
    min-height: map-get($breakpoints, s) - $nav-min-height;
    background-color: $color-white;
    padding-top: $unit-size-base * 3;

    &__item {
        padding-bottom: $unit-size-large * 2;

        &:last-child {
            padding-bottom: 0;
        }
    }

    &__date {
        font-size: $font-size-small;
        color: $color-text-gray;
        padding-bottom: $unit-size-small;
    }

    &__description {
        font-size: $font-size-plus;
        font-weight: $font-weight-bold;
        margin: $unit-size-base 0;
    }

    &__link {
        font-size: $font-size-base;
        text-transform: none;
    }

    &__icon {
        top: 55%;
        line-height: $line-height-big;
        font-size: $font-size-base;
        color: $color-accent-base;
    }
}
