@import 'variables';
@import 'mixins';

/***
  * Module / Footer
  */

.#{$ns}footer {
    background: $color-background-darker;
}

.#{$ns}footer-section {
    padding: $unit-size-small * 2 0;
    background: $color-background-dark;
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    color: $color-text-light;

    a {
        color: $color-text-light;
    }

    @include breakpoint('m') {
        padding: $unit-size-small * 9 0;
        background-image: url($image-path + '/site-logo-sketch.svg');
    }
}

.#{$ns}footer-info-links {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include breakpoint('m') {
        flex-direction: row;
    }

    a {
        display: flex;
        margin-bottom: $unit-size-small * 2;

        @include breakpoint('m') {
            margin-bottom: 0;
            margin-right: $unit-size-small * 2;
        }
    }
}

.#{$ns}footer-communication {
    @include breakpoint('m') {
        display: flex;
        justify-content: flex-end;
    }
}

.#{$ns}footer-communication-links {
    display: flex;
    flex-direction: column;

    a {
        display: flex;
        color: $color-text-inverse;
    }
}

.#{$ns}footer-payment {
    display: flex;
    align-items: center;
    margin-top: $unit-size-small * 3;

    @include breakpoint('m') {
        margin-top: 0;
        justify-content: flex-end;
    }
}
