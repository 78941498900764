@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Component / Cards
  */

$card-space: $unit-size-small*3;

.#{$ns}card {
    position: relative;
    box-shadow: $box-shadow-base;
    border-radius: $common-border-radius;
    background-color: $color-white;
    height: 100%;


    &__wrap {
        display: flex;
        flex-direction: column;
        justify-content: stretch;
        overflow: hidden;
        border-radius: $common-border-radius;
        height: inherit;
        width: 100%;
    }

    &__labels {
        position: absolute;
        top: 0;
        left: $unit-size-small * 3;
        transform: translateY(-50%);
        z-index: 1;
    }

    &__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;

        &--scrollable {
            height: 0;
            overflow: hidden auto;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        padding: $unit-size-small * 3;
    }

    &__media {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
    }

    &--tiny-text {
        font-size: $font-size-tiny;
    }

    &__edit {
        position: absolute;
        left: 4px;
        top: 2px;
        z-index: $z-base;
    }

    &__title {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__desc {
        &,
        span {
            -webkit-line-clamp: 4;
            -webkit-box-orient: vertical;
            display: -webkit-box;
        }

        &--compact,
        &--compact span {
            -webkit-line-clamp: 3;
        }
    }

    &__desc {
        position: relative;
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        margin: $unit-size-small*0.5 0;
        font-family: $font-family-soft;
        font-size: $font-size-base;
        max-height: $unit-size-small * 15;

        @include wordbreak;

        line-height: 1.5;

        @include breakpoint('m') {
            font-size: $font-size-tiny;
            max-height: $unit-size-small * 9;
        }

        @include breakpoint('l') {
            max-height: $unit-size-large * 3 - $unit-size-small * 0.5;
        }

        strong,
        b {
            font-weight: $font-weight-normal;
        }

        div:first-child {
            display: block;
        }

        ul,
        ol,
        dl,
        div {
            display: none;
        }

        textarea {
            width: 100%;
            height: 100%;
            resize: none;
        }

        &--compact {
            max-height: $unit-size-small * 10;

            @include breakpoint('m') {
                font-size: $font-size-tiny;
                max-height: $unit-size-small * 9;
            }

            @include breakpoint('l') {
                max-height: $unit-size-small * 9;
            }
        }
    }

    &__action {
        position: absolute;
        bottom: $unit-size-small * 3;
        left: 0;
        right: 0;
        padding: 0 ($unit-size-small * 3);
        text-align: center;

        &--static {
            position: static;
            padding: 0;
            margin-top: 0;
        }
    }

    &__categories {
        text-align: center;
        width: 100%;
        overflow: hidden;
        height: $unit-size-small*10;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-bottom: $card-space;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        &--center>div {
            text-align: center;
        }
    }

    &__crowdfunding-info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $font-size-tiny;
        margin-bottom: $card-space;

        &--text-emphasized {
            font-weight: $font-weight-extrabold;
            color: $color-text-base;
        }

        &--inline {
            display: inline;
        }
    }

    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    @include breakpoint('m') {
        &--orientation-horizontal &__wrap {
            flex-direction: row;
        }

        &--orientation-horizontal &__content {
            padding: $unit-size-small * 5;
        }

        &--orientation-horizontal &__media {
            flex-basis: 50%;
        }
    }

    &--media-size-half &__media {
        flex-basis: 50%;
    }

    &--media-size-third &__media {
        flex-basis: 33%;
    }

    &--media-size-quarter &__media {
        flex-basis: 25%;
    }

    &--scheme-inverse {
        background-color: $color-background-dark;
    }
}

.#{$ns}card-title {
    margin: 0;
    font-size: $font-size-extra;
    font-family: $font-family-decorative;
    font-weight: 400;

    @include breakpoint(l) {
        font-size: $font-size-super;
    }

    &--compact {
        font-size: $font-size-extra;
        margin-bottom: $unit-size-small;
    }

    &--featured {
        font-size: $font-size-extra;

        @include breakpoint('m') {
            font-size: $font-size-super;
        }

        @include breakpoint('xl') {
            font-size: $font-size-enormous;
        }
    }

    &--scheme-inverse {
        color: $color-accent-base;
    }
}

/***
  * Module / Card List
  */
.#{$ns}card-list {
    $gutter-s: $unit-size-small * 2;
    $gutter-m: $unit-size-small * 2;
    $gutter-l: $unit-size-small * 3;

    position: relative;
    display: flex;
    list-style: none;
    margin: 0 -$gutter-s;

    @include breakpoint('m') {
        margin-left: -$gutter-m;
        margin-right: -$gutter-m;
        scroll-padding: $gutter-m;
    }

    @include breakpoint('l') {
        flex-wrap: wrap;
        margin-left: -$gutter-l;
        margin-right: -$gutter-l;
    }

    &__item {
        padding: $gutter-s;

        @include breakpoint('m') {
            padding: $gutter-m;
        }

        @include breakpoint('l') {
            padding: $gutter-l;
        }
    }

    &--wrap {
        flex-wrap: wrap;
    }
}