@import 'mixins';
@import 'variables';

.#{$ns}installments-box {
    padding: $layout-padding;
    display: flex;
    flex-direction: column;
    gap: $unit-size-base;
    background-color: $color-background-light;
    border-radius: $common-border-radius;
    margin-top: $unit-size-base;
    cursor: default;
}

.#{$ns}installments-plan {
    padding: $layout-padding;
    background-color: $color-background-lighter;
    box-shadow: $box-shadow-base;
    border: none;

    &__header {
        color: $color-text-accent;
        font-size: $font-size-base;
        cursor: pointer;

        &::first-letter {
            text-transform: lowercase;
        }
    }

    &__information {
        display: none;
        overflow: hidden;
        transition: min-height $transition-quick;

        &.is-open {
            display: block;
        }
    }
}

.#{$ns}installments-summary {
    padding: $layout-padding;
    background-color: $color-background-light;
    border-radius: $common-border-radius;
    margin-top: $unit-size-base;

    &__values-list {
        margin-top: $unit-size-base;
        display: flex;
        flex-direction: column;
        gap: $unit-size-base * 0.5;
        font-size: $font-size-tiny;
    }
}

.#{$ns}installment-state-box {
    padding: $layout-padding;
    background-color: $color-background-lighter;
    box-shadow: $box-shadow-base;
    border-radius: $common-border-radius;

    &__header {
        text-transform: uppercase;
        margin-top: 0;
        color: $color-text-lighter;
    }
}

.#{$ns}installment-details {
    display: flex;
    align-items: center;
    gap: $unit-size-small * 0.5;
    color: $color-text-base;

    &__value {
        font-size: $font-size-extra;
        font-weight: $font-weight-extrabold;
    }
}
