@import 'variables';
@import 'mixins';

/***
  * Module / Success Box
  */

$success-box-logos-offset: 5px;

.#{$ns}success-box {
    margin-top: $unit-size-large * 3;

    &__images {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
    }

    &__avatar {
        margin-right: -$success-box-logos-offset;
    }

    &__head {
        position: relative;
        padding-top: $unit-size-small * 10;
        background-color: $color-background-accent;
        color: $color-text-inverse;
    }

    &__body {
        @include breakpoint('m') {
            padding-left: $unit-size-small * 17;
            padding-right: $unit-size-small * 17;
            padding-bottom: $unit-size-small * 17;
        }
    }
}
/***
  * Module / Success Box Logo
  */

.#{$ns}success-box-logo {
    display: block;
    width: $unit-size-small * 10;
    height: $unit-size-small * 10;
    border-radius: $unit-size-small * 10;
    background: $color-accent-base url($image-path + 'projects/summary-stamp.png') no-repeat 50% 50%;
    box-shadow: $box-shadow-tight;
    z-index: $z-base;
    margin-left: -$unit-size-base;
    margin-right: -$unit-size-base;
}

/***
  * Module / Success Box
  */

.#{$ns}success-box-backer {
    padding: $unit-size-small $unit-size-large;
    display: inline-block;
    border: 2px solid $color-white;
    border-radius: $unit-size-large * 2;
}

/***
  * Module / Success Box
  */

.#{$ns}success-box-social {
    @include box-padding();

    background-color: $color-primary-base;
    color: $color-text-inverse;
    border-radius: $common-border-radius;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include breakpoint(m) {
        flex-direction: row;
    }

    &__link {
        display: inline-block;
        margin-top: $unit-size-base;
        font-size: $font-size-big;

        &:active,
        &:hover {
            text-decoration: none;
        }

        @include breakpoint(m) {
            margin-top: 0;
            margin-left: $unit-size-base * 2;
        }
    }
}

/***
  * Module / Success Box
  */

.gfu-success-box-stretch-goals {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include breakpoint(m) {
        margin: 0 (-$unit-size-small * 3);
        flex-direction: row;
    }

    &__col {
        @include breakpoint(m) {
            padding: 0 $unit-size-small * 3;
            flex-direction: row;
        }
    }
}

/***
  * Module / Success Box
  */

.gfu-success-box-stretch-goal-item {
    position: relative;
    box-shadow: $box-shadow-base;
    margin-bottom: $unit-size-small * 5;
    border-radius: $common-border-radius;

    &__side {
        position: relative;
        overflow: hidden;
        border-radius: $common-border-radius;
        padding: $unit-size-small * 0.5;
    }

    &__body {
        overflow: hidden;
        padding: $unit-size-small $unit-size-small * 2;
    }

    &__cloud {
        position: absolute;
        right: 0;
        top: -14px; // Magic number to align items
    }
}

/***
  * Module / Success Box
  */

.gfu-success-box-stretch-goal-total {
    position: relative;

    &__cloud {
        position: absolute;
        right: 0;
        bottom: $unit-size-small * 2;
    }
}
