@import 'variables';
@import 'mixins';

.#{$ns}product-recommendation {
    &__item {
        padding: 0 $unit-size-small;

        @include breakpoint('m') {
            padding: 0 $unit-size-small + $unit-size-small * 0.5;
        }

        &:hover {
            text-decoration: none;
        }
    }

}