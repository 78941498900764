@import 'mixins';
@import 'variables';

/***
  * Module / Content
  */

.#{$ns}content-head {
    margin: $unit-size-small * 4 0;
    text-align: center;

    @include breakpoint('l') {
        margin: $unit-size-small * 6 0 $unit-size-small * 5;
        text-align: left;
    }
}
