@import 'variables';
@import 'mixins';


/**
 * Actions Like
 */

.#{$ns}like {
    line-height: $unit-size-base * 2;
    height: $unit-size-base * 2;

    &.is-liked {
        color: $color-accent-base;
    }
}
