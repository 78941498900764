@import 'variables';
@import 'mixins';

/***
  * Component / Chatbox
  */

.#{$ns}chatbox {
    min-height: $unit-size-small * 9;

    &__message-wrapper {
        position: relative;
        overflow-y: auto;
        border-radius: $common-border-radius;

        &--empty {
            background-color: $color-background-light;
            color: $color-text-gray;
            font-size: $font-size-base;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    &__message {
        position: relative;
        text-align: right;
        padding: ($unit-size-small * 0.5) $unit-size-base;
        margin: ($unit-size-large) $unit-size-base;

        &--own {
            text-align: left;
            margin-left: 0;

            .#{$ns}chatbox__name {
                color: $color-text-primary;
                float: left;

                &::before {
                    border-color: $color-text-primary;
                    left: 0;
                    right: unset;
                }
            }

            .#{$ns}chatbox__date {
                &::after {
                    display: none;
                }

                &::before {
                    display: inline-block;
                }
            }

            .#{$ns}chatbox__content {
                text-align: left;
                padding-left: 0;
                padding-right: $unit-size-large * 4;
            }
        }
    }

    &__name {
        display: inline-block;
        color: $color-text-dark;
        font-size: $font-size-small;
        font-weight: $font-weight-extrabold;
        float: right;

        &::before {
            content: '';
            border-left: solid 2px $color-text-dark;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
        }
    }

    &__date {
        display: inline-block;
        color: $color-text-light;
        font-size: $font-size-micro;

        &::before, &::after {
            display: inline-block;
            margin: ($unit-size-small * 0.5) $unit-size-base;
            content: '';
            height: 4px;
            width: 4px;
            background-color: $color-text-light;
            border-radius: 50%;
        }

        &::before {
            display: none;
        }
    }

    &__content {
        text-align: right;
        color: $color-text-gray;
        font-size: $font-size-tiny;
        padding-top: $unit-size-small * 0.5;
        padding-left: $unit-size-large * 4;
    }

    $bubble-border-width: 4px;
    $bubble-border-radius: $unit-size-base;
    $bubble-border: solid $bubble-border-width $color-white;

    $bubble-width: $unit-size-base * 6;
    $bubble-height: $unit-size-base * 5;

    $bubble-triangle-left: $bubble-width * 0.25;
    $bubble-triangle-bottom: - $bubble-border-width * 5;

    &__bubble {
        position: absolute;
        top: -$bubble-border-width - 2px;
        left: $unit-size-large;
        border-radius: $bubble-border-radius;
        border: $bubble-border;
        width: $bubble-width;
        height: $bubble-height;

        &::before, &::after {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            right: auto;
            top: auto;
        }

        &::before {
            left: $bubble-triangle-left;
            bottom: $bubble-triangle-bottom;
            border: solid ($bubble-border-width * 2);
            border-color: $color-white transparent transparent $color-white;
        }

        &::after {
            left: $bubble-triangle-left + $bubble-border-width;
            bottom: $bubble-triangle-bottom + $bubble-border-width * 2;
            border: solid $bubble-border-width;
            border-color: $color-background-light transparent transparent $color-background-light;
            border-top-width: $bubble-border-width * 2;
        }

        &--small {
            top: $unit-size-large;
            left: unset;
            right: $unit-size-large * 2;
            border-radius: $bubble-border-radius * 0.5;
            border-width: $bubble-border-width * 0.5;
            width: $bubble-width * 0.5;
            height: $bubble-height * 0.5;

            &::before {
                left: $bubble-triangle-left * 0.5;
                bottom: $bubble-triangle-bottom * 0.5;
                border: solid ($bubble-border-width * 2) * 0.5;
                border-color: $color-white transparent transparent $color-white;
            }

            &::after {
                left: ($bubble-triangle-left + $bubble-border-width) * 0.5;
                bottom: ($bubble-triangle-bottom + $bubble-border-width * 2) * 0.5;
                border: solid ($bubble-border-width * 0.5);
                border-color: $color-background-light transparent transparent $color-background-light;
                border-top-width: ($bubble-border-width * 2) * 0.5;
            }
        }
    }
}
