@import 'mixins';
@import 'variables';

.#{$ns}team-members {
    overflow: hidden;
    position: relative;
    display: flex;
    padding: $unit-size-small 0;
}

.#{$ns}team-member-card {
    width: 100%;

    @include breakpoint('m') {
        width: 33.33%;
        padding: 0 $unit-size-small * 6;
    }

    &__media {
        position: relative;
        margin: 0 auto (-$unit-size-small * 3);
    }

    &__content {
        text-align: center;
        padding: $unit-size-small * 3 0;
        box-shadow: $box-shadow-base;
        border-radius: $common-border-radius;
    }
}
