.#{$ns}project-summary-overtime-card {
    @include breakpoint('xl', 'max-width') {
        gap: $unit-size-small * 3
    }
}

.#{$ns}project-overtime-avatar-stack {
    display: flex;
    justify-content: center;
    height: $unit-size-small * 7;
    position: relative;
    margin-right: -$unit-size-small * 5;

    &::after {
        content: "";
        width: $unit-size-small * 12;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        bottom: 0;
        background: linear-gradient(270deg, rgb(242 243 248 / 0) 1.74%, #fff 50%);
        height: $unit-size-small * 8;
        margin-left: -$unit-size-small * 5;
    }

    &__list {
        display: flex;
        position: absolute;

        &--sticky-right {
            right: $unit-size-base * 3;
        }
    }
}

.#{$ns}avatar-stack-container-enter-active, .#{$ns}avatar-stack-container-leave-active {
    transition: all 0.25s ease;
    animation-fill-mode: both;
    will-change: transform, opacity;
}

.#{$ns}avatar-stack-container-enter, .#{$ns}avatar-stack-container-leave-to {
    transform: translateX(140%);
    opacity: 0;
}

.#{$ns}avatar-stack-container-enter-to, .#{$ns}avatar-stack-container-leave {
    transform: translateX(0);
    opacity: 1;
}

.#{$ns}avatar-stack-container-move {
    transition: transform 0.25s ease;
}

