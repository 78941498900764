@import 'variables';
@import 'mixins';

/***
  * Helpers / Text
  */

/**
  * Aligns
  */

._tac {
    text-align: center !important;
}

._tal {
    text-align: left !important;

    &--l {
        @include breakpoint(l) {
            text-align: left !important;
        }
    }
}

._tar {
    text-align: right !important;
}

._tvam {
    vertical-align: middle !important;
}

/**
  * Text transform
  */

._ttu {
    text-transform: uppercase;
}

._ttl {
    text-transform: lowercase;
}

._ttr {
    text-transform: none;
}

/**
  * Justify-content
  */

._jcc {
    justify-content: center;
}

/**
  * Colors
  */

._tc {
    &--base {
        color: $color-text-base;
    }

    &--inverse {
        color: $color-text-inverse;
    }

    &--accent {
        color: $color-text-accent;
    }

    &--accent-inverse {
        color: $color-accent-inverse;
    }

    &--gray {
        color: $color-text-gray;
    }

    &--dark {
        color: $color-text-dark;
    }

    &--light {
        color: $color-text-light;
    }

    &--lighter {
        color: $color-text-lighter;
    }

    &--primary {
        color: $color-text-primary;
    }

    &--disabled {
        color: $color-text-disabled;
    }

    &--success {
        color: $color-message-success;
    }

    &--warning {
        color: $color-message-warning;
    }

    &--error {
        color: $color-message-error;
    }

    &--accent-4 {
        color: $color-text-accent-4;
    }

    &--inherit {
        color: inherit;
    }

    &--mute {
        opacity: 0.5;
    }

    &--white {
        color: $color-white;
    }

    &--light-gray {
        color: $color-text-light-gray;
    }

    &--urgent {
        color: $color-urgent;
    }
}

/**
  * Word break
  */

._wbbw {
    @include wordbreak;
}

/**
  * Line-height
  */
._lhc {
    line-height: $line-height-condensed;
}

._lhb {
    line-height: $line-height-base;
}

._lhh {
    line-height: $line-height-huge;
}

._lhe {
    line-height: $line-height-enormous;
}

._lh-1 {
    line-height: 1;
}

/**
  * Overflow
  */

._toe {
    @include ellipsis;
}

/**
  * Highlight
  */

._text-highlight-accent {
    background-color: $color-background-accent-highlight;
    color: $color-text-base;
}

/**
  * font-weight
  */

._fw-eb {
    font-weight: $font-weight-extrabold;
}

._fw-b {
    font-weight: $font-weight-bold;
}

._fw-n {
    font-weight: $font-weight-normal;
}

._fw-l {
    font-weight: $font-weight-light;
}

/**
  * font-family
  */

._ffb {
    font-family: $font-family-base;
}

/**
  * pointer-events
  */

._pen {
    pointer-events: none;
}

/**
  * White space
  */

._whs-nw {
    white-space: nowrap;
}

._whs-normal {
    white-space: normal;
}

._whs-pw {
    @include pre-wrap;
}

/**
  * Text decoration
  */

._tdu {
    text-decoration: underline;
}

._tdlt {
    text-decoration: line-through;
}

._tdn {
    text-decoration: none;
}

/**
  * Text decoration
  */

$line-clamp-lines: 4;

@include line-clamp($line-clamp-lines);

@each $name, $br in $breakpoints {
@include breakpoint($name) {
    @include line-clamp($line-clamp-lines, '--'+ $name);
}
}

/**
  * User Select
  * Utilities for controlling whether the user can select text in an element.
  */

._select-none {
    user-select: none;
}

/**
  * Letter spacing
  */

._lts-1 {
    letter-spacing: 1px;
}

._lts-2 {
    letter-spacing: 2px;
}
