@import 'variables';
@import 'mixins';

/***
  * Skeletons / Image
  */

  .#{$ns}skeleton-image {
    background-color: $color-skeleton-base;
    border-radius: $common-border-radius;
    aspect-ratio: 1 / 1;
  }