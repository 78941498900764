@import 'variables';
@import 'mixins';

$check-list-table-highlighted-column-title: #D0E8DF;
$check-list-table-titles-background: #F2F3F8;
$check-list-table-highlighted-row-value: #EFF7F4;
$check-list-table-light-row-icon: #E0E0E0;

.#{$ns}check-list-table {
    position: relative;
    width: 90%;
    margin: 0 auto $unit-size-base * 4 auto;

    @include breakpoint('m') {
        width: 100%;
        margin: 0 auto;
    }

    &__content {
        display: flex;
        flex-direction: column;
        position: relative;
        border-radius: $common-border-radius;
        box-shadow: $box-shadow-base;
        overflow: hidden;
    }
}

.#{$ns}check-list-table-titles {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: calc(#{$unit-size-base * 5} - #{$unit-size-small});
    background-color: $check-list-table-titles-background;

    @include breakpoint('m') {
        position: relative;
        height: calc(#{$unit-size-base * 8} - #{$unit-size-small});
    }

    &__title {
        display: none;
        flex: 1;
        padding: 0 $unit-size-base * 4;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: $font-weight-extrabold;

        @include breakpoint('m') {
            display: flex;
            padding: 0 $unit-size-small * 3;
        }

        &--divider {
            justify-content: flex-start;
            flex: 2;
            font-weight: $font-weight-normal;
            color: $color-text-lighter;

            @include breakpoint('m') {
                display: flex;
            }
        }

        &.is-active {
            display: flex;
            width: 100%;
            background-color: $color-white;
            box-shadow: $box-shadow-base;
        }

        &--highlighted {
            @include breakpoint('m') {
                background: $check-list-table-highlighted-column-title;
            }
        }
    }
}

.#{$ns}check-list-table-rows {
    background-color: $color-white;
    margin-top: calc(#{$unit-size-base * 5} - #{$unit-size-small});
    height: $unit-size-base * 32;
    overflow: auto;

    @include breakpoint('m') {
        margin-top: initial;
        height: initial;
        overflow: hidden;
    }

    &__description {
        flex: 2;
        padding: $unit-size-small * 3 0 $unit-size-small * 3 $unit-size-small * 3;

        @include breakpoint('m') {
            padding: $unit-size-small * 3;
        }
    }

    &__paragraph {
        font-size: $font-size-tiny;
        color: $color-text-lighter;
        margin-top: $unit-size-small;
    }

    &__value {
        flex: 1;
        padding: $unit-size-small * 3 0;
        display: none;
        justify-content: center;
        align-items: center;

        @include breakpoint('m') {
            display: flex;
            padding: $unit-size-small * 3;
        }

        &.is-active {
            display: flex;
        }

        &--highlighted {
            @include breakpoint('m') {
                background-color: $check-list-table-highlighted-row-value;
            }
        }
    }

    &__icon {
        &--light {
            color: $check-list-table-light-row-icon;
        }
    }
}
