@import 'variables';
@import 'mixins';

/***
  * Component / Icon
  */

.#{$ns}icon {
    position: relative;
    display: inline-flex;
    justify-content: center;
    align-content: center;

    &--elevate {
        box-shadow: $box-shadow-base;
    }

    &--small {
        font-size: $font-size-base;
    }

    &--tiny {
        font-size: $font-size-tiny;
    }

    &--micro {
        font-size: $font-size-micro;
    }

    &--big {
        font-size: $font-size-big;
    }

    &--fw {
        min-width: $unit-size-large;
        line-height: $unit-size-large;
    }

    &--hd {
        margin-top: 4px; // Fix to move icons under centerline
    }

    &--baseline::after {
        position: relative;
        top: 1px;
    }
}

.#{$ns}circle-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    font-size: $font-size-small;
    line-height: 1;

    &--size-default {
        height: 20px;
        width: 20px;
    }

    &--size-large {
        height: 30px;
        width: 30px;
    }

    &--success {
        background-color: $color-success;
        color: $color-white;
    }

    &--error {
        background-color: $color-error;
        color: $color-white;
    }

    &--info {
        background-color: $color-primary-base;
        color: $color-white;
    }

    &--warning {
        color: $color-black;
        background-color: $color-warning;
        border: none;
    }

    &--disabled {
        color: $color-white;
        background-color: $color-primary-disabled;
    }
}
