/***
  * Module / Partners Slider
  */

.#{$ns}partners-slider-nav {
    &--next {
        transform: translate(50%, -50%);
    }

    &--prev {
        transform: translate(-50%, -50%);
    }
}
