@import "variables";
@import "mixins";

/***
  * Module / Project Card
  */
.#{$ns}project-card {
    display: grid;

    &__body {
        display: flex;
        flex-direction: column;
        padding: ($unit-size-small * 2 + $unit-size-small * 0.5) $unit-size-small * 3 $unit-size-small * 3 $unit-size-small * 3;
        height: 100%;
        width: 100%;
    }

    &__title {
        font-size: $font-size-extra;
    }

    &__funded {
        font-size: $font-size-micro;
    }

    &__stamps {
        display: flex;
        position: absolute;
        transform: translateY(-50%);
        left: $unit-size-small * 3;
        right: $unit-size-small * 3;
    }

    &--horizontal &__tags {
        max-height: $unit-size-small * 9;
    }

    &--horizontal &__body {
        @include breakpoint('m') {
            padding: $unit-size-small * 6;
            justify-content: center;
        }
    }

    &__action-button {
        position: absolute;
        bottom: -$unit-size-small * 2;
        right: -$unit-size-small * 2;
        padding: $unit-size-small $unit-size-small * 3 $unit-size-small * 3 $unit-size-small;

        @include breakpoint('m') {
            bottom: -$unit-size-small * 3;
            right: -$unit-size-small * 3;
            padding: $unit-size-small $unit-size-small * 4 $unit-size-small * 4 $unit-size-small;
        }
    }

    &__progress-bar {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 100;
        border-radius: 0 0 $common-border-radius $common-border-radius;
    }
}


.#{$ns}project-flip-card__stamp {
    display: flex;
    position: absolute;
    transform: translateY(-50%);
    left: $unit-size-small * 3;
    right: $unit-size-small * 3;
}

.#{$ns}project-card-stamp {
    display: flex;
    background-color: $color-white;
    padding: $unit-size-small $unit-size-small * 2 $unit-size-small $unit-size-large;
    border-radius: $unit-size-large;
    box-shadow: $box-shadow-base;
    justify-content: center;
    align-items: center;
    color: $color-background-dark;
    line-height: 12px;
}

.#{$ns}project-card-follow {
    font-size: $font-size-tiny;
    font-weight: bold;
    min-height: $unit-size-small * 5;
    line-height: $unit-size-small * 5 * 0.5;

    &__icon {
        display: inline-flex;
        position: absolute;
        left: $unit-size-base;

        &--followed {
            animation: heart 1s cubic-bezier(0.17, 0.89, 0.32, 1.49);
        }
    }
}

@keyframes heart { 0%, 17.5% { font-size: 0; } }