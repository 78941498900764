@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Component / Fab
  */

.#{$ns}fab {
    width: $unit-size-base * 4;
    height: $unit-size-base * 4;
    font-size: $font-size-extra;
    border-radius: 50%;
    background-color: $color-background-light;
    display: flex;
    align-items: center;
    justify-content: center;

    &--accent {
        background-color: $color-accent-base;
        color: $color-text-inverse;
    }

    &--primary {
        background-color: $color-primary-base;
        color: $color-text-inverse;
    }

    &--warning {
        color: $color-text-base;
        background-color: $color-warning;
    }

    &--x3 {
        width: $unit-size-small * 3;
        height: $unit-size-small * 3;
        font-size: $unit-size-small * 3 * 0.5;
    }

    &--x4 {
        width: $unit-size-small * 4;
        height: $unit-size-small * 4;
        font-size: $unit-size-small * 4 * 0.5;
    }

    &--medium {
        width: $unit-size-large - math.div($unit-size-small, 3);
        height: $unit-size-large - math.div($unit-size-small, 3);
        font-size: $font-size-tiny;
    }
}
