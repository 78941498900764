@use 'variables';
@use 'mixins';

/***
  * Helpers / Flex
  */

$flex-basis-list: 1 5 6 10 15 20 21 25 30 35 40 45 50 55 60 65 70 75 80 85 90 95 100;

@each $fb in $flex-basis-list {
    @include mixins.percentage('_flexb', 'flex-basis', $fb);
}

@each $name, $br in $breakpoints {
    @include mixins.breakpoint($name) {
        @each $fb in $flex-basis-list {
            @include mixins.percentage('_flexb', 'flex-basis', $fb, '--' + $name);
        }

        .#{'_flexb'}#{'--not-' + $name} {
            #{'flex-basis'}: auto;
        }
    }
}

._flex {
    display: flex;
}

._flexinline {
    display: inline-flex;
}

._flexcol {
    display: flex;
    flex-direction: column;

    &--reverse {
        flex-direction: column-reverse;
    }
}

._flexrow {
    display: flex;
    flex-direction: row;
}

._flexbtwn {
    display: flex;
    justify-content: space-between;
}

._flexaround {
    display: flex;
    justify-content: space-around;
}

._flexcntr {
    display: flex;
    justify-content: center;
}

._flexaicntr {
    display: flex;
    align-items: center;
}

._flexwrap {
    display: flex;
    flex-wrap: wrap;
}

._flexwrap-reverse {
    display: flex;
    flex-wrap: wrap-reverse;
}

._grow-1 {
    flex-grow: 1;
}

._grow-0 {
    flex-grow: 0;
}

._shrink-0 {
    flex-shrink: 0;
}

._shrink-1 {
    flex-shrink: 1;
}

._ac-e {
    align-content: end;
}

._flex-none {
    flex: none;
}

// Flex direction
@include screen('_fd-c', 'flex-direction', column);
@include screen('_fd-r', 'flex-direction', row);

@each $name, $br in $breakpoints {
    @include mixins.breakpoint($name) {
        @include mixins.screen('_fd-c', 'flex-direction', column, '--' + $name);
        @include mixins.screen('_fd-r', 'flex-direction', row, '--' + $name);
    }
}

/***
  * Helpers / Flex align
  */

._ai-c {
    align-items: center;
}

._ai-b {
    align-items: baseline;
}

._ai-fs {
    align-items: flex-start;
}

._ai-fe {
    align-items: flex-end;
}

._ai-st {
    align-items: stretch;
}

._jc-c {
    justify-content: center;
}

._jc-sb {
    justify-content: space-between;
}

._jc-se {
    justify-content: space-evenly;
}

._jc-fe {
    justify-content: flex-end;
}

._jc-st {
    justify-content: stretch;
}

._jc-fs {
    justify-content: flex-start;
}

/***
  * Helpers / Flex self align
  */

._as-a {
    align-self: auto;
}

._as-fs {
    align-self: flex-start;
}

._as-fe {
    align-self: flex-end;
}

._as-st {
    align-self: stretch;
}

._js-st {
    justify-self: stretch;
}

._js-c {
    justify-self: center;
}

._js-s {
    justify-self: start;
}

._js-e {
    justify-self: end;
}

/***
  * Helpers / Grid align
  */

._ji-c {
    justify-items: center;
}

/***
  * Helpers / Floats
  */

._cf {
    @include clearfix;
}

._fl {
    float: left;
}

._fr {
    float: right;
}

._fc {
    clear: both;
}

/***
  * Helpers / Overflow
  */

._fo-y {
    overflow-y: hidden;
}

._fo {
    overflow: hidden;
}

._fov {
    overflow: visible !important;
}

._foa {
    overflow: auto;
}

._fos {
    overflow: scroll;
}

/***
  * Helpers / Positions
  */

._pos-r {
    position: relative;
}

._pos-sticky {
    position: sticky;
}

._pos-a {
    position: absolute;
}

._posl-0 {
    left: 0;
}

._posr-0 {
    right: 0;
}

._post-0 {
    top: 0;
}

._posb-0 {
    bottom: 0;
}

._pos-a--cntr {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/***
  * Helpers / Overflow
  */

._ov-h {
    overflow: hidden;
}

._ov-s {
    overflow: scroll;
}

._ov-xs {
    overflow-x: scroll;
}

._ov-ys {
    overflow-y: scroll;
}

/***
  * Helpers / Min width hack
  */

._mw-0 {
    min-width: 0;
}

._ha-right {
    @include horizontal-align('right');
}

._ha-left {
    @include horizontal-align('left');
}

._block {
    display: block;
}

._inline-block {
    display: inline-block;
}

._contents {
    display: contents;
}

._pwa-hidden {
    display: none;
}

._pwa-visible {
    display: block;
}

._h-lvh {
    height: 100vh;
}

/***
  * Helpers / Grid
  */

._grid {
    display: grid;
}

@each $name, $br in $breakpoints {
    @include breakpoint($name) {
        @each $fb in $flex-basis-list {
            @include mixins.percentage('_flexb', 'flex-basis', $fb, '--' + $name);
        }

        .#{'_flexb'}#{'--not-' + $name} {
            #{'flex-basis'}: auto;
        }
    }
}

$grid-cols: 24;

// Generate grid columns classes
@include grid-cols('', $grid-cols);

@each $name, $br in $breakpoints {
    @include breakpoint($name) {
        @include grid-cols('--' + $name, $grid-cols);
    }
}

._grid-cols-16-9{
    grid-template-columns: 16fr 9fr
}

// Generate grid item span classes
@include grid-item('', $grid-cols);

@each $name, $br in $breakpoints {
    @include breakpoint($name) {
        @include grid-item('--' + $name, $grid-cols);
    }
}

// Row height generation for a grid – this is specific to a masonry grid.
@include auto-rows;

/***
* Helpers / Orders
*/

@include order('', $grid-cols);

@each $name, $br in $breakpoints {
    @include breakpoint($name) {
        @include order('--' + $name, $grid-cols);
    }
}

/***
  * Helpers / Z-index
  */
@include z-index('', 10);

@each $name, $br in $breakpoints {
    @include breakpoint($name) {
        @include z-index('--' + $name, 10)
    }
}

/***
  * Helpers / PWA
  */
@media all and (display-mode: standalone) {
    ._pwa-hidden {
        display: none;
    }

    ._pwa-visible {
        display: block;
    }
}