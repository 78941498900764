@import 'variables';
@import 'mixins';

/***
  * Component / Stats Table
  */

.#{$ns}stats-table {
    &__value {
        font-weight: $font-weight-extrabold;
        font-size: $font-size-enormous;
    }
}
