@import 'variables';
@import 'mixins';

.#{$ns}comment-login {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    @include breakpoint('s') {
        flex-direction: row;
    }

    &__button {
        flex-shrink: 0;
        width: 100%;
        margin-top: $unit-size-base + $unit-size-small;

        @include breakpoint('s') {
            width: auto;
            margin-top: 0;
            margin-left: $unit-size-base;
        }
    }
}
