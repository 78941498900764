@import 'variables';
@import 'mixins';

/***
  * Component / Sidenav
  */

.#{$ns}sidenav {
    @include navbar-height('top');

    position: fixed;
    overflow-y: auto;
    z-index: $z-sidenav;
    height: $sidenav-height;
    background: $sidenav-bg-color-main;
    box-shadow: $box-shadow-base;
    padding: $unit-size-small * 3;

    &__wrapper,
    & {
        width: $sidenav-width-s;

        @include breakpoint('m') {
            width: $sidenav-width;
        }

        &.is-disabled {
            pointer-events: none;

            &::before {
                content: '';
                position: fixed;
                top: 0;
                left: 0;
                width: $sidenav-width-s;
                height: 100%;
                z-index: $z-floating-notification;
                background: $color-shadow-dark;

                @include breakpoint('m') {
                    width: $sidenav-width;
                }
            }
        }
    }

    &__heading {
        margin-top: $unit-size-small * 3;
        margin-bottom: $unit-size-small * 5;
    }

    &__item,
    &__subitem {
        position: relative;
        display: flex;
        align-items: center;
        border-radius: $container-border-radius-tight;
        background-color: $color-white;
        width: 100%;
        padding: ($unit-size-base - 3px) $unit-size-small;
        border: solid transparent;
        border-width: 3px 0;
        margin: ($unit-size-small * 0.5) 0;

        &--selected {
            color: $navbar-text-color-main;
            background-color: $color-primary-base;

            .#{$ns}sidenav-actions__item:after {
                color: $navbar-text-color-main;
            }
        }

        &--light {
            color: $navbar-text-color-secondary;
        }

        &--drag-up,
        &--drag-down {
            .#{$ns}sidenav-actions {
                pointer-events: none;
            }
        }

        &--drag-up {
            border-top-color: $color-text-accent;
        }

        &--drag-down {
            border-bottom-color: $color-text-accent;
        }

        &:hover {
            cursor: pointer;
            text-decoration: none;

            .#{$ns}sidenav__content {
                text-decoration: underline;
            }

            &.#{$ns}sidenav__trigger:before {
                text-decoration: none;
            }
        }

        &--no-fx {
            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }

        &--disabled {
            color: $color-text-gray;
            cursor: default;
            opacity: 0.75;

            &:hover {
                cursor: default;

                .#{$ns}sidenav__content {
                    text-decoration: none;
                }
            }
        }
    }

    &__link {
        display: inline-block;
        padding-left: $unit-size-small;
    }

    &__icon {
        display: inline-block;
        text-align: center;
        width: $unit-size-base * 3;

        &--inline {
            text-align: right;
            width: $unit-size-small * 9 + $unit-size-small * 0.5;
            padding-right: $unit-size-base;
        }
    }

    &__content {
        flex-grow: 1;

        @include wordbreak;

        &--no-icon {
            padding: 0 0 0 $unit-size-base * 3;
        }
    }

    &__trigger {
        &::before {
            content: '\f0da';
            font-family: $font-family-icons;
            text-align: center;
            min-width: $unit-size-base * 3;
        }

        &--expanded::before {
            content: '\f0d7';
        }

        &--puzzle-piece::before {
            content: '\f12e';
        }
    }

    &__collapsible {
        overflow: hidden;
        height: auto;
        max-height: 0;
        transition: max-height $transition-quick;
        margin-left: $unit-size-large;

        &:focus,
        &:active {
            outline: none;
        }

        &--expanded {
            overflow: visible;
            max-height: 9999px;
        }

        &:active,
        &:focus {
            outline: none;
        }
    }
}

.#{$ns}sidenav-actions {
    padding-left: $unit-size-small;
    min-width: $unit-size-large * 2;
    height: 100%;

    &--editable {
        min-width: $unit-size-small * 9;
        padding-left: $unit-size-small;
    }

    &__item {
        margin: 0 $unit-size-small*0.5;

        &::after {
            color: $color-text-lighter;
            opacity: 0.75;
        }

        &:hover {
            text-decoration: none;

            &::after {
                opacity: 1;
            }
        }

        &--draggable:hover {
            cursor: grab;
        }

        &--selected::after {
            opacity: 1;
        }
    }
}