@import 'variables';
@import 'mixins';

/***
  * Helpers / States
  */

.is-hidden {
    display: none;
}

.is-invisible {
    visibility: hidden;
}

._hover {
    ._hover-target {
        visibility: hidden;
    }

    &:hover {
        ._hover-target {
            visibility: visible;
        }
    }
}
