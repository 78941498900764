@import 'variables';
@import 'mixins';

/***
  * Module / Project Banner
  */

.#{$ns}project-banner {
    $c: &;

    position: relative;
    background-color: $color-background-darker;
    background-size: cover;

    @include breakpoint(xl) {
        box-shadow: $box-shadow-base;
        background: $color-background-lighter;
    }

    &--secondary {
        #{$c}__content-wrapper {
            background-color: $color-background-darker;
        }
    }

    &__content-wrapper {
        background: $color-background-dark;
        background-size: cover;
        background-position: center;
    }

    &__content {
        position: relative;

        @include layout;

        padding-top: $unit-size-small * 5;
        padding-bottom: $unit-size-small * 5;

        @include breakpoint(l) {
            min-height: $unit-size-large * 5;
        }

        &--narrow {
            @include layout('narrow');
        }
    }

    &__image {
        background: $color-background-lighter;
        border-radius: $unit-size-small * 0.5;
        padding: $unit-size-small;
        display: inline;
        height: 100%;

        @include breakpoint('m') {
            box-shadow: $box-shadow-base;
        }

        &-wrapper {
            border-radius: $unit-size-small * 0.5;
            display: none;

            @include breakpoint('m') {
                display: block;
            }
        }
    }

    &__title {
        display: inline-block;
        font-family: $font-family-decorative;
        font-size: $font-size-enormous;
        font-weight: $font-weight-normal;
        color: $color-accent-inverse;
        text-shadow: $text-shadow-blurred;
        line-height: $line-height-condensed;
        text-align: left;
    }

    &__title-annotation {
        line-height: $unit-size-small * 5;
        color: $color-text-inverse;
        font-size: $font-size-base;
        text-shadow: $text-shadow-blurred;
        font-family: $font-family-base;
        display: inline-block;
        position: relative;

        &--has-image {
            position: relative;
            bottom: $unit-size-small * 0.5;
        }

        &-image {
            width: $unit-size-small * 4;
            height: $unit-size-small * 4;
            margin-left: $unit-size-small * 0.5;
            margin-top: -$unit-size-small;
            padding: 0;
            border-radius: $common-border-radius;
            display: inline;
        }

        &-text {
            font-weight: $font-weight-extrabold;
            text-transform: none;
        }

        &:hover {
            opacity: 1;
            text-decoration: none;
        }
    }

    &__subtitle {
        color: $color-text-inverse;
        font-size: $font-size-base;
        text-shadow: $text-shadow-base;
        font-family: $font-family-base;
        text-transform: none;
        display: block;

        @include breakpoint(m) {
            font-size: $font-size-plus;
        }
    }

    &__description {
        margin-top: $unit-size-small * 1.5;
        color: $color-text-inverse;
        font-size: $font-size-base;
        text-shadow: $text-shadow-base;
        text-align: left;
    }

    &__box-wrapper {
        background: $color-white;
        position: relative;
        pointer-events: none;

        @include breakpoint(l) {
            background: none;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            width: 100%;
        }
    }

    &__box-wrapper-layout {
        @include layout;

        position: relative;
    }

    &__box {
        padding: $unit-size-base $unit-size-small * 3;
        text-align: center;
        background: $color-background-lighter;
        pointer-events: all;

        @include breakpoint(l) {
            background: none;
            text-align: left;
            margin-top: $unit-size-large;
            text-shadow: $text-shadow-base;
            color: $color-text-inverse;
        }
    }

    &-user-info {
        &__heading {
            font-family: $font-family-decorative;
            font-size: $font-size-big;
            font-weight: $font-weight-light;

            @include breakpoint(l) {
                color: $color-text-inverse;
            }
        }
    }

    &__field {
        &-label {
            font-size: $font-size-tiny;
            color: $color-text-lighter;
            text-align: left;
            text-transform: uppercase;
        }

        &-value {
            width: 100%;
            text-align: left;
            color: $color-text-dark;
            display: inline-block;
            font-size: $font-size-big;
            font-weight: $font-weight-extrabold;

            &--tags {
                padding-top: $unit-size-small * 0.5;
            }
        }
    }

    &__tooltip {
        display: inline-block;
    }
}

.#{$ns}project-banner-mobile {
    background-color: $color-background-darker;
    background-size: cover;
}

.#{$ns}project-banner-gallery {
    display: flex;
    padding-bottom: $unit-size-small * 7;
    flex-direction: column;

    @include breakpoint('xl') {
        flex-direction: row;
    }

    &__media {
        padding-bottom: $unit-size-small * 3;

        @include breakpoint('xl') {
            padding-bottom: 0;
            padding-right: $unit-size-small * 3;
        }
    }

    &__body {
        padding-top: $unit-size-small * 3;

        @include breakpoint('xl') {
            padding-top: 0;
            padding-left: $unit-size-small * 3;
        }
    }
}
