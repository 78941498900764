@import 'variables';
@import 'mixins';

.#{$ns}push-notification-list {
    max-height: 80vh;
    overflow-y: auto;
    position: relative;
}

.#{$ns}push-notification__image {
    max-width: 320px;
}