@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Component / Tags
  */

.#{$ns}tag {
    display: inline-block;
    margin: 0 0 $unit-size-small;
    padding: $unit-size-small*0.5 $unit-size-base;
    border-radius: $unit-size-large;
    font-size: $font-size-micro;
    font-family: $font-family-base;
    font-weight: $font-weight-bold;
    text-transform: none;
    color: $color-accent-base;
    border: 1px solid $color-accent-base;

    @include accent-colors;

    &--no-background {
        background-color: $color-transparent;
        border: 1px solid;
        color: $color-accent-base;
    }

    &--compact {
        padding: $unit-size-small*0.5 $unit-size-base;
    }

    &::after {
        margin-left: $unit-size-small;
    }

    &--has-icon {
        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        text-decoration: none;
    }
}

.#{$ns}tag-badge {
    cursor: pointer;
    color: $color-primary-base;
    font-weight: $font-weight-extrabold;
    background-color: $color-background-light;
    border-radius: $unit-size-large;
    padding: math.div($unit-size-small, 3) $unit-size-small + $unit-size-small * 0.5;
    font-size: $font-size-tiny;
    line-height: $unit-size-small + $unit-size-small * 0.5;
    user-select: none;
}

.#{$ns}tag-badge-close {
    margin-left: $unit-size-small * 0.5;
    color: $color-black;
    opacity: 0.5;
    padding: 0 $unit-size-small * 0.5;
}
