@import 'mixins';
@import 'variables';

.#{$ns}system-badge {
    position: fixed;
    bottom: -50px;
    left: -150px;
    text-transform: uppercase;
    transform: rotate(45deg);
    font-weight: bold;
    z-index: 9999;
    pointer-events: none;

    &--environment {
        opacity: 0.1;
        font-size: 50px;
    }

    &--test-mode {
        color: $color-text-primary;
        font-size: 64px;
        opacity: 0.3;
    }
}