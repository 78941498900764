/***
  * Helpers / Ratios
  */

._aspect-square {
    aspect-ratio: 1 / 1;
}

._aspect-video {
    aspect-ratio: 16 / 9;
}

._aspect-9-16 {
    aspect-ratio: 9 / 16;
}