@import 'variables';
@import 'mixins';

/***
  * Basic / Animation
  */

.#{$ns}animation-blink {
    animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

/***
* Basic / Animation
*/

.#{$ns}animation-shake {
    animation: shake 0.25s ease-in-out infinite;
}

@keyframes shake {
    25% {
        transform: rotate(-12deg);
    }

    37.5% {
        transform: rotate(-24deg);
    }

    50% {
        transform: rotate(-12deg);
    }

    62.5% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(12deg);
    }

    87.5% {
        transform: rotate(24deg);
    }

    100% {
        transform: rotate(12deg);
    }
}

@keyframes spinning {
    0% {
        transform: rotate(0deg);
        transform-origin: center center;
    }

    100% {
        transform: rotate(360deg);
        transform-origin: center center;
    }
}

@keyframes beat {
    0% {
        transform: scale(0);
    }

    25% {
        transform: scale(1.2);
    }

    50% {
        transform: scale(0.8);
    }

    75% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }
}

@keyframes background-swept {
    0% {
        background-position: -$unit-size-base * 24 0;
    }

    100% {
        background-position: $unit-size-base * 24 0;
    }
}

/***
* Basic / Fade Transition
*/

.#{$ns}fade-leave-active,
.#{$ns}fade-enter-active {
    transition: opacity $transition-timing-quick ease-in;
}

.#{$ns}fade-enter,
.#{$ns}fade-leave-to {
    opacity: 0;
}

/***
* Slide Up Animation
*/

.#{$ns}slide-up-leave-active,
.#{$ns}slide-up-enter-active {
    transition: transform $transition-timing-quick ease-in;
    transform: translateY(0);
}

.#{$ns}slide-up-enter,
.#{$ns}slide-up-leave-to {
    transform: translateY(100%);
}

/***
* Icon Click Animation
*/

@keyframes icon-animation-down {
    0% {
        transform: translateY(-50%) rotate(90deg);
    }

    50% {
        transform: translateY(-40%) rotate(90deg);
    }

    100% {
        transform: translateY(-50%) rotate(90deg);
    }
}

@keyframes icon-animation-up {
    0% {
        transform: translateY(-50%) rotate(90deg);
    }

    50% {
        transform: translateY(-40%) rotate(90deg);
    }

    100% {
        transform: translateY(-50%) rotate(90deg);
    }
}

@keyframes glow {
    0% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
    20% { box-shadow: 0 0 12px 0 rgb(219 88 132 / 0.38); }
    60% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
    80% { box-shadow: 0 0 12px 0 rgb(219 88 132 / 0.38); }
    100% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
}

@keyframes glow-urgent-lighter {
    0% { box-shadow: 0 0 10px rgb(255 120 170 / 0.3); }
    20% { box-shadow: 0 0 20px rgb(255 120 170 / 0.5); }
    60% { box-shadow: 0 0 12px rgb(255 120 170 / 0.35); }
    80% { box-shadow: 0 0 25px rgb(255 120 170 / 0.6); }
    100% { box-shadow: 0 0 10px rgb(255 120 170 / 0.3); }
}

@keyframes glow-success {
    0% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
    20% { box-shadow: 0 0 12px 0 rgb(27 170 100 / 0.38); }
    60% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
    80% { box-shadow: 0 0 12px 0 rgb(27 170 100 / 0.38); }
    100% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
}

@keyframes glow-warning {
    0% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
    20% { box-shadow: 0 0 12px 0 rgb(211 50 87 / 0.38); }
    60% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
    80% { box-shadow: 0 0 12px 0 rgb(211 50 87 / 0.38); }
    100% { box-shadow: 0 0 12px 0 rgb(0 0 0 / 0.12); }
}

@keyframes flash {
    0% {
        transform: translateX(-100%);
    }

    30% {
        transform: translateX(50%);
    }

    100% {
        transform: translateX(50%);
    }
}

/***
* Slider slide next animation
*/
.#{$ns}slide-next-group-move {
    z-index: 2;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-next-group-leave,
.#{$ns}slide-next-group-enter-to {
    opacity: 1;
    z-index: 2;
}

.#{$ns}slide-next-group-enter {
    opacity: 0;
    transform: translateX(100%) scale(0.5);
}

.#{$ns}slide-next-group-leave-to {
    opacity: 0;
    transform: translateX(-100%) scale(0.5);
}

.#{$ns}slide-next-group-enter-active {
    z-index: 1;
    right: 0;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-next-group-leave-active {
    position: absolute;
    z-index: 1;
    left: 0.01;
    transition: all $transition-timing-default ease;
}

/***
* Slider slide prev animation
*/
.#{$ns}slide-prev-group-move {
    z-index: 2;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-prev-group-leave,
.#{$ns}slide-prev-group-enter-to {
    opacity: 1;
    z-index: 2;
}

.#{$ns}slide-prev-group-enter {
    opacity: 0;
    transform: translateX(-100%) scale(0.5);
}

.#{$ns}slide-prev-group-leave-to {
    opacity: 0;
    transform: translateX(100%) scale(0.5);
}

.#{$ns}slide-prev-group-enter-active {
    left: 0;
    z-index: 1;
    transition: all $transition-timing-default ease;
}

.#{$ns}slide-prev-group-leave-active {
    position: absolute;
    right: 0;
    z-index: 1;
    transition: all $transition-timing-default ease;
}

.#{$ns}animation-pulse {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.#{$ns}animation-glow {
    animation: glow 5s infinite both;
}

.#{$ns}animation-glow--urgent-lighter {
    animation: glow-urgent-lighter 5s infinite both;
}

.#{$ns}animation-glow--success {
    animation: glow-success 5s infinite both;
}

.#{$ns}animation-glow--warning {
    animation: glow-warning 5s infinite both;
}

.#{$ns}background-swept {
    &::before {
        background-image: linear-gradient(to top, rgba($color-white, 0.6) 30%, rgba($color-white, 0) 100%);
    }

    &::after {
        content: '';
        position: absolute;
        inset: 0;
        background-image: linear-gradient(to right, rgba($color-white, 0) 30%, rgba($color-white, 0.5) 70%, rgba($color-white, 0) 100%);
        background-repeat: no-repeat;
        animation: background-swept 1s infinite linear forwards;
        pointer-events: none;
        border-radius: inherit;
    }
}


.#{$ns}flash {
    overflow: hidden !important;
    position: relative;
  }
  
.#{$ns}flash::after {
    position: absolute;
    content: '';
    height: 100%;
    width: 200%;
    top:0;
    left: 0;
    background: linear-gradient(
        -45deg, 
        transparent 0%,
        hsl(0deg 0% 100% / 0) 20%, 
        hsl(0deg 0% 100% / 0.3) 30%, 
        transparent 50%
    );
    border-radius: inherit;
    animation: flash 2.5s linear infinite both;
    pointer-events: none;
}