@import 'variables';
@import 'mixins';

// Todo: create generic hero banner component
.#{$ns}performance-marketing-hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: $unit-size-base * 41;
    padding: ($unit-size-base * 9) $layout-padding-m;
    background-repeat: no-repeat;
    background-color: $color-background-dark;
    background-size: contain;
    background-image:
        url('#{$image-path}performance-marketing/adfound-lp-cover-mob-up.svg'),
        url('#{$image-path}performance-marketing/adfound-lp-cover-mob-bot.svg');
    background-position: top center, bottom center;
    box-shadow: $box-shadow-base;
    color: $color-text-inverse;
    text-align: center;

    &__content {
        max-width: $unit-size-large * 19;
    }

    @include breakpoint('m') {
        background-image: 
            url('#{$image-path}performance-marketing/adfound-lp-cover-desk-l.svg'), 
            url('#{$image-path}performance-marketing/adfound-lp-cover-desk-r.svg');
        background-size: contain;
        background-position: center left, center right;
        padding: ($unit-size-base * 6) 0;
        min-height: $unit-size-base * 28;
    }
}
