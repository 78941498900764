@import 'variables';
@import 'mixins';

/***
  * Component / Available credits box
  */

.#{$ns}available-credits-box {
    display: flex;
    justify-content: space-between;
    color: $color-input-base;
}
