@import 'variables';
@import 'mixins';

.#{$ns}ranking-list {
    min-height: 135px;

    @include breakpoint('l') {
        min-height: 90px;
    }
}

.#{$ns}ranking-list-media {
    &__icon {
        position: absolute;
        right: $unit-size-small * 3;
        bottom: $unit-size-small * 2;

        @include breakpoint(m) {
            position: relative;
            right: initial;
            bottom: initial;
            padding: $unit-size-small * 2;
            min-width: $unit-size-small * 9;
            text-align: center;
        }
    }
}

.#{$ns}ranking-list-description {
    display: flex;
    flex-direction: column;
    gap: $unit-size-small * 2;

    @include breakpoint('m') {
        flex-direction: row;
        align-items: center;
        flex-grow: 1;
        justify-content: space-between;
    }

    &__values {
        text-align: left;

        @include breakpoint(m) {
            text-align: right;
        }
    }
}
