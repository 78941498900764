@import 'variables';
@import 'mixins';

/***
  * Skeletons / Bar
  */

.#{$ns}skeleton-bar {
    position: relative;
    background: $color-skeleton-base;
    border-radius: $unit-size-small * 0.5;
    height: $unit-size-small;
}
