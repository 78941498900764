@import 'variables';
@import 'mixins';

/***
  * Component / Poll
  */

.#{$ns}poll {
    position: relative;
    min-height: $unit-size-base * 14;

    &__title {
        padding-top: $unit-size-small * 3;
        margin-bottom: $unit-size-small * 3;

        @include breakpoint(l) {
            padding-top: 0;
        }
    }
}

/***
  * Component / Poll
  */

.#{$ns}poll-option {
    &:hover {
        text-decoration: none;
    }

    &__text {
        line-height: $unit-size-small * 4; // Same as radio
        display: flex;
        flex-direction: column;

        @include breakpoint('l') {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    &__radio {
        margin-top: 0;

        &.is-invisible {
            visibility: hidden;
        }
    }

    &.is-disabled,
    &.is-blocked,
    &.is-voted {
        cursor: default;
    }

    &.is-blocked {
        label > span { // Override field-toggle
            cursor: default;
        }
    }

    &.is-muted {
        opacity: 0.5;

        &:hover {
            opacity: 1;
            transition: opacity $transition-timing-quick;
        }
    }
}
