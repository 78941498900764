@import 'variables';
@import 'mixins';

.#{$ns}list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin: 0;
    padding: 0;
}

/***
  * Component / List link
  */

.#{$ns}list-item {
    display: flex;
    flex-direction: row;
    padding: $unit-size-base $unit-size-small * 3 $unit-size-base $unit-size-small * 3;
    line-height: $unit-size-small * 3;

    &.is-active {
        font-weight: $font-weight-bold;
        box-shadow: inset 2px 0 0 $color-accent-base;

        @include breakpoint('m') {
            box-shadow: inset 3px 0 0 $color-accent-base;
        }
    }

    &.is-inactive {
        opacity: 0.5;
    }

    &--elevate {
        box-shadow: $box-shadow-base;
    }
}

/***
  * Component / List subitem
  */

.#{$ns}list-subitem {
    position: relative;
    display: flex;
    flex-direction: row;
    padding: $unit-size-small ($unit-size-small * 3) $unit-size-small $unit-size-base * 3;
    line-height: $unit-size-small * 3;
    overflow: hidden;

    &.is-active {
        font-weight: $font-weight-bold;
    }

    &::before, &::after {
        position: absolute;
        text-decoration: underline; // IE & Edge fix
        color: $color-text-light;
    }

    &::after {
        @include fa-light;

        top: 100%;
        left: 10px;
        content: '\f141\f141\f141\f141\f141\f141\f141\f141\f141\f141\f141\f141\f141\f141'; // dots
        transform: rotate(-90deg);
        transform-origin: 0 0;
        letter-spacing: 1px;
        margin-top: 1px;
        width: $unit-size-small * 18;
        text-decoration: none;
        font-weight: normal;
        white-space: nowrap;
    }

    &:last-child::after {
        top: 50%;
    }

    &::before {
        @include fa-light;

        content: '\f141';
        left: 21px;
        top: 50%;
        bottom: 0;
        width: $unit-size-small * 3;
        line-height: $unit-size-small * 3;
        height: $unit-size-small * 3;
        margin-top: -($unit-size-small * 3) *0.5;
        text-decoration: none;
    }

    &--indent {
        padding-left: $unit-size-small * 9;

        &::before {
            display: none;
        }

        &:last-child::after {
            top: 100%;
        }
    }
}

.#{$ns}list-indicator {
    width: ($unit-size-small * 3) * 0.5;
    height: ($unit-size-small * 3) * 0.5;
    border-radius: 50%;
    background-color: $color-background-accent;
}
