@import 'variables';
@import 'mixins';

/***
  * Helpers / Screens
  */

$class-name: '_screen';
$props-name: 'display';

@include screen($class-name, $props-name, none, '--none');
@include screen($class-name, $props-name, block, '--all');

@each $name, $br in $breakpoints {
    @include breakpoint($name) {
        @include screen($class-name, $props-name, inline-flex, '--inline-flex-' + $name);
        @include screen($class-name, $props-name, flex, '--flex-' + $name);
        @include screen($class-name, $props-name, block, '--' + $name);
        @include screen($class-name, $props-name, none, '--not-' + $name);
    }
}