@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Helpers / Grid
  */

.#{$ns}grid {
    overflow: visible;
    margin: 0;
    padding: 0;
    list-style: none;

    @include clearfix;

    &--gutter, &--gutter-v2, &--gutter-columns {
        position: relative;
        margin-left: (-$grid-space-size-m*0.5);
        margin-right: (-$grid-space-size-m*0.5);

        @include breakpoint(l) {
            margin-left: (-$grid-space-size-l*0.5);
            margin-right: (-$grid-space-size-l*0.5);
        }

        @include breakpoint(xl) {
            margin-left: (-$grid-space-size-xl*0.5);
            margin-right: (-$grid-space-size-xl*0.5);
        }
    }

    &--gutter-v2 {
        margin-top: (-$grid-space-size-m*0.5);
        margin-bottom: (-$grid-space-size-m*0.5);

        @include breakpoint(l) {
            margin-top: (-$grid-space-size-l*0.5);
            margin-bottom: (-$grid-space-size-l*0.5);
        }

        @include breakpoint(xl) {
            margin-top: (-$grid-space-size-xl*0.5);
            margin-bottom: (-$grid-space-size-xl*0.5);
        }
    }

    &--narrow-columns {
        position: relative;
        margin-left: math.div(-$grid-space-size-m, 3);
        margin-right: math.div(-$grid-space-size-m, 3);

        @include breakpoint(l) {
            margin-left: math.div(-$grid-space-size-l, 3);
            margin-right: math.div(-$grid-space-size-l, 3);
        }

        @include breakpoint(xl) {
            margin-left: math.div(-$grid-space-size-xl, 3);
            margin-right: math.div(-$grid-space-size-xl, 3);
        }
    }

    &__cell {
        float: left;

        .#{$ns}grid--gutter > & {
            padding: $grid-space-size-m*0.5;

            @include breakpoint(l) {
                padding: $grid-space-size-l*0.5;
            }

            @include breakpoint(xl) {
                padding: $grid-space-size-xl*0.5;
            }
        }

        .#{$ns}grid--gutter-v2 > & {
            padding: $grid-space-size-m*0.5;

            @include breakpoint(l) {
                padding: $grid-space-size-l*0.5;
            }

            @include breakpoint(xl) {
                padding: $grid-space-size-xl*0.5;
            }
        }

        .#{$ns}grid--gutter-tight > & {
            padding: math.div($grid-space-size-m, 3) $grid-space-size-m*0.5;

            @include breakpoint(l) {
                padding: math.div($grid-space-size-l, 3) $grid-space-size-l*0.5;
            }

            @include breakpoint(xl) {
                padding: math.div($grid-space-size-xl, 3) $grid-space-size-xl*0.5;
            }
        }

        .#{$ns}grid--narrow-columns > & {
            padding: math.div($grid-space-size-m, 3);

            @include breakpoint(l) {
                padding: math.div($grid-space-size-l, 3);
            }

            @include breakpoint(xl) {
                padding: math.div($grid-space-size-xl, 3);
            }
        }

        .#{$ns}grid--small > & {
            padding: $unit-size-small*0.5;

            @include breakpoint(l) {
                padding: $unit-size-small*0.5;
            }

            @include breakpoint(xl) {
                padding: $unit-size-small*0.5;
            }
        }

        .#{$ns}grid--gutter-columns > & {
            padding-left: $grid-space-size-m*0.5;
            padding-right: $grid-space-size-m*0.5;

            @include breakpoint(l) {
                padding-left: $grid-space-size-l*0.5;
                padding-right: $grid-space-size-l*0.5;
            }

            @include breakpoint(xl) {
                padding-right: $grid-space-size-xl*0.5;
                padding-left: $grid-space-size-xl*0.5;
            }
        }
    }
}
