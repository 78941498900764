﻿@import 'variables';
@import 'mixins';

/***
  * Component / Price
  */

.#{$ns}price {
    color: $color-text-dark;
    font-family: $font-family-base;
    font-weight: $font-weight-extrabold;
    display: inline-block;
    margin: 0;

    &:hover {
        outline: none;
        text-decoration: inherit;
    }

    &--old {
        color: $color-text-light;

        *:not(.is-active) {
            text-decoration: line-through;
        }

        .is-active * {
            text-decoration: none;
        }

        &, &:hover {
            text-decoration: line-through;
        }
    }

    &--discounted {
        color: $color-accent-base;
    }

    &--extra {
        font-size: $font-size-extra;
    }

    &--big {
        font-size: $font-size-big;
    }

    &--tiny {
        font-size: $font-size-tiny;
    }

    &--text-base {
        color: $color-text-base;
    }
}
