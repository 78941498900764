@use "sass:math";
@import 'variables';
@import 'mixins';

$slider-arrow-width: math.div($unit-size-base * 5, 3);
$slider-arrow-width-circle: $unit-size-base * 3;
$slider-blur-width: $unit-size-large;
$slider-thin-offset: $unit-size-base * 4;
$slider-thin-offset-xl: $unit-size-base * 7;

.#{$ns}slider {
    position: relative;
    background: inherit;
    max-width: 100%;
    width: 100%;
    height: 100%;

    &__slides-wrapper {
        height: 100%;
        overflow-x: hidden;
        -ms-overflow-style: none !important;
        scrollbar-width: none !important;

        &--thin {
            margin: 0 $slider-thin-offset;

            @include breakpoint('xl') {
                margin: 0 $slider-thin-offset-xl;
            }
        }
    }

    &__controls {
        display: none;

        @include breakpoint('l') {
            display: flex;
            justify-content: flex-end;
        }
    }

    &__slides {
        height: 100%;
        display: flex;
        overflow: auto hidden;
        scrollbar-width: none !important;
        -ms-overflow-style: none;
        -webkit-overflow-scrolling: touch;
        scroll-snap-type: x mandatory;
        scroll-behavior: smooth;
        padding-bottom: $unit-size-base;

        &--negative-margin {
            margin-left: -$unit-size-small;
            margin-right: -$unit-size-small;
    
            @include breakpoint('m') {
                margin-left: -($unit-size-small + $unit-size-small * 0.5);
                margin-right: -($unit-size-small + $unit-size-small * 0.5);
            }
        }

        > * {
            scroll-snap-align: start;
        }

        &::-webkit-scrollbar-track {
            background: $color-background-base;
            margin-left: $unit-size-small*2;
            margin-right: $unit-size-small*2;
        }

        @include breakpoint('l') {
            &::-webkit-scrollbar {
                display: none;
                width: 0;
                height: 0;
            }

            &::-webkit-scrollbar-track {
                background: $color-transparent;
            }
        }

        &--visible {
            opacity: 1;
            visibility: visible;
        }

        &--center {
            justify-content: center;
        }

        &--no-scroll {
            overflow: hidden;
        }
    }

    &__arrow {
        position: initial;
        transform: none;
        visibility: hidden;
        transition: $transition-opacity;
        font-size: $font-size-big;
        color: $color-accent-base;
        z-index: 2;

        @include breakpoint('l') {
            display: inline-block;
            font-family: $font-family-icons;
            width: $slider-arrow-width;
        }

        &:hover {
            text-decoration: none;
        }

        &--visible {
            opacity: 1;
            visibility: visible;
        }

        &--prev {
            &::after {
                content: '\f053';
            }
        }

        &--next {
            &::after {
                content: '\f054';
            }
        }

        &--circle {
            width: $slider-arrow-width-circle;
            height: $slider-arrow-width-circle;
            border-radius: $unit-size-large;
            background: $color-white;
            box-shadow: $box-shadow-tight;
            text-align: center;

            &::after {
                line-height: $unit-size-base * 3;
            }
        }
    }

    &__overlay {
        position: absolute;
        top: 0;
        display: inline-block;
        width: $slider-blur-width;
        height: 100%;
        pointer-events: none;
        z-index: $z-base;
        opacity: 0;
        visibility: hidden;

        &--visible {
            opacity: 1;
            visibility: visible;
        }

        &--left {
            left: 0;
            background: linear-gradient(to right, $color-background-light 50%, $color-transparent 100%);
        }

        &--left#{&}--has-arrow {
            @include breakpoint('l') {
                left: $slider-arrow-width;
            }
        }

        &--left#{&}--fixed {
            transition: opacity ease-in-out 0.666s;
            background: linear-gradient(to right, $color-white 50%, $color-transparent 100%);
        }

        &--right {
            right: 0;
            background: linear-gradient(to left, $color-background-light 50%, $color-transparent 100%);
        }

        &--right#{&}--has-arrow {
            @include breakpoint('l') {
                left: - $slider-arrow-width;
            }
        }

        &--right#{&}--fixed {
            transition: opacity ease-in-out 0.666s;
            background: linear-gradient(to left, $color-white 50%, $color-transparent 100%);
        }

        &--offset#{&}--left {
            @include breakpoint('l') {
                left: $slider-thin-offset;
            }
        }

        &--offset#{&}--right {
            @include breakpoint('l') {
                left: - $slider-thin-offset + $unit-size-base;
            }
        }
    }
}

.#{$ns}base-slider {
    position: relative;
}

.#{$ns}base-slider-wrapper {
    position: relative;
    overflow: hidden;
}

.#{$ns}base-slider-slide {
    @include embed-fill;

    z-index: 0;
    opacity: 0;
    transition: opacity $transition-timing-default ease-in;

    &.is-active {
        opacity: 1;
        z-index: 1;
    }
}

.#{$ns}base-slider-nav {
    overflow: hidden;
    position: absolute;
    width: $unit-size-base * 3;
    height: $unit-size-base * 3;
    line-height: $unit-size-base * 3;
    border-radius: $unit-size-base * 3;
    color: $color-accent-base;
    background-color: $color-white;
    box-shadow: $box-shadow-base;
    text-align: center;
    transition: color $transition-quick;
    z-index: 2;

    &:hover {
        color: $color-text-accent-2;
        text-decoration: none;
    }

    &--primary {
        background-color: $color-primary-base;
        color: $color-white;

        &:hover {
            color: $color-accent-base;
        }
    }

    &--close {
        top: 0;
        right: 0;
        transform: translate(-25%, 25%);
        font-size: $unit-size-small * 3;

        @include breakpoint('m') {
            transform: translate(50%, -50%);
        }
    }

    &--next {
        top: 50%;
        right: 0;
        transform: translate(-25%, -50%);
        text-indent: 3px; // Center offset of angle icons.
        font-size: $unit-size-base * 3;

        @include breakpoint('m') {
            transform: translate(50%, -50%);
        }
    }

    &--prev {
        top: 50%;
        left: 0;
        transform: translate(25%, -50%);
        text-indent: -3px;
        font-size: $unit-size-base * 3;

        @include breakpoint('m') {
            transform: translate(-50%, -50%);
        }
    }

    &--zoom {
        top: 0;
        right: 0;
        transform: translate(-25%, 25%);
        font-size: $unit-size-small * 3;
    }
}

.#{$ns}base-slider-indicators {
    position: absolute;
    bottom: -$unit-size-base * 2;
    left: 50%;
    display: flex;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    transform: translate(-50%, 150%);
    max-width: 80vw;

    &__indicator {
        width: $unit-size-large;
        height: $unit-size-small * 0.5;
        background-color: rgb(0 0 0 / 0.1);
        border-radius: $unit-size-small * 0.5;
        margin: 0 1px;
        text-indent: -9999px;

        &.is-active {
            background-color: $color-accent-base;
        }
    }
}
