@import 'variables';
@import 'mixins';

/***
  * Module / Product add to pledge modal
  */

.#{$ns}product-details-info {
    display: flex;
    gap: $unit-size-small * 3;

    &--single-item {
        padding: $unit-size-base;
        border-radius: $common-border-radius;
        background-color: $color-background-light;

        @include breakpoint('m') {
            padding: $unit-size-base * 2 + $unit-size-small;
        }
    }
}

.#{$ns}product-set-item-wrapper {
    height: 100%;
    display: flex;
    gap: $unit-size-small * 3;
    color: $color-primary-dark;
    padding: $unit-size-small * 2;
    border-radius: $common-border-radius;
    background-color: $color-background-light;

    @include breakpoint('m') {
        align-items: center;
    }

    &__content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;

        &--middle {
            justify-content: center;

            @include breakpoint('m') {
                justify-content: initial;
            }
        }

        @include breakpoint('m') {
            flex-direction: row;
            align-items: center;
        }
    }
}

.#{$ns}product-set-item-details {
    font-size: $font-size-small;

    @include breakpoint('m') {
        margin-right: $unit-size-base;
        flex-basis: 40%;
    }
}
