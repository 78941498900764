@import 'variables';
@import 'mixins';

/***
  * Component / Notification boxes
  */

.#{$ns}floating-notification-box {
    z-index: $z-floating-notification;
    display: block;
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: $unit-size-large;
    background-color: $color-background-dark;
    box-shadow: $box-shadow-base;
    color: $color-white;

    &--fixed {
        position: fixed;
    }

    &--left {
        left: 0;
        right: unset;
    }

    &--right {
        left: unset;
        right: 0;
    }

    &__content {
        @include breakpoint('l') {
            padding-top: $unit-size-small*3;
        }
    }

    .#{$ns}link {
        padding: 0 $unit-size-base;
        display: inline-block;
        line-height: 1;
    }
}
