@import 'variables';
@import 'mixins';

/***
  * Module / Promo banner
  */

$animation-duration: 300ms;
$promo-banner-link-color: $color-text-inverse;
$promo-banner-text-shadow-color: #5073BD;

.#{$ns}promo-banner {
    position: relative;
    height: $unit-size-base * 10;
    z-index: $z-header + 1;
    display: flex;
    justify-content: center;

    &:hover {
        text-decoration: inherit;
    }

    &--closed {
        height: 0;
        min-height: 0;
        overflow: hidden;
        transition: height $animation-duration ease-in-out;
    }

    &__layout {
        color: $color-white;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        height: 100%;
        width: 100%;
        padding: $unit-size-base 0;
        max-width: 320px;

        @include breakpoint('m') {
            padding: 0;
            max-width: none;
        }

        @include breakpoint('xl') {
            @include layout();
        }
    }

    &__holder {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    &__head {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        @include breakpoint('m') {
            top: 0;
            left: 0;
            height: 148px;
            width: 238px;
        }

        @include breakpoint('xl') {
            top: 0;
            left: 0;
            height: 120px;
            width: 374px;
            z-index: 3;
        }
    }

    &__actions {
        max-width: $unit-size-small * 33;
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0;
        margin-top: $unit-size-small;
        justify-content: center;
        align-items: center;
        color: $color-white;

        @include breakpoint('m') {
            max-width: none;
            flex-direction: row-reverse;
            padding: 0 $unit-size-small * 6;
            justify-content: normal;
            min-height: $unit-size-small * 8;
        }

        @include breakpoint('xl') {
            max-width: $unit-size-large * 10;
            flex-direction: column;
            margin-top: 0;
            color: $color-white;
        }
    }

    &__content {
        z-index: 2;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        min-width: 190px;
        
        @include breakpoint('m') {
            text-align: right;
            width: 70%;
            padding: 0;
            margin-left: auto;
            align-items: flex-end;
        }

        @include breakpoint('xl') {
            text-align: center;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            width: calc(100% - 330px);
        }

        @include breakpoint('xl') {
            width: calc(100% - 510px);
            text-align: center;
            justify-content: flex-end;
        }
    }

    &__text-container {
        padding: 0 $unit-size-small;
        font-weight: $font-weight-extrabold;

        @include breakpoint('m') {
            padding: 0 $unit-size-small * 6 0 0;
        }

        @include breakpoint('xl') {
            padding: 0;
        }

        @include breakpoint('xl') {
            margin-right: $unit-size-small * 6;
        }
    }

    &__title {
        display: block;
        font-size: $font-size-extra;
        color: $color-white;
        font-weight: $font-weight-extrabold;

        @include breakpoint('m') {
            font-size: $font-size-super;
        }
    }

    &__link {
        color: $promo-banner-link-color;

        &:hover {
            color: $promo-banner-link-color;
        }
    }

    &__subtitle {
        display: block;
        color: $color-white;
        font-size: $font-size-base;
        font-weight: $font-weight-extrabold;
        text-shadow: $text-shadow-blurred;
    }

    &__title.text-stroke, &__subtitle.text-stroke, &__link.text-stroke {
        position: relative;
        background: transparent;
        z-index: 0;
    }

    &__link.text-stroke {
        display: block;
    }

    &__subtitle.text-stroke::before, &__title.text-stroke::before, &__link.text-stroke::before {
        content: attr(data-title);
        position: absolute;
        -webkit-text-stroke-width: 0.25em;
        left: 0;
        z-index: -1;
    }

    &__countdown {
        display: none;

        @include breakpoint('xl') {
            margin-top: $unit-size-base;
            display: block;
            font-size: $font-size-base;
            color: $color-white;
            font-weight: $font-weight-extrabold;
            text-shadow: $text-shadow-blurred;
        }
    }

    &__countdown-unit {
        opacity: 0.7;
        font-weight: $font-weight-normal;
    }

    &__withdraw-notification {
        height: $unit-size-small * 7;
    }

    &__link-close {
        display: inline-block;
        margin-top: $unit-size-small;
        text-shadow: $text-shadow-blurred;

        @include breakpoint('m') {
            margin-right: $unit-size-large;
            margin-top: 0;
        }

        @include breakpoint('xl') {
            margin-right: 0;
            margin-top: $unit-size-small;
        }
    }

    & &__btn {
        border-color: $color-white;

        &:hover {
            border-color: $color-white;
        }
    }
}

/***
  * Background
  */

.#{$ns}promo-banner-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-repeat: no-repeat;
    background-position: center;
}

/***
  * Underlay
  */

.#{$ns}promo-banner-underlay {
    background-size: contain;
    height: 100%;
    width: 100%;
}

/***
  * Packshot
  */

.#{$ns}promo-banner-packshot {
    height: 130px;
    width: 130px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;

    @include breakpoint('m') {
        width: 238px;
        height: 148px;
    }

    @include breakpoint('xl') {
        height: 146px;
        width: 374px;
    }
}

/***
  * Display
  */

.#{$ns}promo-banner-display {
    display: none;

    &--small {
        display: block;

        @include breakpoint('m') {
            display: none;
        }
    }

    &--medium {
        display: none;

        @include breakpoint('m') {
            display: block;
        }

        @include breakpoint('xl') {
            display: none;
        }
    }

    &--large {
        display: none;

        @include breakpoint('xl') {
            display: block;
        }
    }
}
