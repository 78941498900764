@import 'variables';
@import 'mixins';

/***
  * Components / Flex
  */

.#{$ns}flex__container {
    display: flex;
    flex-direction: row;

    &--vertical {
        flex-direction: column;
    }

    &--fill {
        width: 100%;
        height: 100%;
    }

    &--l {
        @include breakpoint('l') {
            display: flex;
            flex-direction: row;
        }
    }

    &--xl {
        @include breakpoint('xl') {
            display: flex;
            flex-direction: row;
        }
    }

    &--center {
        align-items: center;
    }
}

.#{$ns}flex__item {
    flex: 0 1 auto;

    &--grow {
        flex-grow: 1;
    }

    &--shrink {
        flex-shrink: 1;
    }
}
