@import 'variables';
@import 'mixins';

/***
  * Module / Creator Banner
  */

$creator-banner-height-slide: $unit-size-base * 19;
$creator-banner-height: $unit-size-base * 19;
$creator-banner-height-l: $unit-size-small * 50;
$creator-banner-content-width-l: $unit-size-large * 24;
$creator-banner-avatar-size: $unit-size-large * 4;

.#{$ns}creator-banner {
    position: relative;
    background: $color-background-dark;
    box-shadow: $box-shadow-base;
    color: $color-background-base;
    padding: $unit-size-large 0;

    @include breakpoint('m') {
        height: $creator-banner-height-l;
    }

    &__slide {
        @include embed-fill();

        background-repeat: repeat;
        background-position: 50% 50%;
        opacity: 0;
        transition: opacity $transition-default;

        &.is-active {
            opacity: 1;
        }
    }


    &__image {
        background: $color-background-lighter;
        border-radius: $unit-size-small * 0.5;
        padding: $unit-size-small;
        width: $creator-banner-avatar-size;
    }

    &__title {
        font-family: $font-family-decorative;
        font-size: $font-size-big;
        font-weight: $font-weight-normal;
        text-shadow: $text-shadow-blurred;
        text-transform: capitalize;
        line-height: $line-height-condensed;
    }

    &__link {
        padding-top: $unit-size-small * 3;
        flex-basis: 50%;

        @include breakpoint(l) {
            padding-top: $unit-size-base * 3;
        }

        &--left {
            text-align: left;
        }

        &--right {
            text-align: right;
        }

        &:only-child {
            text-align: center;
        }
    }
}
