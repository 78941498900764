@import 'variables';
@import 'mixins';

._bxsh {
    &--base {
        box-shadow: $box-shadow-base;
    }

    &--tight {
        box-shadow: $box-shadow-tight;
    }

    &--none {
        box-shadow: none;
    }
}
