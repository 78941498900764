@import 'variables';
@import 'mixins';

$z-index-button: 2;
$z-index-gradient: 1;

.#{$ns}section-expander {
    max-height: 520px;
    overflow: hidden;
    position: relative;
    margin: -$unit-size-half * 3;
    padding: $unit-size-half * 3;

    @include breakpoint('l') {
        max-height: 100%;
        height: 100%;
        overflow: visible;
    }

    &::before {
        content: '';
        width: 100%;
        position: absolute;
        bottom: 0%;
        z-index: $z-index-gradient;
        background: linear-gradient(180deg, rgba(242, 243, 248, 0.00) 1.74%, #F2F3F8 50%);
        height: 160px;
        margin-left: -$unit-size-half * 3;
        pointer-events: none;
    }

    &--tall {
        max-height: 620px;
    }

    &--expanded {
        max-height: 100%;
        &::before {
            opacity: 0;
            pointer-events: none;
        }
    }

    &__button {
        position: absolute;
        bottom: $unit-size-half * 3;
        width: calc(100% - $unit-size-small * 3);
        z-index: $z-index-button;
        transition: none;

        &--expanded {
            position: relative;
            margin-top: $unit-size-small * 2;
            width: 100%;
            transition: none;
            bottom: 0;
        }

        @include breakpoint('l') {
            display: none;
        }
    }
}