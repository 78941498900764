@import 'variables';
@import 'mixins';

/***
  * Skeletons / Charts
  */

  .#{$ns}skeleton-chart-line {
    stroke-width: $unit-size-half;
    stroke: $color-skeleton-light;
    fill: none;
  }