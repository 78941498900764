﻿@use "sass:math";

@import 'variables';
@import 'mixins';

/***
  * Basic / Scrollbar
  */

body * {
    -moz-scrollbar-color: $color-accent-base transparent;
    -moz-scrollbar-width: thin;

    &::-webkit-scrollbar {
        width: math.div($unit-size-base, 3);
        height: math.div($unit-size-base, 3);
        background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: math.div($unit-size-base, 6);
        background-color: $color-primary-disabled-light;
    }

    ::-webkit-scrollbar-button {
        display: none;
      }
}