@import 'variables';
@import 'mixins';

/***
  * Component / Tooltip
  */
.#{$ns}tooltip {
    position: fixed;
    top: 100%;
    left: 0;
    padding: $unit-size-base;
    outline: none;
    z-index: $z-modal + 1;

    &__balloon {
        position: relative;
        padding: $unit-size-small $unit-size-small*0.5*3;
        min-width: auto;
        transform: none;
        left: auto;
        right: auto;
        word-break: break-word;
        max-width: 60vw;

        @include breakpoint('s') {
            max-width: $unit-size-base * 22;
        }
    }
}

/***
  * Component / Tooltip transitions
  */
.#{$ns}tooltip-fade-enter-active {
    transition: opacity $transition-timing-quick ease-in;
}

.#{$ns}tooltip-fade-enter,
.#{$ns}tooltip-fade-leave-to {
    opacity: 0;
}
