@import 'variables';
@import 'mixins';

.#{$ns}project-subscription-management-settings {
    border-radius: $common-border-radius;
    padding: $unit-size-base;
    background-color: $color-background-light;
    position: relative;

    @include breakpoint('m') {
        padding: $unit-size-small * 5;
    }

    &__badges {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }

    &__title {
        font-size: $font-size-extra;
        margin-bottom: $unit-size-small * 3;
        font-weight: $font-weight-extrabold;
        color: $color-text-base;

        @include breakpoint('m') {
            margin-bottom: $unit-size-small * 5;
        }
    }
}
