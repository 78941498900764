@import 'variables';
@import 'mixins';

/***
  * Component / Forms
  */

.#{$ns}form {
    &__footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        gap: $unit-size-small * 2;
        margin-top: $unit-size-large;
    }
}

/***
  * Component / Forms
  */

.#{$ns}form-holder {
    position: relative;
}

/***
  * Component / Actions
  */

.#{$ns}form-actions {
    position: relative;
    display: flex;
    justify-content: space-between;
    bottom: 0;
    background-color: $color-white;
    align-items: center;
    padding: $unit-size-small * 4 $unit-size-small * 3;
    margin: 0 (-$unit-size-small * 2) (-$unit-size-small * 2);
    border-bottom-left-radius: $container-border-radius;
    border-bottom-right-radius: $container-border-radius;

    @include breakpoint('m') {
        padding: $unit-size-small * 4 $unit-size-small * 3;
        margin: 0 (-$unit-size-small * 3) (-$unit-size-small * 3);
    }

    @include breakpoint('l') {
        padding: $unit-size-small * 4 $unit-size-small * 5;
        margin: 0 (-$unit-size-small * 5) (-$unit-size-small * 5);
    }

    &.is-sticky {
        position: sticky;
    }

    &.is-stuck {
        box-shadow: $box-shadow-base;
        border-radius: 0;
    }
}

.#{$ns}form-actions-trigger {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}