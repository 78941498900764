@import 'variables';
@import 'mixins';

/***
  * Module / featured Header
  */
.#{$ns}featured-header {
    position: relative;
    padding-top: $unit-size-small * 4;
    padding-bottom: $unit-size-small * 20;
    margin-bottom: -($unit-size-small * 18);
    color: $color-white;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint('m') {
        padding-top: $unit-size-small * 7;
        padding-bottom: $unit-size-small * 22;
    }

    &__title {
        max-width: 90%;
        font-size: $font-size-super;

        @include breakpoint('m') {
            max-width: none;
            font-size: $font-size-enormous;
        }
    }

    &--primary {
        background-color: #5968df;
        background-image: url($image-path + 'featured/header-primary-s.svg');

        @include breakpoint('m') {
            background-image: url($image-path + 'featured/header-primary-m.svg');
        }
    }

    &--secondary {
        background-color: #8771c6;
        background-image: url($image-path + 'featured/header-secondary-s.svg');

        @include breakpoint('m') {
            background-image: url($image-path + 'featured/header-secondary-m.svg');
        }
    }

    &--accent {
        background-color: $color-accent-base;
        background-image: url($image-path + 'featured/header-accent-s.svg');

        @include breakpoint('m') {
            background-image: url($image-path + 'featured/header-accent-m.svg');
        }
    }

    &--teal {
        background-color: #54D1D1;
        background-image: url($image-path + 'featured/header-teal-s.svg');

        @include breakpoint('m') {
            background-image: url($image-path + 'featured/header-teal-m.svg');
        }
    }
}

/***
  * Module / featured Header
  */

.#{$ns}category-card-projects {
    $gap: $unit-size-small * 0.5;

    display: flex;
    position: relative;

    > * {
        margin: $gap;
        width: calc(20% - #{$gap * 2});
        border-radius: $input-field-border-radius;
        overflow: hidden;
    }
}
