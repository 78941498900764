@import 'variables';
@import 'mixins';

.#{$ns}top-navigation-bar {
    width: 100%;
    z-index: $z-admin-nav;
    background-color: $color-white;
    border-bottom: 1px solid rgb(242 243 248 / 1);
    display: flex;
    justify-content: space-between;
}