@import 'variables';
@import 'mixins';

/***
  * Helpers / Cursors
  */

._cur-p {
    cursor: pointer;
}

._cur-d {
    cursor: default;
}

._cur-grab {
    cursor: grab;
}

._cur-grabbing {
    cursor: grabbing;
}

._pointer-events-none {
    pointer-events: none;
}