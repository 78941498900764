@import 'variables';
@import 'mixins';

/***
  * Component / Media
  */

.#{$ns}media {
    display: flex;
    align-items: flex-start;

    &__body {
        flex: 1;
    }

    &--middle {
        align-items: center;
    }
}
