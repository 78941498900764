@import 'variables';
@import 'mixins';

.#{$ns}value-indicator {
    border-top: solid;
    border-width: 2px;
    border-color: $color-background-light;
    padding: $unit-size-base $unit-size-small 0 $unit-size-small;

    &__title {
        color: $color-text-gray;
        font-size: $font-size-micro;
        text-transform: uppercase;

        @include breakpoint(xl) {
            font-size: $font-size-tiny;
        }
    }

    &__value {
        color: $color-text-dark;
        font-size: $font-size-base;
        font-weight: $font-weight-extrabold;

        @include breakpoint(xl) {
            font-size: $font-size-big;
        }
    }
}