@import 'variables';
@import 'mixins';

/***
  * Module / Contact Form
  */

.#{$ns}contact-form {
    &__actions {
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;

        @include breakpoint(m) {
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            text-align: right;
        }
    }

    &__submit {
        width: 100%;
        margin-bottom: $unit-size-small * 1;

        @include breakpoint(m) {
            width: inherit;
            margin-bottom: 0;
            margin-left: $unit-size-small * 2;
        }
    }

    &__back-navigation {
        display: inline-flex;
        color: $color-accent-base;
        font-size: $font-size-base;
        user-select: none;
        cursor: pointer;

        &--bottom {
            margin: $unit-size-small * 3 0 0;
        }

        &--top {
            @include breakpoint(s) {
                margin-bottom: $unit-size-small * 3;
            }

            margin: 0 0 $unit-size-small * 3 0;
        }
    }
}

.#{$ns}contact-more {
    padding-top: $unit-size-small;
    padding-bottom: $unit-size-small;
    box-shadow: $box-shadow-tight;
}
