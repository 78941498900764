@import 'variables';
@import 'mixins';

.#{$ns}recommendations {
    &__slides-wrapper {
        margin: 0 (-$unit-size-base);

        @include breakpoint('m') {
            margin: 0 (-$unit-size-small * 2);
        }

        @include breakpoint('l') {
            margin: 0 (-$unit-size-small * 3);
        }
    }
}