@import 'variables';
@import 'mixins';

/***
  * Module / Creator Statistics
  */

.#{$ns}creator-testimonial {
    background-color: $color-primary-base;
    color: $color-text-inverse;
    display: flex;
    flex-direction: column;
    border-radius: $common-border-radius;
    padding: $unit-size-large * 2 0;

    @include breakpoint('m') {
        flex-direction: row;
        justify-content: center;
    }

    &__content {
        color: $color-white;
        text-align: left;
        font-size: $font-size-base;
        padding: ($unit-size-small * 4) $unit-size-large 0 $unit-size-large;

        @include breakpoint('m') {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 60%;
            padding: 0 $unit-size-large 0 0;
        }

        &--narrow {
            @include breakpoint('m') {
                padding: 0 ($unit-size-base * 6) 0 0;
            }
        }
    }

    &__side {
        display: flex;
        align-items: center;
        padding: 0 $unit-size-small * 5;
        margin-top: $unit-size-base;

        @include breakpoint('m') {
            justify-content: center;
            margin-top: 0;
            width: 40%;
            flex-direction: column;
            text-align: center;
        }
    }

    &__image {
        max-height: $unit-size-small * 8;
        max-width: 50%;
        margin-right: $unit-size-small * 2;

        @include breakpoint('m') {
            margin-right: 0;
            max-height: $unit-size-small * 30;
            max-width: 100%;
            margin-bottom: $unit-size-small * 2;
        }
    }
}

.#{$ns}become-creator-hero {
    display: flex;
    align-items: center;
    flex-direction: column;
    min-height: $unit-size-small * 64;
    padding: ($unit-size-base * 5) 0;
    background-repeat: no-repeat;
    background-color: $color-background-darker;
    background-image: url('#{$image-path}/become-a-creator/cover-s.svg?v=2');
    background-position: 50% 100%;
    background-size: cover;
    box-shadow: $box-shadow-base;
    color: $color-text-inverse;
    text-align: center;

    &__content {
        max-width: $unit-size-large * 19;
    }

    @include breakpoint(m) {
        background-image:
            url('#{$image-path}/become-a-creator/cover-rocket-l.svg'),
            url('#{$image-path}/become-a-creator/cover-l.svg?v=2');
        background-position: 95% 40px, 50% 50%;
        background-repeat: no-repeat, repeat;
        background-size: auto;
    }
}

.#{$ns}become-creator-values {
    background-color: $color-white;
}

.#{$ns}become-creator-values-title {
    max-width: 90%;
    margin: 0 auto;
    min-height: $line-height-base * 3 * $font-size-big;
    display: flex;
    align-items: center;
    justify-content: center;

    @include breakpoint('m') {
        min-height: $line-height-base * 2 * $font-size-big;
    }

    @include breakpoint('l') {
        min-height: none;
    }
}

.#{$ns}become-creator-stats {
    padding: $unit-size-small * 2 0;
    background-repeat: no-repeat;

    @include breakpoint('m') {
        padding: $unit-size-small * 3;
    }

    &--accent {
        background-image: 
        url('#{$image-path}/become-a-creator/become_a_creator_bg_mob.svg');
        background-size: cover;
        color: $color-text-inverse;

        @include breakpoint('m') {
            background-image: 
            url('#{$image-path}/become-a-creator/become_a_creator_bg_desk.svg');
            background-position: 0 0;
        }
    }
}

.#{$ns}become-creator-values-wrapper {
    padding: $unit-size-small * 4;

    @include breakpoint('l') {
        padding: $unit-size-small * 6;
    
    }
}

.#{$ns}become-creator-divider {
    padding: $unit-size-small * 2;

    @include breakpoint('l') {
        padding: $unit-size-small * 3;
    }

    &::before {
        content: '';
        display: block;
        height: 3px;
        width: $unit-size-small * 4;
        color: inherit;
        background-color: currentColor;
        opacity: 0.5;
        margin: 0 auto;
    }

    &--solid::before {
        color: $color-accent-base;
        opacity: 1;
    }
};

.#{$ns}become-creator-value {
    font-size: $font-size-extra;
    margin: 0;
    margin-top: $unit-size-small * 4;
    font-weight: $font-weight-extrabold;

    @include breakpoint('l') {
        font-size: $font-size-big;
        margin-top: $unit-size-small * 5;
    }
}