@use "sass:math";
@import 'variables';
@import 'mixins';

/***
  * Component / Balloon
  */

$arrow-border-width: (
    $unit-size-small + math.div($unit-size-small * 2, 3)
);

.#{$ns}balloon {
    $c: &;

    line-height: $line-height-base;
    text-align: left;
    font-weight: $font-weight-normal;
    background-color: $color-white;
    border-radius: $common-border-radius;
    filter: drop-shadow($drop-shadow-base);
    z-index: $z-nav + 2;
    max-width: $grid-column-size-xl * 5 + $grid-space-size-xl * 4;
    min-width: $grid-column-size-xl * 3 + $grid-space-size-xl * 2;
    outline: none;
    overflow: hidden;

    @media screen and (-ms-high-contrast: active),
    screen and (-ms-high-contrast: none) {
        box-shadow: $box-shadow-base;
    }

    &:active,
    &:focus {
        outline: none;
    }

    &::before {
        content: "";
        bottom: 100%;
        right: $unit-size-small * 7;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border: $arrow-border-width solid transparent;
    }

    &.is-hidden {
        display: block;
        opacity: 0;
        pointer-events: none;
    }

    &.is-open {
        opacity: 1;
        pointer-events: auto;
    }

    &--center {
        transform: translate(-50%, -$unit-size-base);

        &::before {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    &--right {
        &::before {
            right: $unit-size-small;
        }
    }

    &--left {
        left: calc(50% - #{$unit-size-small} - #{$arrow-border-width});

        &::before {
            left: $unit-size-small;
        }
    }

    &--no-header {
        &::before {
            border-bottom-color: $color-white;
        }

        &.#{$ns}balloon--up {
            &::before {
                border-top-color: $color-white;
            }
        }
    }

    &--no-arrow {
        &::before {
            display: none;
        }
    }

    &--up {
        bottom: 100%;
        top: auto;

        &::before {
            top: 100%;
            bottom: auto;
            border-bottom-width: 0;
            border-top-width: $arrow-border-width;
        }
    }

    &--down {
        bottom: auto;
        top: 100%;

        &::before {
            border-top-width: 0;
            border-bottom-width: $arrow-border-width;
        }
    }

    &--inverse {
        background-color: $color-primary-dark;
        color: $color-white;

        &>#{$c}__content {
            color: $color-white;
        }

        &.#{$ns}balloon--no-header::before {
            border-bottom-color: $color-primary-dark;
        }

        &.#{$ns}balloon--up:before {
            border-top-color: $color-primary-dark;
        }

        &.#{$ns}balloon--down:before {
            border-bottom-color: $color-primary-dark;
        }
    }

    &--error {
        background-color: $color-error;
        color: $color-white;
        font-weight: $font-weight-normal;

        &.#{$ns}balloon--up:before {
            border-top-color: $color-error;
        }

        &.#{$ns}balloon--down:before {
            border-bottom-color: $color-error;
        }
    }

    &--fixed {
        position: fixed;
        top: unset;
        bottom: unset;
    }

    &__header {
        padding: $unit-size-small*3 $unit-size-large;
        background-color: $color-primary-dark;
        min-height: $unit-size-large *2;
        color: $color-white;
        font-family: $font-family-soft;
        font-size: $font-size-base;
        border-radius: $common-border-radius $common-border-radius 0 0;
    }

    &__top-title {
        font-weight: $font-weight-extrabold;
        text-transform: capitalize;
    }

    &__top-alert {
        font-weight: $font-weight-bold;
        text-align: center;
        color: $color-text-accent;
        margin-bottom: $unit-size-small;
        text-transform: uppercase;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: $unit-size-small;
        padding: $unit-size-small*3 $unit-size-large;
        color: $color-text-dark;
    }

    &__content-item {
        padding-top: $unit-size-base;

        &:first-child {
            padding-top: 0;
        }
    }

    &__content-item-title {
        font-size: $font-size-base;
        font-weight: $font-weight-extrabold;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 100%;

        @include wordbreak;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        white-space: normal;

        &:hover {
            text-decoration: underline;
        }
    }

    &__content-item-desc {
        font-family: $font-family-soft;
        font-size: $font-size-micro;
    }

    &__content-icon {
        position: absolute;
        top: 50%;
        left: 0;
        font-size: $font-size-big;
        color: $color-accent-base;
        transform: translateY(-50%);
    }

    &__content-link {
        position: relative;
        display: flex;
        align-items: center;
        padding: $unit-size-small 0 $unit-size-small 6*$unit-size-small;
        font-size: $font-size-base;
        font-weight: $font-weight-bold;

        &:hover {
            cursor: pointer;
            text-decoration: underline;
        }

        &--disabled {
            opacity: 0.5;
            pointer-events: none;

            &:hover {
                cursor: default;
                text-decoration: none;
            }
        }
    }

    &__actions {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: $unit-size-small * 3;
        padding: $unit-size-small * 3 0;
    }

    &__wrapper {
        position: relative;
        overflow: visible;
        display: inline-block;
        flex: initial;
    }

    &__dropdown {
        position: absolute;
        right: 0;
        top: $unit-size-base*3;

        &-wrapper {
            position: relative;
            display: inline-block;
        }
    }

    &--compact {
        width: 100%;
        width: auto;
        max-width: $grid-column-size-xl * 2 + $grid-space-size-xl * 2 !important;
        min-width: $grid-column-size-xl * 2 + $grid-space-size-xl * 2 !important;
    }

    &--narrow {
        width: 100%;

        @include breakpoint('s') {
            width: auto;
            max-width: $grid-column-size-xl + $grid-space-size-xl * 2 !important;
            min-width: $grid-column-size-xl + $grid-space-size-xl * 2 !important;
        }
    }

    &--wide {
        width: 100%;

        @include breakpoint('s') {
            width: auto;
            max-width: $grid-column-size-xl * 6 + $grid-space-size-xl * 5 !important;
            min-width: $grid-column-size-xl * 4 + $grid-space-size-xl * 3 !important;
        }
    }

    &--absolute-center {
        position: absolute;
        z-index: 5;
        top: 100%;
        left: 50%;
        transform: translate(-50%, $unit-size-base);
    }

    &--absolute-right {
        position: absolute;
        z-index: 5;
        top: 100%;
        right: 0;
        transform: translate(0, $unit-size-base);
    }
}