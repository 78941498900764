@import 'variables';
@import 'mixins';

/***
  * Module / Newsletter banner
  */

.#{$ns}newsletter-banner-action {
    display: flex;
    justify-content: center;

    @include breakpoint('m') {
        justify-content: left;
    }
}

.#{$ns}newsletter-banner-image {
    height: 228px;
    width: 296px;
}
