@import 'variables';
@import 'mixins';

/***
  * Component / Scrollable
  */

  $z-index-gradient: 1;
  $gradient-color: rgb(242 243 248 / 0.00);

.#{$ns}scrollable {
    display: flex;
    margin-left: -$unit-size-small * 2;
    margin-right: -$unit-size-small * 2;

    @include breakpoint('m') {
        margin-left: -$unit-size-small * 4;
        margin-right: -$unit-size-small * 4;
    }

    @include breakpoint('l') {
        margin-left: -$unit-size-small * 3;
        margin-right: -$unit-size-small * 3;
    }

    @include breakpoint('l', 'max-width') {
        position: relative;
        overflow: auto hidden;
        scroll-snap-type: x mandatory;
        scroll-snap-align: start;
        padding-bottom: $unit-size-small;

        &::-webkit-scrollbar-track {
            background: rgb(255 255 255 / 0.5);
            margin-left: $unit-size-small*2;
            margin-right: $unit-size-small*2;
        }

        > * {
            flex: 0 0 auto;
            scroll-snap-align: start;
        }
    }
}

.#{$ns}scroll-container {
    position: relative;
    overflow: auto hidden;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
    will-change: auto;
    transform: translate3d(0, 0, 0);

    *::-webkit-scrollbar {
        width: $unit-size-small*2;
    }
    
    &::-webkit-scrollbar-track {
        background: transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background: transparent;
    }

    > * {
        flex: 0 0 auto;
        scroll-snap-align: start;
    } 
    
    &.is-busy {
        pointer-events: none;
    }
}

.#{$ns}scroll-section {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 (-$unit-size-small * 2);
    padding: 0 $unit-size-small * 2;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 12px;
        background-image: linear-gradient(90deg, rgb(242 243 248 / 0) 0%, $color-background-light 50%);
    }
}

.#{$ns}scrollable-wrapper {
    @include breakpoint('l') {
        height: 100%;
    }
  }

.#{$ns}scrollable-content {
        position: relative;
        width: 100%;
        flex-grow: 1;
        height: 100%;
}

.#{$ns}scrollable-overlay {
    &::before, &::after {
        @include breakpoint('l') {
            content: none;

            // 110% to cover the whole shadows etc.
            width: 110%;
            position: absolute;
            height: $unit-size-small * 5;
            z-index: $z-index-gradient;
            left: 50%;
            transform: translateX(-50%);
            pointer-events: none;
        }
    }

    &--top {
        @include breakpoint('l') {
            &::before {
                content: '';
                top: -$unit-size-small * 3 * 0.5; 
                background: linear-gradient(0deg, $gradient-color 1.74%, $color-background-light 50%);
            }
        }
    }

    &--bottom {
        @include breakpoint('l') {
            &::after {
                content: '';
                bottom: -$unit-size-small * 3 * 0.5;
                background: linear-gradient(180deg, $gradient-color 1.74%, $color-background-light 50%);
            }
        }
    }
}

.#{$ns}scrollable-content-vertical {
    @include breakpoint('l') {
        position: absolute;
        inset: 0;
        overflow-y: scroll;
        margin: -($unit-size-small * 3) * 0.5;
        padding: ($unit-size-small * 3) * 0.5;
    }
}

.#{$ns}scrollable-content-inner {
    overflow: hidden;
    margin: -($unit-size-small * 3) * 0.5;
    padding: ($unit-size-small * 3) * 0.5;
}

.#{$ns}scrollable-content-horizontal {
    position: relative;
    overflow: auto hidden;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    scroll-snap-type: x mandatory;
    scroll-snap-align: start;
}

.#{$ns}scrollable-item {
    scroll-snap-align: start;
}
