@import 'variables';
@import 'mixins';
@import 'mixins.product-cards';

/***
  * Module / Mini wizard
  */

@mixin pledge-summary {
    width: 100%;
    background-color: $color-accent-base;
    position: relative;
    overflow: hidden;
    color: $color-text-inverse;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 0;
    flex-shrink: 0;

    &--image {
        &::before {
            content: '';
            position: absolute;
            top: -16px;
            right: -16px;
            opacity: 0.5;
            width: $unit-size-small * 10;
            height: $unit-size-small * 10;
            transform: rotate(15deg);
            background-image: url($image-path + 'projects/summary-stamp.png');
            background-repeat: no-repeat;
            background-position: 50%;
        }
    }

    &--alt-background {
        background-color: $color-primary-base;
    }
}

.#{$ns}project-mini-wizard-wrapper {
    @include layout-space(padding-right);

    width: 20%;
    height: 100%;
    top: 0;
    right: 0;
    padding: $unit-size-base * 3 0;
    max-width: $grid-column-size-xl * 5;
    display: block;
    position: absolute;

    @include breakpoint(xl) {
        display: block;
    }
}

.#{$ns}project-mini-wizard {
    position: sticky;
    padding: 0;
    z-index: $z-mini-wizard;
    max-height: calc(100vh - #{$unit-size-small * 25}); // navbar height 78px (13 * 6px) + 2 * 36px margin (2 * 6 * 6px)
    display: flex;
    flex-direction: column;

    &--web {
        @include navbar-height(top, $unit-size-base * 3);
    }

    &--pwa {
        @include navbar-height(top, $unit-size-base * 7);
    }

    &__header {
        padding: $unit-size-small * 3;

        @include pledge-summary;
    }

    &__paragraph {
        text-align: center;
        font-size: $font-size-tiny;
        color: $color-text-gray;
    }

    &__caption {
        text-align: right;
        font-size: $font-size-tiny;
        color: $color-text-lighter;
        display: inline-block;

        @include breakpoint(xl) {
            display: block;
        }
    }

    &__content {
        overflow-y: auto;
        flex-grow: 1;

        &.is-overflowing {
            +.#{$ns}project-mini-wizard__footer {
                padding-top: $unit-size-small * 3;

                &::before {
                    visibility: visible;
                }
            }
        }
    }

    &__collapse {
        position: relative;
        padding-left: $unit-size-small * 5;
        user-select: none;
        cursor: pointer;
        margin-top: $unit-size-base;
    }

    &__collapse-icon {
        width: $unit-size-small * 5;

        @include collapsible-icon {
            color: $color-white;
            left: $unit-size-small;
        }
    }

    &__footer {
        flex-grow: 0;
        flex-shrink: 0;
        position: relative;
        padding: 0 $unit-size-small * 3 $unit-size-small * 3;
    }

    &__price {
        color: $color-text-base;
        font-size: $font-size-plus;
        font-weight: $font-weight-extrabold;
        white-space: nowrap;
        display: inline;
    }
}

.#{$ns}project-mini-wizard-expandable {
    margin-top: $unit-size-base;
    width: #{'min(100%, #{$grid-column-size-xl * 10})'};
 justify-content: space-between;
    align-self: center;
    display: flex;

    &__cta {
        min-width: $unit-size-small * 23;
    }

    &__expand-button-container {
        position: absolute;
        top: 0;
        margin-left: auto;
        margin-right: auto;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        height: $unit-size-small * 7;
        display: flex;
        align-items: center;
    }

    &__expand-button {
        background-color: $color-background-accent;
        color: $color-text-inverse;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    &__shadow {
        width: 100%;
        height: 4px;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background: linear-gradient(180deg, rgb(0 0 0 / 0.12) 0%, rgb(0 0 0 / 0) 100%);
        visibility: hidden;
    }

    &__content-wrapper {
        position: relative;
        width: #{'min(100%, #{$grid-column-size-xl * 10})'};

    }

    &__content {
        max-height: 50vh;
        width: 100%;
        background-color: $color-background-lighter;
        bottom: 0;
        overflow-y: auto;
        box-shadow: $drop-shadow-base;
        padding: $unit-size-base;

        &.is-overflowing {
            & .#{$ns}project-mini-wizard-expandable__shadow {
                visibility: visible;
            }
        }
    }

    &__summary {
        @include pledge-summary;
    }

    &__pledge {
        margin-top: $unit-size-base;
    }
}