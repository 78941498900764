@import 'variables';
@import 'mixins';

.#{$ns}project-rankings {
    display: flex;
    flex-direction: column;
    margin-bottom: $unit-size-small * 3;

    @include breakpoint('m') {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
        margin-bottom: $unit-size-small * 3;
        @include breakpoint('l') {
            margin-bottom: 0;
        }
    }
}

.#{$ns}project-rankings-selectors {
    display: flex;
    gap: $unit-size-small;
    margin-bottom: $unit-size-small * 3;

    @include breakpoint('m') {
        flex-basis: 50%;
        justify-content: flex-end;
        margin-bottom: 0;
    }

    &__option {
        margin-top: 0;
        flex-basis: 50%;

        @include breakpoint('m') {
            padding: 0 $unit-size-small;
            flex-basis: 33.33%;
        }
    }
}
