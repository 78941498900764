@use "sass:list";

@import 'variables';
@import 'mixins';

/***
  * Module / Project Stretch Goals
  */
$stretch-goal-card-size: 168px;
$load-more-height: 44px;

.#{$ns}project-stretch-goal-card {
    display: flex;
    padding: 0;
    background-color: $color-white;
    border-radius: $common-border-radius;
    overflow: hidden;

    &__media {
        width: 42%;
        flex-shrink: 0;

        @include breakpoint('m') {
            width: $stretch-goal-card-size;
        }

        img {
            object-fit: cover;
        }
    }

    &__body {
        flex-grow: 1;
        padding: $unit-size-small * 3;
    }

    &__title {
        overflow: hidden;

        @supports (-webkit-line-clamp: 2) {
            flex-shrink: 0;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            white-space: normal;
        }
    }

    &--featured {
        flex-direction: column-reverse;

        @include breakpoint('m') {
            height: $stretch-goal-card-size * 3 + $unit-size-small * 6;
            grid-row: auto / span 3;
        }
    }

    &--featured &__media {
        width: 100%;
    }
}

.#{$ns}project-stretch-goal-grid {
    width: 100%;

    @include breakpoint('m') {
        display: grid;
        grid: auto-flow dense list.slash($stretch-goal-card-size, 1fr) 1fr;
        grid-gap: $unit-size-small * 3;
        margin-bottom: 0;
    }

    &__item:not(:first-child) {
        margin-top: $unit-size-small * 3;

        @include breakpoint('m') {
            margin-top: 0;
        }
    }
}

.#{$ns}project-stretch-goal-placeholder {
    border: 1px solid $color-input-border;
    width: 100%;
    border-radius: $common-border-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-text-lighter;
    min-height: $stretch-goal-card-size;
}

/***
  * Module / Project Stretch Goals Icon
  */

.#{$ns}project-stretch-goal-icon {
    width: $unit-size-small * 4;
    height: $unit-size-small * 4;
    font-size: $font-size-tiny;
    border-radius: 50%;
    background-color: $color-text-lighter;
    color: $color-white;
    flex-shrink: 0;

    &--success {
        color: $color-white;
        background-color: $color-accent-base;
    }

    span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }
}
