﻿@import 'variables';
@import 'mixins';

/***
  * Component / Dropdown
  */

.#{$ns}dropdown {
    display: inline-block;
    position: relative;
    overflow: visible;
    color: $color-text-base;
    font-weight: normal;
    text-transform: none;
    font-style: normal;
    font-family: $font-family-base;
    line-height: $line-height-base;

    &__list {
        min-width: 250px;
        list-style: none;
        margin: 0;
        padding: 0;
        left: 0;
        top: 25px;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        display: block;
        background: $color-white;
        box-shadow: 0 1px 5px $color-shadow-base;
        z-index: 50;
        border: 1px solid $color-shadow-base;
        border-radius: 3px;

        &--large {
            min-width: 400px;
        }

        &--top {
            top: auto;
            bottom: 25px;
        }

        &.is-open {
            opacity: 1;
            pointer-events: auto;
        }
    }

    &__item {
        display: block;
        font-size: 14px;
        text-align: left;

        a {
            display: block;
            text-decoration: none;
            padding: 0.5em;

            &:hover {
                background-color: $color-primary-base;
                color: $color-white;
            }
        }

        &--divider {
            height: 1px;
            background: $color-shadow-base;
            margin: 0.2em;
        }

        &--label {
            color: $color-text-light;
            padding: 0.5em 1em;
            cursor: default;
        }

        &__icon {
            font-family: $font-family-icons;
            text-align: center;
            display: inline-block;
            width: 2em;
        }
    }
}
