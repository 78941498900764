@import 'variables';

/***
  * Component / State Indicator
  */

.#{$ns}state {
    &--dot {
        display: inline-flex;
        width: 0.5em;
        height: 0.5em;
        border-radius: 1em;
        vertical-align: middle;
    }

    &--success {
        background-color: $color-text-accent;
    }

    &--danger {
        background-color: $color-text-accent-3;
    }

    &--warning {
        background-color: $color-text-accent-4;
    }

    &--info {
        background-color: $color-text-accent-2;
    }

    &--muted {
        background-color: $color-text-gray;
    }
}
