@import 'variables';
@import 'mixins';

/***
  * Component / Embed
  */

.#{$ns}embed {
    display: block;
    position: relative;
    width: 100%;

    &::before {
        display: block;
        content: '';
    }

    &__item {
        @include embed-fill();
    }

    &--1x1::before {
        @include aspect-ratio-1-1();
    }

    &--2x1::before {
        @include aspect-ratio-2-1();
    }

    &--16x9::before {
        @include aspect-ratio-16-9();
    }
}
